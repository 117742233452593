// servicesService.js
import axiosInstance from './axiosConfig';
import { getCookie } from '../utils/cookieUtils';
import { getUserData } from '../utils/cookieUtils';

// Ensure API_BASE_URL doesn't end with a slash

let API_BASE_URL = process.env.REACT_APP_API_URL;
console.log(API_BASE_URL);
if (API_BASE_URL && API_BASE_URL.endsWith('/')) {
    API_BASE_URL = API_BASE_URL.slice(0, -1);
}

// Function to get the token from local storage
const getAuthToken = () => {
  return getCookie('token');
};

// Add Power BI config fetch function
export const fetchPowerBIConfig = async () => {
  try {
    const userData = getUserData();
    if (!userData || !userData.client_id || !userData.product_id) {
      throw new Error('User authentication required');
    }

    const token = getCookie('token');
    if (!token) {
      throw new Error('Authentication token required');
    }

    const response = await axiosInstance.get(
      `${API_BASE_URL}/powerbi/report/${userData.client_id}/${userData.product_id}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error fetching Power BI config:', error);
    throw error;
  }
};


// Add Power BI report by page fetch function
export const fetchPowerBIReportByPage = async (pageId) => {
  try {
    const user = getUserData();
    const token = getCookie('token');

    // Ensure pageId is properly encoded
    const encodedPageId = encodeURIComponent(pageId);
    
    const response = await axiosInstance.get(
      `${API_BASE_URL}/powerbi/report/${user.client_id}/${user.product_id}/${encodedPageId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching Power BI report by page:', error);
    throw error;
  }
};


// Existing functions
export const fetchDataFromAPI = async (platform, pageNumber = 1, pageSize = 50, fromDate = null, toDate = null) => {
  try {
    const token = getAuthToken();
    let url = `${API_BASE_URL}/app/data?platform=${platform}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    
    // Add date parameters if they exist
    if (fromDate) url += `&fromDate=${fromDate}`;
    if (toDate) url += `&toDate=${toDate}`;
    
    const response = await axiosInstance.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data from API:', error);
    throw error;
  }
};

export const fetchOrderDetails = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/app/data`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching order details:', error);
    throw error;
  }
};

export const fetchDashboardData = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/app/orders`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

export const fetchPodata = async (Ponumber) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/app/orders/${Ponumber}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching order summaries:', error);
    throw error;
  }
};

// Contract Related API Calls
export const generateContract = async (poNumber) => {
    try {
        const token = getCookie('token');
        const userData = getUserData();
        
        if (!token || !userData) {
            throw new Error('Authentication required');
        }

        const response = await axiosInstance.post(
            `${API_BASE_URL}/app/contracts/generate`,
            {
                poNumber,
                userId: userData.user_id
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        return {
            success: true,
            message: response.data.message,
            contractNumber: response.data.contractNumber
        };
    } catch (error) {
        console.error('Contract generation error:', error);
        throw new Error(error.response?.data?.message || 'Failed to generate contract');
    }
};

export const generateContract_po = async (poNumber) => {
  try {
    const token = getCookie('token');
    const userData = getUserData();

    if (!token || !userData) {
      throw new Error('Authentication required');
    }

    const response = await axiosInstance.post(
      `${API_BASE_URL}/app/contracts/generate/${poNumber}`,
      {}, // Empty object for the body
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error generating contract:', error);
    throw error;
  }
};


// Order Related API Calls
export const approveOrders = async (orderData) => {
    try {
        const token = getCookie('token');
        if (!token) {
            throw new Error('Authentication required');
        }

        const response = await axiosInstance.post(
            `${API_BASE_URL}/app/orders/approve`,
            orderData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        return {
            success: true,
            message: response.data.message,
            data: response.data.data
        };
    } catch (error) {
        console.error('Order approval error:', error);
        throw new Error(error.response?.data?.message || 'Failed to approve order');
    }
};

// Get contract status
export const getContractStatus = async (poNumber) => {
    try {
        const token = getCookie('token');
        if (!token) {
            throw new Error('Authentication required');
        }

        const response = await axiosInstance.get(
            `${API_BASE_URL}/app/contracts/status/${poNumber}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error fetching contract status:', error);
        throw error;
    }
};

export const fetchLogsByPO = async (poNumber) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/logs/${poNumber}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching logs:', error);
    throw error;
  }
};

export const editOrderItem = async (poNumber, poItemId, data) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.put(
      `${API_BASE_URL}/app/orders/${poNumber}/items/${poItemId}`,
      data,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error editing order item:', error);
    throw error;
  }
};

export const fetchMasters = async (masterType, platform = null) => {
  try {
    const token = getAuthToken();
    let url = `${API_BASE_URL}/app/masters/${masterType}`;
    
    if (platform) {
      url += `/${platform}`;
    }
    
    const response = await axiosInstance.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (!response.data.success) {
      throw new Error(response.data.message || 'Failed to fetch masters');
    }
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPlatforms = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/app/platforms`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching platforms:', error);
    throw error;
  }
};

export const fetchDashboardSummary = async (filters) => {
    try {
        const token = getAuthToken();
        const queryParams = new URLSearchParams({
            platform: filters.platform || 'All',
            distribution: filters.Distribution || 'All',
            fromDate: filters.fromDate || '',
            toDate: filters.toDate || '',
            status: filters.status || 'All'
        }).toString();

        const response = await axiosInstance.get(
            `${API_BASE_URL}/app/dashboard/summary?${queryParams}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching dashboard summary:', error);
        throw error;
    }
};

export const updateMasterData = async (masterType, platform, id, data) => {
    try {
        const userData = getUserData();
        if (!userData || !userData.user_id) {
            throw new Error('User not authenticated');
        }

        // Get IP address
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const ipData = await ipResponse.json();
        const ipAddress = ipData.ip || '0.0.0.0';

        const response = await axiosInstance.post(
            `${API_BASE_URL}/app/masters/update`,
            {
                masterType,
                platform,
                id,
                data,
                userId: userData.user_id,
                ipAddress
            },
            {
                headers: {
                    'Authorization': `Bearer ${getAuthToken()}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error updating master data:', error);
        throw error;
    }
};

export const addMasterData = async (masterType, platform, data) => {
    try {
        const response = await axiosInstance.post(
            `${API_BASE_URL}/app/masters/add`,
            {
                masterType,
                platform,
                data,
                userId: getUserData().userId
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error adding master data:', error);
        throw error;
    }
};

export const disableSkus = async (skuId) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(
      `${API_BASE_URL}/app/disable-skus`,   
      
      {
        headers: {  
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {   
    console.error('Error disabling SKUs:', error);
    throw error;
  }
};

export const soldToPricing = async (platform = null) => {
  try {
    const token = getAuthToken();
    let url = `${API_BASE_URL}/app/sold-to-pricing`;
    
    if (platform) {
      url += `/${platform}`;
    }
    
    console.log('Calling sold-to-pricing URL:', url);
    
    const response = await axiosInstance.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (!response.data.success) {
      throw new Error(response.data.message || 'Failed to fetch sold-to-party pricing');
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching sold-to pricing:', error);
    throw error;
  }
};

// Add this new function to handle master data uploads
export const uploadMasterData = async (formData) => {
    try {
        const token = getAuthToken();
        if (!token) {
            throw new Error('Authentication required');
        }

        // Log the data being sent for debugging
        console.log('Uploading master data:', {
            type: formData.get('type'),
            fileName: formData.get('fileName'),
            uploadedBy: formData.get('uploadedBy'),
            data: JSON.parse(formData.get('data'))
        });

        const response = await axiosInstance.post(
            `${API_BASE_URL}/app/masters/upload`,
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (!response.data.success) {
            throw new Error(response.data.message || 'Upload failed');
        }

        return {
            success: true,
            uploadId: response.data.uploadId,
            processedRecords: response.data.processedRecords,
            failedRecords: response.data.failedRecords,
            status: response.data.status,
            message: response.data.message || 'Upload completed successfully'
        };

    } catch (error) {
        console.error('Error uploading master data:', error);
        if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
        }
        throw error;
    }
};


// Add this function to get upload history
export const getMasterUploadHistory = async () => {
    try {
        const token = getAuthToken();
        const response = await axiosInstance.get(
            `${API_BASE_URL}/app/masters/upload-history`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error fetching upload history:', error);
        throw error;
    }
};

// Add this new function for updating order status
export const updateOrderStatus = async (data) => {
    try {
        const token = getAuthToken();
        const user = getUserData();
        
        if (!token || !user) {
            throw new Error('Authentication required');
        }

        // Add user data to the request
        const requestData = {
            ...data,
            userId: user.user_id,
            userName: `${user.first_name} ${user.last_name}`
        };

        const response = await axiosInstance.post(
            `${API_BASE_URL}/app/orders/status`,
            requestData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        if (!response.data.success) {
            throw new Error(response.data.message || 'Failed to update status');
        }

        return response.data;
    } catch (error) {
        console.error('Error updating status:', error);
        throw error;
    }
};

export const processDataByPlatform = async (platform, startDate, endDate) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.post(
      `${API_BASE_URL}/app/process-platform-data`,
      { platform, startDate, endDate },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error processing platform data:', error);
    throw error;
  }
};




// Add this new function for updating manual remarks
export const updateManualRemarks = async (updates) => {
    try {
        const token = getCookie('token');
        if (!token) {
            throw new Error('Authentication required');
        }

        const response = await axiosInstance.post(
            `${API_BASE_URL}/app/orders/update-remarks`,
            { updates },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error updating manual remarks:', error);
        throw error;
    }
};

// Fetch users
export const fetchUsers = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(
      `${API_BASE_URL}/admin/users`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    
    // Verify data structure before returning
    if (response.data && Array.isArray(response.data.data)) {
      return response.data;
    } else {
      console.warn('Invalid response format for users:', response.data);
      return { 
        success: false, 
        data: [], 
        message: 'Invalid data format received from server' 
      };
    }
  } catch (error) {
    console.error('Error fetching users:', error);
    
    // Handle possible HTML responses from server errors
    let errorMessage = error.message || 'Failed to fetch users';
    if (error.response && error.response.data) {
      // Check if the response is HTML (common for 500 errors)
      if (typeof error.response.data === 'string' && 
          error.response.data.trim().startsWith('<!DOCTYPE html>')) {
        errorMessage = `Server error (${error.response.status}): The server returned an HTML error page`;
      } else if (error.response.data.message) {
        errorMessage = error.response.data.message;
      }
    }
    
    // Return a graceful failure object instead of throwing
    return { 
      success: false, 
      data: [], 
      message: errorMessage 
    };
  }
};

// Fetch users by client ID
export const fetchUsersByClientId = async (clientId) => {
  try {
    if (!clientId) {
      return { success: false, data: [], message: 'Client ID is required' };
    }
    
    const token = getAuthToken();
    const response = await axiosInstance.get(
      `${API_BASE_URL}/admin/users/client/${encodeURIComponent(clientId)}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    
    // Verify data structure before returning
    if (response.data && Array.isArray(response.data.data)) {
      return response.data;
    } else {
      console.warn('Invalid response format for users by client ID:', response.data);
      return { 
        success: false, 
        data: [], 
        message: 'Invalid data format received from server' 
      };
    }
  } catch (error) {
    console.error('Error fetching users by client ID:', error);
    
    // Handle possible HTML responses
    let errorMessage = error.message || 'Failed to fetch users';
    if (error.response && error.response.data) {
      if (typeof error.response.data === 'string' && 
          error.response.data.trim().startsWith('<!DOCTYPE html>')) {
        errorMessage = `Server error (${error.response.status}): The server returned an HTML error page`;
      } else if (error.response.data.message) {
        errorMessage = error.response.data.message;
      }
    }
    
    return { 
      success: false, 
      data: [], 
      message: errorMessage 
    };
  }
};

// Fetch admin modules
export const fetchAdminModules = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/admin/modules`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching modules:', error);
    // Return a graceful failure object instead of throwing
    return { success: false, data: [], message: error.message || 'Failed to fetch modules' };
  }
};

// Fetch admin platforms
export const fetchAdminPlatforms = async (projectId = null) => {
  try {
    const token = getAuthToken();
    let url = `${API_BASE_URL}/admin/platforms`;
    
    if (projectId) {
      url = `${API_BASE_URL}/admin/platforms/project/${projectId}`;
    }
    
    const response = await axiosInstance.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching platforms:', error);
    // Return a graceful failure object instead of throwing
    return { success: false, data: [], message: error.message || 'Failed to fetch platforms' };
  }
};

// Fetch admin projects
export const fetchAdminProjects = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/admin/projects`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error);
    // Return a graceful failure object instead of throwing
    return { success: false, data: [], message: error.message || 'Failed to fetch projects' };
  }
};

// Add this function to your AppRoutes.js file
export const fetchUserPermissions = async (userId) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/admin/users/${userId}/permissions`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching user permissions:', error);
    // Return a consistent error format
    return {
      success: false,
      data: null,
      message: error.message || 'Failed to fetch user permissions'
    };
  }
};

// Create or update user
export const createOrUpdateUser = async (userData, userId = null) => {
  try {
    const url = `${API_BASE_URL}/admin/users`;
    const token = getAuthToken();
    
    // If userId is provided, add it to the payload for update operation
    const payload = userId ? { ...userData, user_id: userId } : userData;
    
    const response = await axiosInstance.post(url, payload, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Error creating/updating user:', error);
    // Return a structured error response
    return {
      success: false,
      message: error.response?.data?.message || 'Failed to save user data',
      error: error.message
    };
  }
};

// Update user permissions
export const updateUserPermissions = async (userId, permissionsData) => {
  try {
    const url = `${API_BASE_URL}/admin/users/${userId}/permissions`;
    const token = getAuthToken();
    
    const response = await axiosInstance.post(url, permissionsData, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Error updating user permissions:', error);
    // Return a structured error response
    return {
      success: false,
      message: error.response?.data?.message || 'Failed to update user permissions',
      error: error.message
    };
  }
};

// Add this function to delete a user
export const deleteUser = async (userId) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.delete(
      `${API_BASE_URL}/admin/users/${userId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    // Return a consistent error format
    return {
      success: false,
      message: error.message || 'Failed to delete user'
    };
  }
};

export const fetchPurchaseOrdersCount = async (platform) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/app/getPurchaseOrdersCount?platform=${platform}`, {
      headers: {  
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching purchase orders count:', error);
    throw error;
  }
};

// Fix the addDisabledSku function to include pf_id parameter
export const addDisabledSku = async (data) => {
  try {
    const userData = getUserData();
    if (!userData || !userData.user_id) {
      throw new Error('User not authenticated');
    }

    // Log the data being sent for debugging
    console.log('Sending disabled SKU data:', {
      masterType: 'DISABLED_SKUS',
      data
    });

    // Create the request payload
    const payload = {
      masterType: 'DISABLED_SKUS', // This is case-sensitive!
      data: {
        sku_id: data.sku_id,
        sap_code: data.sap_code || '',
        product_name: data.product_name || data.title || '',
        reason: data.reason || data.description || '',
        disabled_date: data.disabled_date || new Date().toISOString().split('T')[0],
        status: data.status || 'Disabled',
        remark: data.remark || 'Discontinued',
        pfid: data.pfid || data.pf_id || 0  // Include pf_id from either format
      },
      userId: userData.user_id
    };

    // Make the API request
    const response = await axiosInstance.post(
      `${API_BASE_URL}/app/masters/add`,
      payload,
      {
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error adding disabled SKU:', error);
    if (error.response) {
      console.error('Response status:', error.response.status);
      console.error('Response data:', error.response.data);
    }
    throw error;
  }
};

// Fix the updateDisabledSku function to dynamically include all fields
export const updateDisabledSku = async (id, data) => {
  try {
    const userData = getUserData();
    if (!userData || !userData.user_id) {
      throw new Error('User not authenticated');
    }

    // Extract only the fields that are relevant to disabled SKUs
    // This preserves all fields from the form while excluding metadata
    const processedData = {};
    const relevantFields = [
      'sku_id', 'sap_code', 'product_name', 'title', 'reason', 
      'description', 'disabled_date', 'discontinued_date', 'status', 
      'remark', 'pfid', 'pf_id'
    ];

    // Copy all fields that might be in the data object
    Object.keys(data).forEach(key => {
      if (relevantFields.includes(key) && data[key] !== undefined) {
        processedData[key] = data[key];
      }
    });

    // Make sure we have pfid available (could be in either format)
    if (data.pfid) processedData.pfid = data.pfid;
    if (data.pf_id) processedData.pfid = data.pf_id;

    // Map fields to their backend equivalents if needed
    if (data.product_name) processedData.title = data.product_name;
    if (data.reason) processedData.description = data.reason;
    if (data.disabled_date) processedData.discontinued_date = data.disabled_date;

    console.log('Updating disabled SKU with data:', processedData);

    const response = await axiosInstance.post(
      `${API_BASE_URL}/app/masters/update`,
      {
        masterType: 'DISABLED_SKUS',
        id,
        data: processedData,
        userId: userData.user_id
      },
      {
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating disabled SKU:', error);
    if (error.response) {
      console.error('Response status:', error.response.status);
      console.error('Response data:', error.response.data);
    }
    throw error;
  }
};

// Fix the addSoldToPartyPricing function to include pf_id parameter
export const addSoldToPartyPricing = async (data) => {
  try {
    const userData = getUserData();
    if (!userData || !userData.user_id) {
      throw new Error('User not authenticated');
    }

    // Get platform ID from platforms list if available
    let pf_id = data.pf_id || 0;
    
    // This block does only console logging for debugging
    console.log('Sending sold-to-party pricing data:', {
      masterType: 'SOLDTO_PRICING',
      platform: data.platform_name || data.platform,
      data: {
        ...data,
        pf_id
      }
    });

    const response = await axiosInstance.post(
      `${API_BASE_URL}/app/masters/add`,
      {
        masterType: 'SOLDTO_PRICING', // Ensure uppercase to match backend validation
        platform: data.platform_name || data.platform,
        data: {
          platform_name: data.platform_name || data.platform,
          pf_id: pf_id,  // Include pf_id parameter
          sold_to_party: data.sold_to_party,
          sap_code: data.sap_code || '',
          sku_id: data.sku_id || '',
          price: data.amount || data.price || 0,
          currency: data.currency || 'INR',
          status: data.status || 'Active',
          base_cost: data.base_cost || 0,
          valid_from: data.valid_from || new Date().toISOString().split('T')[0],
          valid_to: data.valid_to || null
        },
        userId: userData.user_id
      },
      {
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error adding sold-to-party pricing:', error);
    if (error.response) {
      console.error('Response status:', error.response.status);
      console.error('Response data:', error.response.data);
    }
    throw error;
  }
};

// And for updateSoldToPartyPricing
export const updateSoldToPartyPricing = async (id, data) => {
  try {
    const userData = getUserData();
    if (!userData || !userData.user_id) {
      throw new Error('User not authenticated');
    }

    // Extract only the fields that are relevant to Sold-To-Party Pricing
    const processedData = {};
    const relevantFields = [
      'platform_name', 'pf_id', 'sold_to_party', 'sap_code', 'sku_id',
      'price', 'amount', 'base_cost', 'location', 'currency', 
      'valid_from', 'valid_to', 'status', 'party_name'
    ];

    // Copy all relevant fields
    Object.keys(data).forEach(key => {
      if (relevantFields.includes(key) && data[key] !== undefined) {
        processedData[key] = data[key];
      }
    });

    // Make sure price is set from either amount or price field
    if (data.amount) processedData.price = data.amount;
    if (data.price) processedData.price = data.price;

    // Ensure pf_id is included
    if (!processedData.pf_id && data.pf_id) {
      processedData.pf_id = data.pf_id;
    }

    console.log('Updating sold-to-party pricing with data:', processedData);

    const response = await axiosInstance.post(
      `${API_BASE_URL}/app/masters/update`,
      {
        masterType: 'SOLDTO_PRICING',
        id,
        data: processedData,
        userId: userData.user_id
      },
      {
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating sold-to-party pricing:', error);
    if (error.response) {
      console.error('Response status:', error.response.status);
      console.error('Response data:', error.response.data);
    }
    throw error;
  }
};



