import React, { useEffect, useState, useRef } from 'react';
import { 
  Card, Row, Col, Space, Progress, Typography, Statistic, 
  Table, Tag, Dropdown, Button, Menu, Divider, DatePicker, Select,
  Tooltip, Badge, Avatar, Empty, Spin, Radio, Input
} from 'antd';
import { 
  LineChartOutlined, PieChartOutlined, BarChartOutlined,
  BellOutlined, InfoCircleOutlined, DownOutlined,
  ReloadOutlined, FilterOutlined,
  ArrowUpOutlined, ArrowDownOutlined, DollarOutlined,
  ShoppingOutlined, RiseOutlined,
  SearchOutlined, ExportOutlined, SyncOutlined,
  SettingOutlined, DownloadOutlined, CalendarOutlined
} from '@ant-design/icons';
import { TrendingUp, DollarSign, Package, Users, AlertTriangle, Eye, Clock, BarChart2 } from 'react-feather';
import moment from 'moment';

const { Title, Text, Paragraph } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

// Mock data for charts and tables
const topSellingProducts = [
  { id: 1, name: 'Premium Rice Basmati', sales: 1245, growth: 12.5, stock: 'In Stock', price: '$24.99' },
  { id: 2, name: 'Brown Rice Organic', sales: 980, growth: -5.2, stock: 'Low Stock', price: '$18.50' },
  { id: 3, name: 'Jasmine Rice 5kg', sales: 875, growth: 8.7, stock: 'In Stock', price: '$22.75' },
  { id: 4, name: 'Wild Rice Mix', sales: 654, growth: 15.3, stock: 'In Stock', price: '$28.99' },
  { id: 5, name: 'Sushi Rice Premium', sales: 542, growth: -2.1, stock: 'Out of Stock', price: '$19.95' },
];

// More mock data for charts
// const salesData = [
//   { month: 'Jan', sales: 3500, competitor: 3000 },
//   { month: 'Feb', sales: 4200, competitor: 3200 },
//   { month: 'Mar', sales: 3800, competitor: 3400 },
//   { month: 'Apr', sales: 5000, competitor: 3800 },
//   { month: 'May', sales: 4800, competitor: 4000 },
//   { month: 'Jun', sales: 5500, competitor: 4200 },
// ];

const platformData = [
  { type: 'Amazon', value: 65 },
  { type: 'Website', value: 20 },
  { type: 'Walmart', value: 10 },
  { type: 'Other', value: 5 },
];

const recentAlerts = [
  { id: 1, type: 'price', title: 'Price Drop Alert', description: 'Competitor has reduced price by 15% on "Premium Basmati Rice"', time: '2 hours ago', severity: 'high' },
  { id: 2, type: 'inventory', title: 'Inventory Alert', description: '5 products will reach low stock threshold within 7 days', time: '5 hours ago', severity: 'medium' },
  { id: 3, type: 'keyword', title: 'Keyword Opportunity', description: '3 new high-volume keywords identified for your product category', time: '1 day ago', severity: 'low' },
  { id: 4, type: 'competitor', title: 'New Competitor', description: 'New competitor detected in "Organic Rice" category', time: '2 days ago', severity: 'medium' },
];

const marketInsights = [
  { id: 1, metric: 'Market Share', value: '23.5%', change: 2.1, trend: 'up' },
  { id: 2, metric: 'Category Growth', value: '8.7%', change: 1.5, trend: 'up' },
  { id: 3, metric: 'Avg. Selling Price', value: '$22.45', change: -0.8, trend: 'down' },
  { id: 4, metric: 'Conversion Rate', value: '4.2%', change: 0.3, trend: 'up' },
];

// First, add this mock data for inventory trends
const inventoryData = [
  { month: 'Jan', inStock: 85, lowStock: 10, outOfStock: 5 },
  { month: 'Feb', inStock: 80, lowStock: 15, outOfStock: 5 },
  { month: 'Mar', inStock: 75, lowStock: 15, outOfStock: 10 },
  { month: 'Apr', inStock: 82, lowStock: 13, outOfStock: 5 },
  { month: 'May', inStock: 78, lowStock: 17, outOfStock: 5 },
  { month: 'Jun', inStock: 80, lowStock: 12, outOfStock: 8 },
];

// Add this utility function at the top of your file (outside the component)
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const DashboardView = () => {
  const [timeRange, setTimeRange] = useState('week');
  const [loading, setLoading] = useState(false);
  const [chartType, setChartType] = useState('bar');
  const [alertFilter, setAlertFilter] = useState('all');
  const [dateRange, setDateRange] = useState([moment().subtract(7, 'days'), moment()]);
  
  // Add this new state for chart dimensions
  const [, setChartDimensions] = useState({ width: 0, height: 0 });
  
  // Add this ref for the chart container
  const chartContainerRef = useRef(null);
  
  // Add this effect to handle resize events properly
  useEffect(() => {
    const updateDimensions = () => {
      if (chartContainerRef.current) {
        setChartDimensions({
          width: chartContainerRef.current.offsetWidth,
          height: chartContainerRef.current.offsetHeight
        });
      }
    };
    
    // Initial update
    updateDimensions();
    
    // Debounced resize handler to prevent excessive ResizeObserver calls
    const debouncedHandleResize = debounce(updateDimensions, 100);
    window.addEventListener('resize', debouncedHandleResize);
    
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  useEffect(() => {
    console.log('DashboardView component mounted');
    // Simulate loading
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    
    return () => {
      clearTimeout(timer);
      console.log('DashboardView component unmounted');
    };
  }, []);

  const handleTimeRangeChange = (value) => {
    setTimeRange(value);
    setLoading(true);
    
    // Set appropriate date range based on selection
    if (value === 'day') {
      setDateRange([moment(), moment()]);
    } else if (value === 'week') {
      setDateRange([moment().subtract(7, 'days'), moment()]);
    } else if (value === 'month') {
      setDateRange([moment().subtract(1, 'month'), moment()]);
    } else if (value === 'quarter') {
      setDateRange([moment().subtract(3, 'months'), moment()]);
    } else if (value === 'year') {
      setDateRange([moment().subtract(1, 'year'), moment()]);
    }
    
    // Simulate data refresh
    setTimeout(() => {
      setLoading(false);
    }, 800);
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      setTimeRange('custom');
      setLoading(true);
      // Simulate data refresh
      setTimeout(() => {
        setLoading(false);
      }, 800);
    }
  };

  const handleRefresh = () => {
    setLoading(true);
    // Simulate data refresh
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleAlertFilterChange = (e) => {
    setAlertFilter(e.target.value);
  };

  const renderStockTag = (stock) => {
    let color = 'green';
    if (stock === 'Low Stock') color = 'orange';
    if (stock === 'Out of Stock') color = 'red';
    return <Tag color={color}>{stock}</Tag>;
  };

  const renderGrowth = (growth) => {
    return (
      <Text type={growth >= 0 ? 'success' : 'danger'}>
        {growth >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />} {Math.abs(growth)}%
      </Text>
    );
  };

  const renderSeverityTag = (severity) => {
    const colors = {
      high: 'red',
      medium: 'orange',
      low: 'green'
    };
    return <Tag color={colors[severity]}>{severity.toUpperCase()}</Tag>;
  };

  const renderAlertIcon = (type) => {
    switch(type) {
      case 'price':
        return <DollarSign size={16} color="#1890ff" />;
      case 'inventory':
        return <Package size={16} color="#52c41a" />;
      case 'keyword':
        return <Eye size={16} color="#722ed1" />;
      case 'competitor':
        return <Users size={16} color="#fa8c16" />;
      default:
        return <AlertTriangle size={16} color="#f5222d" />;
    }
  };

  const columns = [
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a href="#product-details">{text}</a>,
    },
    {
      title: 'Sales',
      dataIndex: 'sales',
      key: 'sales',
      sorter: (a, b) => a.sales - b.sales,
      render: (sales) => <Text strong>{sales.toLocaleString()}</Text>,
    },
    {
      title: 'Growth',
      dataIndex: 'growth',
      key: 'growth',
      sorter: (a, b) => a.growth - b.growth,
      render: renderGrowth,
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      key: 'stock',
      filters: [
        { text: 'In Stock', value: 'In Stock' },
        { text: 'Low Stock', value: 'Low Stock' },
        { text: 'Out of Stock', value: 'Out of Stock' },
      ],
      onFilter: (value, record) => record.stock.indexOf(value) === 0,
      render: renderStockTag,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price.localeCompare(b.price),
    },
    {
      title: 'Action',
      key: 'action',
      render: () => (
        <Dropdown overlay={
          <Menu>
            <Menu.Item key="1" icon={<Eye size={14} />}>View Details</Menu.Item>
            <Menu.Item key="2" icon={<SettingOutlined />}>Edit Product</Menu.Item>
            <Menu.Item key="3" icon={<BarChart2 size={14} />}>View Analytics</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="4" icon={<ExportOutlined />}>Export Data</Menu.Item>
          </Menu>
        }>
          <Button type="text" icon={<DownOutlined />}>Actions</Button>
        </Dropdown>
      ),
    },
  ];

  // Filter alerts based on selected filter
  const filteredAlerts = alertFilter === 'all' 
    ? recentAlerts 
    : recentAlerts.filter(alert => alert.type === alertFilter);

  // Render sales chart
  // const renderSalesChart = () => {
  //   if (loading) {
  //     return (
  //       <div className="chart-loading" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 300 }}>
  //         <Spin size="large" />
  //         <Text type="secondary" style={{ marginTop: 16 }}>Loading chart data...</Text>
  //       </div>
  //     );
  //   }

  //   // Enhanced interactive chart visualization
  //   return (
  //     <>
  //       <div style={{ height: 300, position: 'relative', background: 'rgba(24, 144, 255, 0.05)', borderRadius: 8, padding: 16, overflow: 'hidden' }}>
  //         {/* Chart grid lines */}
  //         <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'grid', gridTemplateRows: 'repeat(5, 1fr)', gridTemplateColumns: 'repeat(6, 1fr)', pointerEvents: 'none' }}>
  //           {Array(5).fill(0).map((_, i) => (
  //             <div key={`h-${i}`} style={{ gridRow: i + 1, gridColumn: '1 / span 6', borderBottom: '1px dashed rgba(0,0,0,0.1)' }} />
  //           ))}
  //           {Array(6).fill(0).map((_, i) => (
  //             <div key={`v-${i}`} style={{ gridColumn: i + 1, gridRow: '1 / span 5', borderRight: '1px dashed rgba(0,0,0,0.1)' }} />
  //           ))}
  //         </div>
          
  //         {/* Y-axis labels */}
  //         <div style={{ position: 'absolute', top: 10, left: 5, bottom: 30, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
  //           <Text type="secondary" style={{ fontSize: 11, fontWeight: 'bold' }}>$6000</Text>
  //           <Text type="secondary" style={{ fontSize: 11 }}>$4500</Text>
  //           <Text type="secondary" style={{ fontSize: 11 }}>$3000</Text>
  //           <Text type="secondary" style={{ fontSize: 11 }}>$1500</Text>
  //           <Text type="secondary" style={{ fontSize: 11 }}>$0</Text>
  //         </div>
          
  //         {/* Your sales line with animation */}
  //         <div style={{ position: 'absolute', bottom: 50, left: 30, right: 30, height: 120, overflow: 'hidden' }}>
  //           <svg width="100%" height="100%" viewBox="0 0 600 120" preserveAspectRatio="none">
  //             <path 
  //               d="M0,100 C50,80 100,90 150,70 C200,50 250,60 300,40 C350,20 400,30 450,10 C500,5 550,15 600,5" 
  //               fill="none" 
  //               stroke="#1890ff" 
  //               strokeWidth="3"
  //               strokeDasharray="1200"
  //               strokeDashoffset="1200"
  //               style={{ animation: 'dash 2s linear forwards' }}
  //             />
  //             <path 
  //               d="M0,100 C50,80 100,90 150,70 C200,50 250,60 300,40 C350,20 400,30 450,10 C500,5 550,15 600,5 L600,120 L0,120 Z" 
  //               fill="url(#blueGradient)" 
  //               strokeWidth="0"
  //               opacity="0"
  //               style={{ animation: 'fadeIn 0.5s ease-out 1.5s forwards' }}
  //             />
  //             <defs>
  //               <linearGradient id="blueGradient" x1="0%" y1="0%" x2="0%" y2="100%">
  //                 <stop offset="0%" stopColor="#1890ff" stopOpacity="0.8"/>
  //                 <stop offset="100%" stopColor="#1890ff" stopOpacity="0.1"/>
  //               </linearGradient>
  //               <style>
  //                 {`
  //                   @keyframes dash {
  //                     to {
  //                       stroke-dashoffset: 0;
  //                     }
  //                   }
  //                   @keyframes fadeIn {
  //                     to {
  //                       opacity: 0.2;
  //                     }
  //                   }
  //                   @keyframes pulse {
  //                     0% { r: 4; opacity: 0.7; }
  //                     50% { r: 6; opacity: 1; }
  //                     100% { r: 4; opacity: 0.7; }
  //                   }
  //                 `}
  //               </style>
  //             </defs>
  //           </svg>
  //         </div>
          
  //         {/* Competitor sales line */}
  //         <div style={{ position: 'absolute', bottom: 30, left: 30, right: 30, height: 120, overflow: 'hidden' }}>
  //           <svg width="100%" height="100%" viewBox="0 0 600 120" preserveAspectRatio="none">
  //             <path 
  //               d="M0,110 C50,100 100,105 150,95 C200,85 250,90 300,80 C350,70 400,75 450,65 C500,60 550,65 600,60" 
  //               fill="none" 
  //               stroke="#f5222d" 
  //               strokeWidth="3"
  //               strokeDasharray="5,5"
  //               opacity="0"
  //               style={{ animation: 'fadeIn 0.5s ease-out 1.8s forwards' }}
  //             />
  //           </svg>
  //         </div>
          
  //         {/* Data points for your sales with hover effect */}
  //         <div style={{ position: 'absolute', bottom: 50, left: 30, right: 30, height: 120 }}>
  //           <svg width="100%" height="100%" viewBox="0 0 600 120" preserveAspectRatio="none">
  //             {salesData.map((item, index) => {
  //               const x = index * 100;
  //               const y = 100 - (item.sales / 60);
  //               return (
  //                 <g key={index}>
  //                   <circle 
  //                     cx={x} 
  //                     cy={y} 
  //                     r="4" 
  //                     fill="#1890ff"
  //                     style={{ animation: `pulse 2s infinite ${index * 0.2}s` }}
  //                   />
  //                   <circle 
  //                     cx={x} 
  //                     cy={y} 
  //                     r="15" 
  //                     fill="transparent"
  //                     stroke="transparent"
  //                     className="hover-target"
  //                     data-value={`$${item.sales}`}
  //                     data-month={item.month}
  //                   />
  //                 </g>
  //               );
  //             })}
  //           </svg>
  //         </div>
          
  //         {/* Value indicators */}
  //         <div style={{ position: 'absolute', top: 50, right: 40, background: 'rgba(255,255,255,0.9)', padding: '8px 12px', borderRadius: 4, boxShadow: '0 2px 8px rgba(0,0,0,0.15)' }}>
  //           <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
  //             <div style={{ width: 8, height: 8, borderRadius: '50%', background: '#1890ff', marginRight: 8 }}></div>
  //             <Text strong>Your Sales: $5,500</Text>
  //           </div>
  //           <div style={{ display: 'flex', alignItems: 'center' }}>
  //             <div style={{ width: 8, height: 8, borderRadius: '50%', background: '#f5222d', marginRight: 8 }}></div>
  //             <Text type="secondary">Competitor: $4,200</Text>
  //           </div>
  //         </div>
          
  //         {/* X-axis labels */}
  //         <div style={{ position: 'absolute', bottom: 0, left: 30, right: 30, display: 'flex', justifyContent: 'space-between' }}>
  //           {salesData.map(item => (
  //             <div key={item.month} style={{ padding: '4px 0' }}>
  //               <Text strong style={{ fontSize: 12 }}>{item.month}</Text>
  //             </div>
  //           ))}
  //         </div>
  //       </div>
        
  //       <div className="chart-legend" style={{ display: 'flex', justifyContent: 'center', margin: '16px 0' }}>
  //         <div className="legend-item" style={{ display: 'flex', alignItems: 'center', marginRight: 24 }}>
  //           <span style={{ display: 'inline-block', width: 16, height: 3, backgroundColor: '#1890ff', marginRight: 8 }}></span>
  //           <Text>Your Sales</Text>
  //         </div>
  //         <div className="legend-item" style={{ display: 'flex', alignItems: 'center' }}>
  //           <span style={{ display: 'inline-block', width: 16, height: 3, backgroundColor: '#f5222d', marginRight: 8, borderTop: '1px dashed #f5222d', borderBottom: '1px dashed #f5222d' }}></span>
  //           <Text>Competitor Sales</Text>
  //         </div>
  //       </div>
        
  //       <div className="chart-description" style={{ padding: '0 16px' }}>
  //         <Text type="secondary">
  //           Your sales have consistently outperformed competitors over the last 6 months,
  //           with the largest gap in April and June. Overall growth trend is <Text type="success" strong>+15.3%</Text> compared to previous period.
  //         </Text>
  //       </div>
  //     </>
  //   );
  // };

  // Updated renderInventoryChart function with fixed dimensions
  const renderInventoryChart = () => {
    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
          <Spin size="large" />
        </div>
      );
    }

    // Calculate averages for the summary
    const avgInStock = Math.round(inventoryData.reduce((sum, item) => sum + item.inStock, 0) / inventoryData.length);
    const avgLowStock = Math.round(inventoryData.reduce((sum, item) => sum + item.lowStock, 0) / inventoryData.length);
    const avgOutOfStock = Math.round(inventoryData.reduce((sum, item) => sum + item.outOfStock, 0) / inventoryData.length);

    return (
      <div style={{ padding: '0 8px' }}>
        {/* Legend at the top with better spacing */}
        <div style={{ display: 'flex', marginBottom: '16px', paddingLeft: '8px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '24px' }}>
            <div style={{ width: '16px', height: '16px', backgroundColor: '#52c41a', marginRight: '8px' }}></div>
            <Text>In Stock</Text>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '24px' }}>
            <div style={{ width: '16px', height: '16px', backgroundColor: '#faad14', marginRight: '8px' }}></div>
            <Text>Low Stock</Text>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '16px', height: '16px', backgroundColor: '#f5222d', marginRight: '8px' }}></div>
            <Text>Out of Stock</Text>
          </div>
        </div>

        {/* Simple bar chart with fixed height to avoid resize issues */}
        <div style={{ display: 'flex', justifyContent: 'space-between', height: '200px', marginBottom: '16px' }}>
          {inventoryData.map((item, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '14%' }}>
              <div style={{ width: '80%', height: '180px', display: 'flex', flexDirection: 'column-reverse' }}>
                <div style={{ height: `${item.inStock}%`, backgroundColor: '#52c41a', width: '100%' }}></div>
                <div style={{ height: `${item.lowStock}%`, backgroundColor: '#faad14', width: '100%' }}></div>
                <div style={{ height: `${item.outOfStock}%`, backgroundColor: '#f5222d', width: '100%' }}></div>
              </div>
              <Text style={{ marginTop: '8px', fontSize: '12px' }}>{item.month}</Text>
            </div>
          ))}
        </div>

        {/* Summary text with better styling and alignment */}
        <div style={{ textAlign: 'left', marginTop: '8px', paddingLeft: '8px' }}>
          <Text>
            Inventory health: <Text strong style={{ color: '#52c41a' }}>{avgInStock}%</Text> in stock, 
            <Text strong style={{ color: '#faad14' }}> {avgLowStock}%</Text> low stock, 
            <Text strong style={{ color: '#f5222d' }}> {avgOutOfStock}%</Text> out of stock. Consider 
            restocking low stock items.
          </Text>
        </div>
      </div>
    );
  };

  // Updated renderPlatformChart function with fixed dimensions
  const renderPlatformChart = () => {
    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
          <Spin size="large" />
        </div>
      );
    }

    const colors = ['#1890ff', '#52c41a', '#fa8c16', '#722ed1'];
    const total = platformData.reduce((sum, item) => sum + item.value, 0);
    
    return (
      <div style={{ padding: '0 8px' }}>
        <div style={{ display: 'flex', height: '220px' }}>
          {/* Pie chart on the left with fixed dimensions */}
          <div style={{ flex: '0 0 60%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ position: 'relative', width: '180px', height: '180px' }}>
              {/* Create pie segments */}
              <svg width="180" height="180" viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="45" fill="transparent" stroke="#f0f0f0" strokeWidth="10" />
                
                {platformData.map((platform, index) => {
                  const percentage = platform.value / total;
                  const previousPercentages = platformData
                    .slice(0, index)
                    .reduce((sum, p) => sum + (p.value / total), 0);
                  
                  const startAngle = previousPercentages * 360;
                  const endAngle = (previousPercentages + percentage) * 360;
                  
                  // Convert angles to radians for calculation
                  const startRad = (startAngle - 90) * Math.PI / 180;
                  const endRad = (endAngle - 90) * Math.PI / 180;
                  
                  // Calculate path
                  const x1 = 50 + 45 * Math.cos(startRad);
                  const y1 = 50 + 45 * Math.sin(startRad);
                  const x2 = 50 + 45 * Math.cos(endRad);
                  const y2 = 50 + 45 * Math.sin(endRad);
                  
                  // Determine if the arc should be drawn as a large arc
                  const largeArcFlag = percentage > 0.5 ? 1 : 0;
                  
                  return (
                    <path
                      key={platform.type}
                      d={`M 50 50 L ${x1} ${y1} A 45 45 0 ${largeArcFlag} 1 ${x2} ${y2} Z`}
                      fill={colors[index % colors.length]}
                      stroke="#fff"
                      strokeWidth="1"
                    />
                  );
                })}
                
                {/* Center circle for donut effect */}
                <circle cx="50" cy="50" r="25" fill="white" />
              </svg>
              
              {/* Center text */}
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                <Text strong style={{ fontSize: 14 }}>Total</Text>
                <br />
                <Text type="secondary" style={{ fontSize: 12 }}>100%</Text>
              </div>
            </div>
          </div>
          
          {/* Legend on the right with better alignment */}
          <div style={{ flex: '0 0 40%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {platformData.map((platform, index) => (
              <div key={platform.type} style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                <div style={{ width: '12px', height: '12px', backgroundColor: colors[index % colors.length], marginRight: '8px', borderRadius: '2px' }}></div>
                <Text style={{ marginRight: '4px', fontSize: '12px' }}>{platform.type}</Text>
                <Text strong style={{ fontSize: '12px' }}>({platform.value}%)</Text>
              </div>
            ))}
          </div>
        </div>
        
        {/* Description text with better alignment */}
        <div style={{ textAlign: 'left', marginTop: '8px', paddingLeft: '8px' }}>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            Amazon dominates your sales channels at <Text strong>65%</Text>, followed by your website at <Text strong>20%</Text>. 
            Consider expanding your presence on Walmart to capture more market share.
          </Text>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-view glass-effect">
      {/* Dashboard Header with Filters */}
      <Row gutter={[16, 16]} className="dashboard-header" style={{ 
        background: 'linear-gradient(135deg, #1890ff 0%, #096dd9 100%)', 
        padding: '20px', 
        borderRadius: '8px 8px 0 0', 
        marginTop: -20, 
        marginLeft: -20, 
        marginRight: -20,
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
      }}>
        <Col xs={24} md={16}>
          <Title level={4} style={{ color: '#ffffff', margin: 0, textShadow: '0 1px 2px rgba(0,0,0,0.1)' }}>DSA Dashboard Overview</Title>
          <Paragraph style={{ color: '#ffffff', opacity: 0.9, margin: '4px 0 0 0' }}>
            Comprehensive view of your digital shelf analytics and market performance
          </Paragraph>
        </Col>
        <Col xs={24} md={8} className="dashboard-actions">
          <Space wrap style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Select 
              defaultValue="week" 
              style={{ width: 120, background: '#ffffff' }} 
              onChange={handleTimeRangeChange}
              loading={loading}
              value={timeRange}
              dropdownMatchSelectWidth={false}
            >
              <Option value="day">Today</Option>
              <Option value="week">This Week</Option>
              <Option value="month">This Month</Option>
              <Option value="quarter">This Quarter</Option>
              <Option value="year">This Year</Option>
              <Option value="custom">Custom Range</Option>
            </Select>
            
            {/* Fixed date picker with improved styling */}
            {timeRange === 'custom' ? (
              <RangePicker 
                style={{ width: 230, background: '#ffffff' }} 
                onChange={handleDateRangeChange}
                value={dateRange}
                allowClear={false}
                format="MMM DD, YYYY"
                suffixIcon={<CalendarOutlined style={{ color: '#1890ff' }} />}
                popupStyle={{ zIndex: 1050 }}
                getPopupContainer={trigger => trigger.parentNode}
                disabledDate={current => current && current > moment().endOf('day')}
              />
            ) : (
              <Button 
                onClick={() => setTimeRange('custom')}
                style={{ background: '#ffffff', display: 'flex', alignItems: 'center' }}
                icon={<CalendarOutlined style={{ color: '#1890ff' }} />}
              >
                Select Dates
              </Button>
            )}
            
            <Tooltip title="Refresh Data">
              <Button 
                type="primary" 
                icon={<ReloadOutlined style={{ fontSize: 16 }} />} 
                loading={loading}
                onClick={handleRefresh}
                ghost
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 32, height: 32, background: 'rgba(255,255,255,0.2)' }}
              />
            </Tooltip>
            <Tooltip title="Export Dashboard">
              <Button 
                icon={<DownloadOutlined style={{ fontSize: 16 }} />} 
                ghost 
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 32, height: 32, background: 'rgba(255,255,255,0.2)' }}
              />
            </Tooltip>
          </Space>
        </Col>
      </Row>

      <Divider style={{ margin: '12px 0', borderColor: 'rgba(255, 255, 255, 0.2)' }} />

      {/* Date Range Display */}
      <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text style={{ fontSize: 14 }}>
          <CalendarOutlined style={{ marginRight: 8 }} />
          Showing data for: <Text strong>{dateRange[0].format('MMM DD, YYYY')} - {dateRange[1].format('MMM DD, YYYY')}</Text>
        </Text>
        <Text type="secondary" style={{ fontSize: 12 }}>Last updated: {moment().format('MMM DD, YYYY HH:mm')}</Text>
      </div>

      {/* Key Metrics Section */}
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={6}>
          <Card className="metric-card" loading={loading} hoverable style={{ height: '100%', boxShadow: '0 2px 8px rgba(0,0,0,0.09)', borderRadius: 8 }}>
            <Statistic
            title={
              <Space>
                <span>Market Performance</span>
                  <Tooltip title="Overall market performance score based on visibility, pricing, and reviews">
                    <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                  </Tooltip>
              </Space>
            } 
              value={85}
              prefix={<TrendingUp size={20} />}
              suffix="%"
              valueStyle={{ color: '#4CAF50' }}
            />
            <Progress percent={85} showInfo={false} strokeColor="#4CAF50" />
            <div className="metric-footer" style={{ marginTop: 12 }}>
              <Text type="secondary">
                <ArrowUpOutlined /> 5% increase from last period
              </Text>
              </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card className="metric-card" loading={loading} hoverable style={{ height: '100%', boxShadow: '0 2px 8px rgba(0,0,0,0.09)', borderRadius: 8 }}>
            <Statistic
              title={
                <Space>
                  <span>Price Competitiveness</span>
                  <Tooltip title="How competitive your prices are compared to market average">
                    <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                  </Tooltip>
                </Space>
              }
              value={92}
              prefix={<DollarSign size={20} />}
              suffix="%"
              valueStyle={{ color: '#2196F3' }}
            />
            <Progress percent={92} showInfo={false} strokeColor="#2196F3" />
            <div className="metric-footer" style={{ marginTop: 12 }}>
              <Text type="secondary">
                <ArrowUpOutlined /> 3% increase from last period
              </Text>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card className="metric-card" loading={loading} hoverable style={{ height: '100%', boxShadow: '0 2px 8px rgba(0,0,0,0.09)', borderRadius: 8 }}>
            <Statistic
            title={
              <Space>
                  <span>Inventory Status</span>
                  <Tooltip title="Overall inventory health across all products">
                    <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                  </Tooltip>
              </Space>
            } 
              value={78}
              prefix={<Package size={20} />}
              suffix="%"
              valueStyle={{ color: '#FF9800' }}
            />
            <Progress percent={78} showInfo={false} strokeColor="#FF9800" />
            <div className="metric-footer" style={{ marginTop: 12 }}>
              <Text type="secondary">
                <ArrowDownOutlined /> 2% decrease from last period
              </Text>
              </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card className="metric-card" loading={loading} hoverable style={{ height: '100%', boxShadow: '0 2px 8px rgba(0,0,0,0.09)', borderRadius: 8 }}>
            <Statistic
              title={
                <Space>
                  <span>Competitor Analysis</span>
                  <Tooltip title="Your performance relative to top competitors">
                    <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                  </Tooltip>
                </Space>
              }
              value={88}
              prefix={<Users size={20} />}
              suffix="%"
              valueStyle={{ color: '#9C27B0' }}
            />
            <Progress percent={88} showInfo={false} strokeColor="#9C27B0" />
            <div className="metric-footer" style={{ marginTop: 12 }}>
              <Text type="secondary">
                <ArrowUpOutlined /> 7% increase from last period
              </Text>
            </div>
          </Card>
        </Col>
      </Row>

      {/* Market Insights Section */}
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24}>
          <Card 
            title={
              <Space>
                <RiseOutlined className="card-icon" style={{ color: '#1890ff' }} />
                <span>Market Insights</span>
                <Badge count="NEW" style={{ backgroundColor: '#52c41a' }} />
              </Space>
            }
            extra={
              <Space>
                <Button type="text" icon={<FilterOutlined />}>Filter</Button>
                <Button type="text" icon={<ReloadOutlined />} onClick={handleRefresh}>Refresh</Button>
                <Button type="text" icon={<DownloadOutlined />}>Export</Button>
              </Space>
            } 
            className="insights-card glass-effect"
            loading={loading}
            style={{ boxShadow: '0 2px 8px rgba(0,0,0,0.09)' }}
          >
            <Row gutter={[16, 16]}>
              {marketInsights.map(insight => (
                <Col xs={24} sm={12} md={6} key={insight.id}>
                  <Card 
                    className="insight-item" 
                    hoverable 
                    style={{ 
                      height: '100%', 
                      transition: 'all 0.3s',
                      borderLeft: `3px solid ${insight.trend === 'up' ? '#52c41a' : insight.trend === 'down' ? '#f5222d' : '#1890ff'}`
                    }}
                  >
                    <Statistic
                      title={insight.metric}
                      value={insight.value}
                      valueStyle={{ 
                        color: insight.trend === 'up' ? '#52c41a' : 
                               insight.trend === 'down' ? '#f5222d' : '#1890ff' 
                      }}
                    />
                    <div className="insight-change" style={{ marginTop: 8 }}>
                      {insight.trend === 'up' ? (
                        <Text type="success"><ArrowUpOutlined /> {insight.change}%</Text>
                      ) : (
                        <Text type="danger"><ArrowDownOutlined /> {insight.change}%</Text>
                      )}
                      <Text type="secondary"> vs. last period</Text>
              </div>
                    <div className="insight-actions" style={{ marginTop: 12 }}>
                      <Button type="link" size="small">View Details</Button>
              </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>

      {/* Charts Section */}
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24} lg={12}>
          <Card 
            title={
              <Space size="small">
                <Package size={16} color="#1890ff" />
                <span>Inventory Status</span>
              </Space>
            }
            extra={
              <Space size="small">
                <Radio.Group 
                  defaultValue="bar" 
                  size="small" 
                  buttonStyle="solid"
                  onChange={(e) => setChartType(e.target.value)}
                  value={chartType}
                >
                  <Radio.Button value="bar"><BarChartOutlined /></Radio.Button>
                  <Radio.Button value="line"><LineChartOutlined /></Radio.Button>
                  <Radio.Button value="pie"><PieChartOutlined /></Radio.Button>
                </Radio.Group>
                <Select 
                  defaultValue="6months" 
                  style={{ width: 100 }}
                  size="small"
                >
                  <Option value="30days">30 Days</Option>
                  <Option value="3months">3 Months</Option>
                  <Option value="6months">6 Months</Option>
                  <Option value="1year">1 Year</Option>
                </Select>
              </Space>
            }
            className="chart-card glass-effect"
            loading={loading}
            style={{ boxShadow: '0 2px 8px rgba(0,0,0,0.09)' }}
            bodyStyle={{ padding: '16px', height: '320px', overflow: 'hidden' }}
          >
            <div className="chart-content" ref={chartContainerRef}>
              {renderInventoryChart()}
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card 
            title={
              <Space>
                <PieChartOutlined className="card-icon" style={{ color: '#1890ff' }} />
                <span>Platform Distribution</span>
              </Space>
            }
            extra={
              <Space>
                <Button type="text" size="small" icon={<InfoCircleOutlined />}>
                  Details
                </Button>
                <Button type="text" size="small" icon={<DownloadOutlined />}>
                  Export
                </Button>
              </Space>
            }
            className="chart-card glass-effect"
            loading={loading}
            style={{ boxShadow: '0 2px 8px rgba(0,0,0,0.09)' }}
            bodyStyle={{ padding: '16px', height: '320px', overflow: 'hidden' }}
          >
            <div className="chart-content">
              {renderPlatformChart()}
            </div>
          </Card>
        </Col>
      </Row>

      {/* Top Selling Products Table */}
      <Row gutter={[16, 16]} className="mt-4">
        <Col xs={24}>
          <Card 
            title={
              <Space>
                <ShoppingOutlined className="card-icon" />
                <span>Top Selling Products</span>
              </Space>
            }
            extra={
              <Space>
                <Input 
                  placeholder="Search products" 
                  prefix={<SearchOutlined />} 
                  style={{ width: 200 }}
                />
                <Select defaultValue="sales" style={{ width: 120 }}>
                  <Option value="sales">By Sales</Option>
                  <Option value="growth">By Growth</Option>
                  <Option value="profit">By Profit</Option>
                </Select>
                <Button type="primary" size="small">
                  View All Products
                </Button>
              </Space>
            }
            className="table-card glass-effect"
            loading={loading}
          >
            <Table 
              dataSource={topSellingProducts} 
              columns={columns} 
              rowKey="id"
              pagination={false}
              size="middle"
            />
            <div className="table-footer" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
              <Button type="link" icon={<DownloadOutlined />}>Export to Excel</Button>
              <Button type="link" icon={<SyncOutlined />} onClick={handleRefresh}>Refresh Data</Button>
            </div>
          </Card>
        </Col>
      </Row>

      {/* Recent Alerts Section */}
      <Row gutter={[16, 16]} className="mt-4">
        <Col xs={24}>
          <Card 
            title={
              <Space>
                <BellOutlined className="card-icon" />
                <span>Recent Alerts & Insights</span>
                <Badge count={recentAlerts.length} style={{ backgroundColor: '#f5222d' }} />
              </Space>
            }
            extra={
              <Space>
                <Radio.Group onChange={handleAlertFilterChange} value={alertFilter} buttonStyle="solid" size="small">
                  <Radio.Button value="all">All</Radio.Button>
                  <Radio.Button value="price">Price</Radio.Button>
                  <Radio.Button value="inventory">Inventory</Radio.Button>
                  <Radio.Button value="keyword">Keywords</Radio.Button>
                  <Radio.Button value="competitor">Competitors</Radio.Button>
                </Radio.Group>
                <Button type="link">View All Alerts</Button>
              </Space>
            } 
            className="alerts-card glass-effect"
            loading={loading}
          >
            <div className="alerts-list">
              {filteredAlerts.length > 0 ? (
                filteredAlerts.map(alert => (
                  <div key={alert.id} className="alert-item" style={{ marginBottom: 16, padding: 16, border: '1px solid #f0f0f0', borderRadius: 8 }}>
                <div className="alert-content">
                      <div className="alert-header" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                        <Space>
                          {renderAlertIcon(alert.type)}
                          <Title level={5} style={{ margin: 0 }}>{alert.title}</Title>
                          {renderSeverityTag(alert.severity)}
                        </Space>
                        <Space>
                          <Clock size={14} />
                          <Text type="secondary">{alert.time}</Text>
                        </Space>
                </div>
                      <Paragraph className="alert-description">
                        {alert.description}
                      </Paragraph>
                      <div className="alert-actions">
                        <Space>
                          <Button size="small" type="primary">Take Action</Button>
                          <Button size="small">Dismiss</Button>
                          <Button size="small" type="link">View Details</Button>
                        </Space>
                </div>
              </div>
                </div>
                ))
              ) : (
                <Empty 
                  description="No alerts match your filter" 
                  image={Empty.PRESENTED_IMAGE_SIMPLE} 
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>

      {/* Quick Actions Section - NEW */}
      <Row gutter={[16, 16]} className="mt-4">
        <Col xs={24}>
          <Card 
            title={
              <Space>
                <SettingOutlined className="card-icon" />
                <span>Quick Actions</span>
              </Space>
            }
            className="actions-card glass-effect"
            loading={loading}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8} md={6}>
                <Card className="action-item" hoverable>
                  <div className="action-icon" style={{ textAlign: 'center', marginBottom: 16 }}>
                    <Avatar size={48} style={{ backgroundColor: '#1890ff' }}>
                      <SearchOutlined />
                    </Avatar>
                  </div>
                  <div className="action-content" style={{ textAlign: 'center' }}>
                    <Title level={5}>Run Competitor Analysis</Title>
                    <Text type="secondary">Analyze your top competitors</Text>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={8} md={6}>
                <Card className="action-item" hoverable>
                  <div className="action-icon" style={{ textAlign: 'center', marginBottom: 16 }}>
                    <Avatar size={48} style={{ backgroundColor: '#52c41a' }}>
                      <DollarOutlined />
                    </Avatar>
                  </div>
                  <div className="action-content" style={{ textAlign: 'center' }}>
                    <Title level={5}>Price Optimization</Title>
                    <Text type="secondary">Get pricing recommendations</Text>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={8} md={6}>
                <Card className="action-item" hoverable>
                  <div className="action-icon" style={{ textAlign: 'center', marginBottom: 16 }}>
                    <Avatar size={48} style={{ backgroundColor: '#722ed1' }}>
                      <Eye size={24} />
                    </Avatar>
                  </div>
                  <div className="action-content" style={{ textAlign: 'center' }}>
                    <Title level={5}>Keyword Analysis</Title>
                    <Text type="secondary">Discover trending keywords</Text>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={8} md={6}>
                <Card className="action-item" hoverable>
                  <div className="action-icon" style={{ textAlign: 'center', marginBottom: 16 }}>
                    <Avatar size={48} style={{ backgroundColor: '#fa8c16' }}>
                      <DownloadOutlined />
                    </Avatar>
                  </div>
                  <div className="action-content" style={{ textAlign: 'center' }}>
                    <Title level={5}>Export Reports</Title>
                    <Text type="secondary">Download analytics reports</Text>
                  </div>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardView; 