import React, { useEffect, useState, useCallback } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Loader2 } from 'lucide-react';
import { fetchPowerBIConfig, fetchPowerBIReportByPage } from '../../routes/AppRoutes';
import { getUserData } from '../../utils/cookieUtils';

const FullScreenPowerBIEmbed = ({ reportType, onPagesLoaded, currentPage }) => {
 
  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [powerBIReport, setPowerBIReport] = useState(null);

  const navigateToPage = useCallback(async (pageId) => {
    if (!powerBIReport?.report) {
      // console.log('PowerBI report not ready for navigation');
      return;
    }
    
    try {
      // console.log('Attempting to navigate to page:', pageId);
      const pages = await powerBIReport.report.getPages();
      // console.log('Available pages:', pages.map(p => ({ name: p.name })));
      
      const targetPage = pages.find(p => p.name === pageId);
      if (targetPage) {
        // console.log('Found matching page, navigating to:', pageId);
        await targetPage.setActive();
      } else {
        console.warn(`Page not found: ${pageId}`);
      }
    } catch (error) {
      console.error('Error during page navigation:', error);
    }
  }, [powerBIReport]);

  useEffect(() => {
    if (currentPage && powerBIReport) {
      // console.log('Current page changed to:', currentPage);
      navigateToPage(currentPage);
    }
  }, [currentPage, powerBIReport, navigateToPage]);

  const eventHandlers = new Map([
    ['loaded', async function(event) {
      try {
        // console.log('PowerBI report loaded');
        const loadedReport = event.detail;
        setPowerBIReport(loadedReport);
        
        const pages = await loadedReport.report.getPages();
        if (pages && onPagesLoaded) {
          const pageInfo = pages.map(page => ({
            name: page.name,
            displayName: page.displayName || page.name
          }));
          onPagesLoaded(pageInfo);
        }

        if (currentPage) {
          await navigateToPage(currentPage);
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error('Error in loaded event handler:', error);
        setIsLoading(false);
      }
    }],
    ['pageChanged', function(event) {
      // console.log('Page changed event:', event.detail);
    }]
  ]);

  const loadReport = useCallback(async () => {
    try {
      setIsLoading(true);
      const userData = getUserData();
      
      if (!userData || !userData.client_id) {
        throw new Error('User authentication required');
      }
      
      const pageId = currentPage?.id || currentPage;
      const response = pageId 
        ? await fetchPowerBIReportByPage(pageId)
        : await fetchPowerBIConfig();
      
      if (!response.success) {
        throw new Error(response.error || 'Failed to load report configuration');
      }

      const { reportId, embedUrl, embedToken, pages, activePage } = response.data;
     
      if (pages && onPagesLoaded) {
        const activePageInfo = pages.find(p => p.name === activePage);
        onPagesLoaded(pages, {
          id: activePage,
          displayName: activePageInfo?.displayName || activePage
        });
      }

      const config = {
        type: 'report',
        id: reportId,
        embedUrl: embedUrl,
        tokenType: models.TokenType.Embed,
        accessToken: embedToken,
        settings: {
          panes: {
            pageNavigation: {
              visible: false
            }
          },
          background: models.BackgroundType.Transparent,
          localeSettings: {
            language: "en",
            formatLocale: "en"
          }
        },
        pageName: pageId // Set initial page
      };


      setReport(config);
      setIsLoading(false);
      setError(null);
    } catch (err) {
      console.error('Error loading PowerBI report:', err);
      setError(err.message || 'Failed to load report');
      setIsLoading(false);
    }
    
  }, [currentPage, onPagesLoaded]);

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  const handleRetry = () => {
    loadReport();
  };

  useEffect(() => {
    const handleResize = () => {
      if (powerBIReport?.report) {
        powerBIReport.report.updateSettings({
          layoutType: window.innerWidth <= 768 ? 
            models.LayoutType.MobilePortrait : 
            models.LayoutType.Master
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [powerBIReport]);

  const getMobileSettings = () => {
    if (window.innerWidth <= 768) {
      return {
        layoutType: models.LayoutType.MobilePortrait,
        background: models.BackgroundType.Transparent,
        panes: {
          pageNavigation: {
            visible: false
          }
        },
        displayOption: models.DisplayOption.FitToWidth
      };
    }
    return {
      layoutType: models.LayoutType.Master,
      background: models.BackgroundType.Transparent,
      panes: {
        pageNavigation: {
          visible: false
        }
      }
    };
  };

  if (error) {
    return (
      <div className="flex items-center justify-center h-full w-full">
        <div className="p-6 bg-white rounded-lg shadow-lg text-center">
          <p className="text-red-500 text-lg">{error}</p>
          <button 
            onClick={handleRetry}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="powerbi-container">
      {isLoading || !report ? (
        <div className="loading-wrapper">
          <div className="loading-content">
            <Loader2 className="loader-icon" />
            <p className="loading-text">Loading  Report...</p>
          </div>
        </div>
      ) : ( 
        <div className="powerbi-wrapper" style={{ opacity: powerBIReport ? 1 : 0 }}>
          <PowerBIEmbed
            embedConfig={{
              ...report,
              settings: {
                ...report.settings,
                ...getMobileSettings(),
                background: models.BackgroundType.Transparent,
                loadingBehavior: 'hidden'
              }
            }}
            cssClassName="powerbi-embed"
            eventHandlers={eventHandlers}
          />
        </div>
      )}
    </div>
  );
};

export default FullScreenPowerBIEmbed;
