import React from 'react';
import { Card, Row, Col, Space } from 'antd';
import { Statistic } from 'antd';
import { 
  BarChartOutlined, PieChartOutlined,
  LineChartOutlined, TableOutlined,
  SearchOutlined
} from '@ant-design/icons';

const KeywordView = ({ filters }) => {
  return (
    <div className="keyword-view">
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={16}>
          <Card 
            title={
              <Space>
                <BarChartOutlined className="card-icon" />
                <span>Keyword Performance</span>
              </Space>
            } 
            className="chart-card glass-effect"
          >
            <div className="chart-content">
              <div className="chart-placeholder">
                <BarChartOutlined /> Keyword Performance Chart
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card 
            title={
              <Space>
                <PieChartOutlined className="card-icon" />
                <span>Share of Voice</span>
              </Space>
            } 
            className="chart-card glass-effect"
          >
            <div className="chart-content">
              <div className="chart-placeholder">
                <PieChartOutlined /> Share of Voice Chart
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      
      <Row gutter={[16, 16]} className="mt-4">
        <Col xs={24}>
          <Card 
            title={
              <Space>
                <LineChartOutlined className="card-icon" />
                <span>Organic vs. Sponsored Performance</span>
              </Space>
            } 
            className="chart-card glass-effect"
          >
            <div className="chart-content">
              <div className="chart-placeholder">
                <LineChartOutlined /> Organic vs. Sponsored Chart
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      
      <Row gutter={[16, 16]} className="mt-4">
        <Col xs={24}>
          <Card 
            title={
              <Space>
                <TableOutlined className="card-icon" />
                <span>Keyword Opportunities</span>
              </Space>
            } 
            className="table-card glass-effect"
          >
            <div className="table-content">
              <div className="table-placeholder">
                <TableOutlined /> Keyword Opportunities Table
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mt-4">
        <Col span={24}>
          <Card title="Keyword Analysis">
            <Statistic
              title="Tracked Keywords"
              value={156}
              prefix={<SearchOutlined />}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default KeywordView; 