import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login';
import Dashboard from './components/Dashboard';
import { AuthProvider, useAuth } from './context/AuthContext';
import UnderConstruction from './components/UnderConstruction';
import AuthorizationDenied from './components/Authorizationdenied';
import AdminDashboard from './components/Admin/AdminDashboard';
import AccessDenied from './components/AccessDenied';

// Import the main Masters component which will handle routing internally
import Masters from './components/PO Dashboard/Masters';
import Summary from './components/PO Dashboard/SummaryPage';
import Potable from './components/PO Dashboard/PoTable';
import ExecutiveSummary from './components/Ecom/ExecutiveSummary';
import SalesAnalytics from './components/Ecom/SalesAnalytics';
import FullScreenPowerBIEmbed from './components/PowerBI/powerbi';
import DSARevamp from './components/DSA/DSARevamp';
import DSA from './components/DSA/views/DashboardView';

// Import necessary API functions for Masters
import { 
  fetchMasters, 
  fetchPlatforms, 
  updateMasterData, 
  disableSkus, 
  soldToPricing, 
  uploadMasterData 
} from './routes/AppRoutes';
import { getUserData } from './utils/cookieUtils';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <div className="loading-container">Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  return (
    <AuthProvider>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/AuthorizationDenied" element={<AuthorizationDenied />} />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="/dsa/dashboard" element={<DSA />} />
        {/* Protected Routes */}
        <Route path="/" element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }>
          {/* Default redirect to PO Dashboard */}
          <Route index element={<Navigate to="/po/summary" replace />} />
          
          {/* DSA Routes */}
          <Route path="dsa" element={
            <FullScreenPowerBIEmbed reportType="dsa" />
          } />
          <Route path="dsa/:pageId" element={
            <FullScreenPowerBIEmbed reportType="dsa" />
          } />

          {/* DSA Revamp Routes - Dynamic routing */}
          <Route path="dsa-revamp/*" element={
            <DSARevamp />
          } />
          
          {/* Ecom Dashboard Routes */}
          <Route path="ecom/executive-summary" element={
            <ExecutiveSummary />
          } />
          <Route path="ecom/sales" element={
            <SalesAnalytics />
          } />
          <Route path="ecom/revenue" element={
            <UnderConstruction />
          } />
          <Route path="ecom/products" element={
            <UnderConstruction />
          } />
          <Route path="ecom/marketing" element={
            <UnderConstruction />
          } />
          
          {/* PO Dashboard Routes */}
          <Route path="po/summary" element={
            <Summary />
          } />
          <Route path="po/table" element={
            <Potable />
          } />
          
          {/* Master Routes - Dynamic routing */}
          <Route path="po/master/*" element={
            <Masters 
              fetchMasters={fetchMasters}
              fetchPlatforms={fetchPlatforms}
              updateMasterData={updateMasterData}
              disableSkus={disableSkus}
              soldToPricing={soldToPricing}
              uploadMasterData={uploadMasterData}
              getUserData={getUserData}
            />
          } />
          
          {/* Admin Route */}
          <Route path="admin" element={
            <AdminDashboard />
          } />
        </Route>

        {/* Catch-all redirect */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
