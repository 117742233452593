import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getAuthData, clearAuthCookies } from '../utils/cookieUtils';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  const checkAuth = useCallback(() => {
    const { token, user } = getAuthData();
    console.log('Auth check:', { hasToken: !!token, hasUser: !!user });
    
    // Store current auth state in sessionStorage to persist across refreshes
    if (token && user) {
      sessionStorage.setItem('isAuthenticated', 'true');
      setIsAuthenticated(true);
      setUser(user);
    } else {
      sessionStorage.removeItem('isAuthenticated');
      setIsAuthenticated(false);
      setUser(null);
    }
    
    setIsLoading(false);
  }, []);

  // On component mount
  useEffect(() => {
    checkAuth();
    
    // Also check token availability periodically to catch any issues
    const interval = setInterval(() => {
      const { token } = getAuthData();
      if (!token && sessionStorage.getItem('isAuthenticated') === 'true') {
        console.warn('Token lost during session, revalidating...');
        checkAuth();
      }
    }, 60000); // Check every minute
  
    return () => clearInterval(interval);
  }, [checkAuth]);

  const login = useCallback((userData) => {
    setIsAuthenticated(true);
    setUser(userData);
    sessionStorage.setItem('isAuthenticated', 'true');
  }, []);
  
  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setUser(null);
    clearAuthCookies();
    sessionStorage.removeItem('isAuthenticated');
    window.location.href = '/login';
  }, []);

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      isLoading, 
      login, 
      logout,
      user,
      checkAuth // Expose this function for manual checks
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
