import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
 
  Database, 
  Container, 
  X, 
  ChevronDown,
  TrendingUp,
  //DollarSign,
  Package,
  //Tag,
  LogOut,
  Menu,
  User,
  DatabaseBackup
} from 'lucide-react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import './Dashboard.css';
import SettingsPopup from './Assets/settings';
import NotificationBar from './NotificationBar';
import StatusDialog from './Assets/Dialog';
import FullScreenPowerBIEmbed from './PowerBI/powerbi';
import { clearAuthCookies, getUserData, getAuthData } from '../utils/cookieUtils';

const ALLOWED_DSA_PAGES = [
  'Summary',
  'Stock Availability',
  'Stock Availability Oil (Store level)',
  'Rating Trend',
  'Discount Trend',
  'RECO Pricing',
  'Selling Price Trend',
  'SOS - Overall Trend',
  'Keyword Rank Analysis',
  'Keyword Rank Trend',
  'Competitor Analytics Trend',
  'Competitor Analytics',
  'Keyword Racking Trend (Competition)'
];

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [user, setUser] = useState({
    name: '',
    imageUrl: '',
    gender: 'male',
    role: '',
    department: '',
    lastActive: new Date(),
  });
  const [activeContent, setActiveContent] = useState('PO Dashboard');
  const [expandedMenu, setExpandedMenu] = useState('PO Dashboard');
  
  const [bellNotifications, setBellNotifications] = useState([]);
  const [showMailNotifications, setShowMailNotifications] = useState(false);
  const [showBellNotifications, setShowBellNotifications] = useState(false);
  const [logoutStatus, setLogoutStatus] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [currentPowerBIPage, setCurrentPowerBIPage] = useState(null);
  const [dialogStatus, setDialogStatus] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isPowerBIInitialized, setIsPowerBIInitialized] = useState(false);
  const [showTestDataAlert, setShowTestDataAlert] = useState(true);

  const mailNotificationRef = useRef(null);
  const bellNotificationRef = useRef(null);
  const hasNavigated = useRef(false);

  const toggleMobileMenu = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsMobileMenuOpen(!isMobileMenuOpen);
    
    // Add body scroll lock when sidebar is open
    document.body.style.overflow = !isSidebarOpen ? 'hidden' : '';
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (expandedMenu === 'Ecom Dashboard' && activeContent === 'Home') {
      setActiveContent('Sales');
    }
  }, [expandedMenu, activeContent]);

  useEffect(() => {
    const checkUserData = () => {
      const userData = getUserData();
      if (!userData) {
        console.warn('No user data found, redirecting to login');
        clearAuthCookies();
        
        // Only navigate if:
        // 1. We're not already on the login page
        // 2. We haven't already triggered navigation
        if (document.location.pathname !== '/login' && !hasNavigated.current) {
          hasNavigated.current = true;
          // Use window.location for a hard redirect instead of React Router
          window.location.href = '/login';
          return;
        }
        return;
      }
      
      // Reset navigation flag when we have user data
      hasNavigated.current = false;

      setUser({
        name: `${userData.first_name} ${userData.last_name}`,
        imageUrl: userData.profile_picture || `https://ui-avatars.com/api/?name=${userData.first_name}+${userData.last_name}&background=4f46e5&color=fff`,
        gender: userData.gender || 'male',
        role: userData.role || 'Team Member',
        department: userData.department || 'General',
        client_id: userData.client_id,
        product_id: userData.product_id,
        lastActive: new Date(),
      });
    };

    checkUserData();
    
    // Use a less frequent interval to check for user data
    const interval = setInterval(checkUserData, 300000); // Check every 5 minutes
    
    return () => {
      clearInterval(interval);
    };
  }, []);  // Remove navigate from dependency array to prevent re-runs

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mailNotificationRef.current && !mailNotificationRef.current.contains(event.target)) {
        setShowMailNotifications(false);
      }
      if (bellNotificationRef.current && !bellNotificationRef.current.contains(event.target)) {
        setShowBellNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (activeContent) {
      document.title = `Dashboard - ${activeContent}`;
    }
  }, [activeContent]);

  useEffect(() => {
    if (showTestDataAlert) {
      setDialogStatus('info');
      const timer = setTimeout(() => {
        setDialogStatus(null);
        setShowTestDataAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showTestDataAlert]);

  const [menuItems, setMenuItems] = useState([
    // { 
    //   icon: PieChart, 
    //   text: 'DSA', 
    //   hasSubItems: true,
    //   subItems: [],
    //   isLoading: false,
    //   baseRoute: '/dsa'
    // },
    
    // { 
    //   icon: Database,
    //   text: 'Ecom Dashboard',
    //   hasSubItems: true,
    //   baseRoute: '/ecom',
    //   subItems: [
    //     { icon: TrendingUp, text: 'Executive Summary', route: 'executive-summary', description: 'Overview of key metrics' },
    //     { icon: TrendingUp, text: 'Sales', route: 'sales', description: 'Sales analytics' },
    //     { icon: DollarSign, text: 'Revenue', route: 'revenue', description: 'Revenue tracking' },
    //     { icon: Package, text: 'Products', route: 'products', description: 'Product management' },
    //     { icon: Tag, text: 'Marketing', route: 'marketing', description: 'Marketing campaigns' }
    //   ]
    // },
    {
      icon: Package,
      text: 'PO Dashboard',
      hasSubItems: true,
      baseRoute: '/po',
      subItems: [
        { icon: TrendingUp, text: 'Summary', route: 'summary', description: 'PO overview' },
        { icon: Database, text: 'PO Table', route: 'table', description: 'Detailed PO list' },
        { icon: DatabaseBackup, text: 'Master Data', route: 'master', description: 'Master data management' }
      ]
    },
    { 
      icon: User, 
      text: 'Admin',
      route: '/admin',
      hasSubItems: false
    }
  ]);

  const handlePowerBIPagesLoaded = useCallback((pages, activePage = null) => {
    if (isPowerBIInitialized) {
      return;
    }
    
    setIsPowerBIInitialized(true);
    
    // Clear loading state immediately
    setMenuItems(prevItems => prevItems.map(item => 
      item.text === 'DSA' 
        ? { ...item, isLoading: false }
        : item
    ));
    
    if (!pages || pages.length === 0) {
      console.warn('No PowerBI pages received');
      setMenuItems(prevItems => prevItems.map(item => 
        item.text === 'DSA' 
          ? { ...item, hasError: true, errorMessage: 'Failed to load pages' } 
          : item
      ));
      return;
    }

    try {
      // Filter pages to only include allowed pages
      const filteredPages = pages.filter(page => 
        ALLOWED_DSA_PAGES.includes(page.displayName?.trim())
      );
      
      const sortedPages = [...filteredPages].sort((a, b) => {
        // Get the index of each page name in the ALLOWED_DSA_PAGES array
        const indexA = ALLOWED_DSA_PAGES.indexOf(a.displayName?.trim());
        const indexB = ALLOWED_DSA_PAGES.indexOf(b.displayName?.trim());
        // Sort based on the order in ALLOWED_DSA_PAGES
        return indexA - indexB;
      });

      const firstPage = sortedPages[0];

      const currentPath = location.pathname;
      const currentPageId = currentPath.startsWith('/dsa/') ? currentPath.split('/').pop() : null;
      const matchingPage = currentPageId 
        ? sortedPages.find(page => page.name === currentPageId)
        : firstPage;

      if (currentPath.startsWith('/dsa')) {
        const pageToUse = matchingPage || firstPage;
        setCurrentPowerBIPage({
          id: pageToUse.name,
          displayName: pageToUse.displayName?.trim() || 'Untitled'
        });
        setActiveContent(pageToUse.displayName?.trim() || 'Untitled');
        
        if (currentPath === '/dsa' || !matchingPage) {
          navigate(`/dsa/${pageToUse.name}`, { replace: true });
        }
      }

      setMenuItems(prevItems => prevItems.map(item => {
        if (item.text === 'DSA') {
          return {
            ...item,
            hasSubItems: true,
            isLoading: false,
            hasError: false,
            errorMessage: null,
            subItems: sortedPages.map(page => ({
              icon: TrendingUp,
              text: page.displayName?.trim() || 'Untitled',
              name: page.name,
              displayName: page.displayName?.trim() || 'Untitled',
              order: ALLOWED_DSA_PAGES.indexOf(page.displayName?.trim())
            }))
          };
        }
        return item;
      }));
    } catch (error) {
      console.error('Error processing PowerBI pages:', error);
      setMenuItems(prevItems => prevItems.map(item => 
        item.text === 'DSA' 
          ? { 
              ...item, 
              isLoading: false, 
              hasError: true, 
              errorMessage: error.message,
              subItems: []
            } 
          : item
      ));
    }
  }, [location.pathname, navigate, setActiveContent, isPowerBIInitialized, setMenuItems]);

  const handleMenuItemClick = (item) => {
    if (item.hasSubItems) {
      setExpandedMenu(prevMenu => prevMenu === item.text ? null : item.text);
      
      if (item.text === 'DSA') {
        // Only initialize if not already loaded
        if (!isPowerBIInitialized && !item.subItems.length) {
          setIsPowerBIInitialized(false);
          setMenuItems(prevItems => prevItems.map(menuItem => 
            menuItem.text === 'DSA' 
              ? { ...menuItem, isLoading: true }
              : menuItem
          ));
        }
        // Only navigate if we're not already on a DSA page
        if (!location.pathname.startsWith('/dsa')) {
          navigate('/dsa');
        }
        return;
      }
      
      // Handle other menu items with subitems
      if (expandedMenu !== item.text && item.subItems && item.subItems.length > 0) {
        const firstSubItem = item.subItems[0];
        setActiveContent(firstSubItem.text);
        if (item.baseRoute && firstSubItem.route) {
          navigate(`${item.baseRoute}/${firstSubItem.route}`);
        }
      }
    } else {
      // Handle menu items without subitems
      setActiveContent(item.text);
      setExpandedMenu(null);
      if (item.route) {
        navigate(item.route);
      }
    }
  };

  const handleSubItemClick = (parentItem, subItem, event) => {
    if (event) {
      event.stopPropagation();
    }
    
    // Prevent action if we're already on the same page
    if (activeContent === (subItem.displayName || subItem.text)) {
      return;
    }
    
    if (parentItem.text === 'DSA') {
      const pageId = subItem.name;
      setCurrentPowerBIPage({
        id: pageId,
        displayName: subItem.displayName
      });
      setActiveContent(subItem.displayName);
      navigate(`${parentItem.baseRoute}/${pageId}`, { replace: true });
    } else {
      const route = `${parentItem.baseRoute}/${subItem.route}`;
      setActiveContent(subItem.text);
      navigate(route, { replace: true });
    }

    if (isMobile) {
      closeSidebar();
    }
  };

  const handleLogout = () => {
    setLogoutStatus('loading');
    setDialogStatus('loading');
    
    // First, clear auth cookies
    clearAuthCookies();
    sessionStorage.removeItem('isAuthenticated');
    
    // Then update component state
    setTimeout(() => {
      setLogoutStatus('success');
      setDialogStatus('success');
      
      // Use a short delay before navigation to ensure UI updates are visible
      setTimeout(() => {
        // Replace with window.location for a hard redirect to avoid React Router issues
        window.location.href = '/login';
      }, 1000);
    }, 500);
  };

  const handleDialogClose = () => {
    setDialogStatus(null);
    
    // If we've already logged out successfully, redirect
    if (logoutStatus === 'success') {
      window.location.href = '/login';
    }
  };

  const toggleNotifications = (type) => {
    if (type === 'mail') {
      setShowMailNotifications(prev => !prev);
      setShowBellNotifications(false);
    } else {
      setShowBellNotifications(prev => !prev);
      setShowMailNotifications(false);
    }
  };

  const clearAllNotifications = (type) => {
    if (type === 'mail') {
      return;
    } else {
      setBellNotifications([]);
    }
  };

  const removeNotification = (type, id) => {
    if (type !== 'mail') {
      setBellNotifications(prevNotifications => 
        prevNotifications.filter(notif => notif.id !== id)
      );
    }
  };

  const renderSubmenuItem = (item, subItem) => {
    return (
      <div
        className={`submenu-item ${
          activeContent === (subItem.displayName || subItem.text) ? 'active' : ''
        }`}
        onClick={() => handleSubItemClick(item, subItem)}
      >
        <div className="submenu-content">
          <div className="submenu-header">
            <span className="submenu-title">
              {subItem.displayName || subItem.text}
            </span>
          </div>
          {subItem.description && (
            <span className="submenu-description">{subItem.description}</span>
          )}
        </div>
      </div>
    );
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
    setIsMobileMenuOpen(false);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    // Check if we're at the root dashboard path and redirect to PO dashboard
    if (location.pathname === '/') {
      navigate('/po/summary');
    }
    
    const path = location.pathname;
    const currentMenuItem = menuItems.find(item => 
      path.startsWith(item.baseRoute || item.route)
    );

    if (currentMenuItem) {
      setExpandedMenu(currentMenuItem.hasSubItems ? currentMenuItem.text : null);
      
      if (currentMenuItem.hasSubItems) {
        const subPath = path.split('/').pop();
        const currentSubItem = currentMenuItem.subItems.find(
          sub => sub.route === subPath
        );
        if (currentSubItem) {
          setActiveContent(currentSubItem.text);
        }
      } else {
        setActiveContent(currentMenuItem.text);
      }
    }
  }, [location.pathname, menuItems, navigate, setExpandedMenu, setActiveContent]);

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/dsa')) {
      const pageId = path.split('/').pop();
      if (currentPowerBIPage?.id !== pageId) {
        const dsaItem = menuItems.find(item => item.text === 'DSA');
        if (dsaItem && dsaItem.subItems) {
          const currentPage = dsaItem.subItems.find(page => page.name === pageId);
          if (currentPage) {
            setCurrentPowerBIPage({
              id: currentPage.name,
              displayName: currentPage.displayName
            });
            setActiveContent(currentPage.displayName);
          }
        }
      }
    }
  }, [location.pathname, menuItems, currentPowerBIPage?.id, setCurrentPowerBIPage, setActiveContent]);

  // Add this new effect to handle DSA initialization
  useEffect(() => {
    if (location.pathname.startsWith('/dsa') && !isPowerBIInitialized) {
      setMenuItems(prevItems => prevItems.map(item => 
        item.text === 'DSA' 
          ? { ...item, isLoading: true }
          : item
      ));
      setExpandedMenu('DSA');
    }
  }, [location.pathname, isPowerBIInitialized, setExpandedMenu]);

  useEffect(() => {
    return () => {
      setIsPowerBIInitialized(false);
    };
  }, []);

  useEffect(() => {
    // Check if we have valid auth data on component mount and refreshes
    const checkAuth = () => {
      const { token, user } = getAuthData();
      
      console.log('Dashboard Auth Check:', { hasToken: !!token, hasUser: !!user });
      
      if (!token || !user) {
        console.warn('Missing authentication data in Dashboard');
        // Only redirect if not already on login page
        if (window.location.pathname !== '/login') {
          clearAuthCookies();
          sessionStorage.removeItem('isAuthenticated');
          navigate('/login', { replace: true });
        }
      }
    };
    
    checkAuth();
    
    // Also periodically check auth during the dashboard session
    const intervalId = setInterval(checkAuth, 5 * 60 * 1000); // Every 5 minutes
    
    return () => clearInterval(intervalId);
  }, [navigate]);

  return (
    <div className="dashboard-layout">
      {/* Sidebar */}
      <aside className={`sidebar ${isSidebarOpen ? 'active' : ''}`}>
        <div className="sidebar-header">
          <div className="brand-container">
            <div className="brand-section">
              <div className="brand-logo">
                <img src="https://i.ibb.co/vDqg4jC/no-Bg-Color.png" alt="Logo" className="company-logo" />
              </div>
              
              {/* <div className="brand">
                <img src="https://i.ibb.co/sgh73w5/KRBL-ltd.png" alt="brandlogo" className="brandlogo-logo" />
                </div> */}
              {/* <div className="brand-info">
                <span className="powered-text">Product</span>
                <div className="orange-section">
                  <img src="https://i.ibb.co/wQMtL7D/orange.png" alt="Orange icon" className="orange-icon" />
                  <span className="orange-text">orange</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        
        <nav className="sidebar-nav">
        {menuItems.map((item, index) => (
          <div key={index} className="nav-group">
            <div 
              className={`nav-item ${activeContent === item.text ? 'active' : ''}`}
              onClick={() => handleMenuItemClick(item)}
            >
              <item.icon className="nav-icon" />
              <span>{item.text}</span>
              {item.hasSubItems && (
                <ChevronDown 
                  className={`arrow ${expandedMenu === item.text ? 'expanded' : ''}`} 
                />
              )}
            </div>
            
            {item.hasSubItems && expandedMenu === item.text && (
              <div className={`submenu ${expandedMenu === item.text ? 'expanded' : ''}`}>
                {item.isLoading ? (
                  <div className="submenu-loading">Loading pages...</div>
                ) : item.hasError ? (
                  <div className="submenu-error">Failed to load pages</div>
                ) : item.subItems && item.subItems.length > 0 ? (
                  item.subItems.map((subItem) => (
                    <React.Fragment key={subItem.name || subItem.text}>
                      {renderSubmenuItem(item, subItem)}
                    </React.Fragment>
                  ))
                ) : (
                  <div className="submenu-empty">No pages available</div>
                )}
              </div>
            )}
          </div>
        ))}</nav>

        <div className="sidebar-footer">
          <button 
            className="logout-btn" 
            onClick={handleLogout}
            disabled={logoutStatus === 'loading'}
          >
            <LogOut className="logout-icon" />
            <span>Logout</span>
          </button>
        </div>
      </aside>

      {isMobile && (
        <div 
          className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`}
          onClick={closeSidebar}
        />
      )}

      {isMobile && (
        <button 
          className="mobile-nav-toggle"
          onClick={toggleMobileMenu}
          aria-label="Toggle navigation"
        >
          {isSidebarOpen ? (
            <X size={24} className="menu-icon active" />
          ) : (
            <Menu size={24} className="menu-icon" />
          )}
        </button>
      )}

      {/* Main Content */}
      <main className="main-container">
        <header className="top-header glass-effect">
          <div className="header-left">
            <button className="menu-toggle glass-effect" onClick={() => setIsMobile(!isMobile)}>
              <Container />
            </button>
            <h1 className="page-title gradient-text">{activeContent}</h1>
          </div>

          <NotificationBar 
            notifications={bellNotifications}
            showNotifications={showBellNotifications || showMailNotifications}
            toggleNotifications={toggleNotifications}
            clearAllNotifications={clearAllNotifications}
            removeNotification={removeNotification}
            notificationRef={bellNotificationRef}
            setIsSettingsOpen={setIsSettingsOpen}
            user={user}
          />
        </header>

        <div className="content">
          {location.pathname.startsWith('/dsa') ? (
            <div className="dashboard-container">
              <div className="powerbi-wrapper glass-effect">
                <FullScreenPowerBIEmbed 
                  reportType="dsa" 
                  onPagesLoaded={handlePowerBIPagesLoaded}
                  currentPage={currentPowerBIPage}
                  key={currentPowerBIPage?.id}
                />
              </div>
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </main>

      {isSettingsOpen && (
        <div className="settings-overlay" onClick={() => setIsSettingsOpen(false)}>
          <div className="settings-modal" onClick={e => e.stopPropagation()}>
            <SettingsPopup
              isOpen={isSettingsOpen}
              onClose={() => setIsSettingsOpen(false)}
              user={user}
            />
          </div>
        </div>
      )}

      {dialogStatus && (
        <StatusDialog
          status={dialogStatus}
          onSuccess={handleDialogClose}
          onClose={handleDialogClose}
          errorMessage=""
          action={logoutStatus === 'loading' ? 'logout' : 'info'}
        />
      )}
    </div>
  );
};

export default Dashboard;