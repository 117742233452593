import React, { useState, useEffect } from 'react';
import {
  Search,
  Eye,
  Edit2,

  RefreshCw,
  Download,
  Plus,
  X,
  Upload,
  Package,
  Database
} from 'lucide-react';
import * as XLSX from 'xlsx';
import '../Masters.css';

const OtherMasters = ({
  products,
  loading,
  error,
  selectedSubType,
  selectedItems,
  setSelectedItems,
  handleSelectItem,
  handleSelectAll,
  handleView,
  handleEdit,
  handleDelete,
  searchTerm,
  selectedColumn,
  setSelectedColumn,
  getColumns,
  formatColumnName,
  handleSearch,
  handleDownload,
  handleRefresh,
  handleAddRecord,
  handleClearAll,
  uploadMasterData,
  getUserData,
  handleSearchChange,
  setAlert,
  platforms,
  selectedPlatform,
  setSelectedPlatform
}) => {
  // State for upload modal
  const [showUploadModal, setShowUploadModal] = useState(false);
  
  // Initialize with first platform when component mounts for Sold-To-Party section
  useEffect(() => {
    if (selectedSubType === 'SOLDTO_PRICING' && platforms && platforms.length > 0 && !selectedPlatform) {
      // Set the platform to the first available platform
      setSelectedPlatform(platforms[0].platform_name);
    }
  }, [selectedSubType, platforms, selectedPlatform, setSelectedPlatform]);

  // Create a map of subtypes to display names
  const subTypeDisplayNames = {
    'UPLOAD_MASTER': 'Upload Masters',
    'CREDENTIALS_MASTER': 'Reliance Credentials',
    'SOLDTO_PRICING': 'Sold-To-Party',
    'DISABLED_SKUS': 'Disabled SKUs'
  };

  // Upload options for the upload master
  const uploadOptions = [
    { 
      id: 'MASTER_PRICING', 
      label: 'Master Pricing', 
      description: 'Upload Master Pricing data for all platforms' 
    },
    { 
      id: 'PLATFORM_SOLDTO_PRICING', 
      label: 'Platform Sold-To-Party Pricing', 
      description: 'Upload Platform-specific Sold-To-Party Pricing data' 
    }
  ];

  // Define editable fields for each subtype
  const getEditableFieldsForSubType = (subType) => {
    switch(subType) {
      case 'SOLDTO_PRICING':
        return [
          { key: 'platform_name', label: 'Platform', type: 'text', disabled: true },
          { key: 'pf_id', label: 'Platform ID', type: 'number', disabled: true },
          { key: 'sku_id', label: 'SKU ID', type: 'text', required: true },
          { key: 'sap_code', label: 'SAP Code', type: 'text' },
          { key: 'sold_to_party', label: 'Sold To Party', type: 'text', required: true },
          { key: 'price', label: 'Price', type: 'number', step: '0.01' },
          { key: 'base_cost', label: 'Base Cost', type: 'number', step: '0.01' },
          { key: 'location', label: 'Location', type: 'text' },
          { key: 'currency', label: 'Currency', type: 'text', defaultValue: 'INR' },
          { key: 'valid_from', label: 'Valid From', type: 'date' },
          { key: 'valid_to', label: 'Valid To', type: 'date' },
          { key: 'status', label: 'Status', type: 'select', options: ['Active', 'Inactive'] }
        ];
      
      case 'CREDENTIALS_MASTER':
        return [
          { key: 'username', label: 'Username', type: 'text', required: true },
          { key: 'password', label: 'Password', type: 'password', required: true },
          { key: 'client_id', label: 'Client ID', type: 'text' },
          { key: 'client_secret', label: 'Client Secret', type: 'text' },
          { key: 'environment', label: 'Environment', type: 'select', 
            options: ['Production', 'Development', 'Testing'] },
          { key: 'description', label: 'Description', type: 'textarea' },
          { key: 'status', label: 'Status', type: 'select', options: ['Active', 'Inactive'] }
        ];
      
      case 'DISABLED_SKUS':
        return [
          { key: 'sku_id', label: 'SKU ID', type: 'text', required: true },
          { key: 'sap_code', label: 'SAP Code', type: 'text' },
          { key: 'title', label: 'Title', type: 'text' },
          { key: 'pfid', label: 'Platform ID', type: 'number' },
          { key: 'description', label: 'Description', type: 'textarea' },
          { key: 'remark', label: 'Remark', type: 'textarea' },
          { key: 'status', label: 'Status', type: 'select', options: ['Disabled', 'Enabled'] },
          { key: 'discontinued_date', label: 'Discontinued Date', type: 'date' }
        ];
      
      default:
        return [];
    }
  };

  // // Custom edit handler for Sold-To-Party records
  // const handleSoldToEdit = (product) => {
  //   // Find the selected platform object to get its pf_id
  //   const platformObj = platforms.find(p => p.platform_name === selectedPlatform) || {};
    
  //   // Make sure platform info is included in the edited item
  //   const itemToEdit = {
  //     ...product,
  //     platform: selectedPlatform,
  //     platform_name: selectedPlatform,
  //     pf_id: product.pf_id || platformObj.pf_id || 0,
  //     _customType: 'SOLDTO_PRICING'
  //   };
    
  //   // Get custom editable fields for this subtype
  //   const customFields = getEditableFieldsForSubType('SOLDTO_PRICING');
    
  //   // Store custom fields in session storage for the modal to use
  //   sessionStorage.setItem('customFields', JSON.stringify(customFields));
    
  //   // Pass the item to the parent handler
  //   handleEdit(itemToEdit);
  // };

  // // Add this function after handleSoldToEdit
  // const handleDisabledSkuEdit = (product) => {
  //   // Create enhanced product with all necessary data
  //   const itemToEdit = {
  //     ...product,
  //     pfid: product.pfid || 0,
  //     _customType: 'DISABLED_SKUS'
  //   };
    
  //   // Get custom editable fields for this subtype
  //   const customFields = getEditableFieldsForSubType('DISABLED_SKUS');
    
  //   // Store custom fields in session storage for the modal to use
  //   sessionStorage.setItem('customFields', JSON.stringify(customFields));
    
  //   // Pass the item to the parent handler
  //   handleEdit(itemToEdit);
  // };

  // // Update the handleAddSoldToRecord function to include pf_id
  // const handleAddSoldToRecord = () => {
  //   // Find the selected platform object to get its pf_id
  //   const platformObj = platforms.find(p => p.platform_name === selectedPlatform) || {};
    
  //   // Create a basic new record with the platform info including pf_id
  //   const newRecord = {
  //     platform: selectedPlatform,
  //     platform_name: selectedPlatform,
  //     pf_id: platformObj.pf_id || 0,  // Include the platform ID
  //     _customType: 'SOLDTO_PRICING'
  //   };
    
  //   // Call the parent's handleAddRecord with this pre-populated record
  //   handleAddRecord(newRecord);
  // };

  // Render the table header with search and action buttons
  const renderTableHeader = () => (
    <div className="table-header">
      <h2 className="section-title">{subTypeDisplayNames[selectedSubType] || 'Other Masters'}</h2>
      <div className="masters-controls">
        <div className="search-container">
          <div className="column-select-container">
            <label className="column-label">Search By:</label>
            <select 
              className="column-select"
              value={selectedColumn}
              onChange={(e) => setSelectedColumn(e.target.value)}
            >
              <option value="all">All Columns</option>
              {getColumns().filter(column => column !== 'all').map(column => (
                <option key={column} value={column}>
                  {formatColumnName(column)}
                </option>
              ))}
            </select>
          </div>
          <div className="search-input-container">
            <Search className="search-icon" size={18} />
            <input
              type="text"
              placeholder={`Search by ${selectedColumn === 'all' ? 'all columns' : formatColumnName(selectedColumn)}...`}
              onChange={handleSearchChange}
              className="search-input"
            />
          </div>
        </div>
        
        <div className="masters-actions">
          <button 
            className="action-btn-header clear-btn" 
            onClick={handleClearAll}
          >
            <X size={16} />
            <span>Clear All</span>
          </button>
          <button 
            className="action-btn-header download-btn"
            onClick={handleDownload}
          >
            <Download size={16} />
            Download
          </button>
          <button 
            className="action-btn-header refresh-btn"
            onClick={handleRefresh}
          >
            <RefreshCw size={16} />
            Refresh
          </button>
          <button 
            className="action-btn-header add-btn"
            onClick={() => {
              if (selectedSubType === 'SOLDTO_PRICING') {
                // Find the selected platform object to get its pf_id
                const platformObj = platforms.find(p => p.platform_name === selectedPlatform) || {};
                
                // Create a basic new record with the platform info including pf_id
                const newRecord = {
                  platform: selectedPlatform,
                  platform_name: selectedPlatform,
                  pf_id: platformObj.pf_id || 0,
                  status: 'Active',
                  currency: 'INR',
                  valid_from: new Date().toISOString().split('T')[0], // Current date
                  _customType: 'SOLDTO_PRICING',
                  masterType: 'SOLDTO_PRICING' // Add this field for the backend
                };
                
                // Get the custom fields for this type
                const customFields = getEditableFieldsForSubType('SOLDTO_PRICING');
                
                // Store in session storage for the modal to use
                sessionStorage.setItem('customFields', JSON.stringify(customFields));
                
                // Call handleAddRecord with the new record
                handleAddRecord(newRecord);
              } else if (selectedSubType === 'DISABLED_SKUS') {
                // Create a new empty record for Disabled SKUs
                const newRecord = {
                  status: 'Disabled',
                  discontinued_date: new Date().toISOString().split('T')[0], // Current date
                  pfid: 0,
                  _customType: 'DISABLED_SKUS',
                  masterType: 'DISABLED_SKUS' // Add this field for the backend
                };
                
                // Get the custom fields for this type
                const customFields = getEditableFieldsForSubType('DISABLED_SKUS');
                
                // Store in session storage for the modal to use
                sessionStorage.setItem('customFields', JSON.stringify(customFields));
                
                // Call handleAddRecord with the new record
                handleAddRecord(newRecord);
              } else if (selectedSubType === 'CREDENTIALS_MASTER') {
                // Create a new empty record for Credentials
                const newRecord = {
                  status: 'Active', // Default value
                  environment: 'Production', // Default value
                  _customType: 'CREDENTIALS_MASTER',
                  masterType: 'CREDENTIALS_MASTER' // Add this field for the backend
                };
                // Get the custom fields for this type
                const customFields = getEditableFieldsForSubType('CREDENTIALS_MASTER');
                // Store in session storage for the modal to use
                sessionStorage.setItem('customFields', JSON.stringify(customFields));
                // Call handleAddRecord with the new record
                handleAddRecord(newRecord);
              } else {
                handleAddRecord();
              }
            }}
          >
            <Plus size={16} />
            Add Record
          </button>
        </div>
      </div>
    </div>
  );

  // Upload Section Component
  const UploadSection = () => {
    return (
      <div className="upload-section">
        <div className="upload-info">
          <Upload size={32} className="upload-icon" />
          <h3>Upload Master Files</h3>
          <p>Click the button below to upload master data files</p>
          <button 
            className="upload-btn"
            onClick={() => setShowUploadModal(true)}
          >
            <Upload size={16} />
            <span>Upload Master Data</span>
          </button>
        </div>
      </div>
    );
  };

  // Upload Modal Component
  const UploadModal = ({ onClose, platforms }) => {
    const [uploadType, setUploadType] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewData, setPreviewData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);


    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      
      const files = e.dataTransfer.files;
      if (files.length) {
        const file = files[0];
        if (file.type.includes('excel') || file.type.includes('spreadsheet') || file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
          setSelectedFile(file);
          handleExcelPreview(file);
        } else {
          setAlert({
            type: 'error',
            title: 'Invalid File Type',
            message: 'Please upload an Excel file (.xlsx or .xls)',
            showConfirmButton: false,
            onClose: () => setAlert(null)
          });
        }
      }
    };

    const handleExcelPreview = async (file) => {
      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            
            // Get the data with specific options
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { 
              header: 1,
              raw: false,
              defval: '',
              dateNF: 'dd.mm.yyyy' // Specify date format
            });

            if (jsonData.length < 2) {
              throw new Error('Excel file must contain headers and at least one row of data');
            }

            const headers = jsonData[0].map(h => h.trim());
            const rows = jsonData.slice(1).map(row => 
              headers.map((_, index) => row[index] || '')
            );

            setPreviewData({
              headers,
              rows,
              totalRows: rows.length
            });

          } catch (error) {
            console.error('Error processing Excel data:', error);
            setAlert({
              type: 'error',
              title: 'Processing Error',
              message: error.message || 'Failed to process Excel file',
              showConfirmButton: false,
              onClose: () => setAlert(null)
            });
            setSelectedFile(null);
            setPreviewData(null);
          }
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.error('Error handling Excel file:', error);
        setSelectedFile(null);
        setPreviewData(null);
      }
    };

    const handleUpload = async () => {
      if (!uploadType || !selectedFile || !previewData) {
        setAlert({
          type: 'warning',
          title: 'Missing Information',
          message: 'Please select a master type and upload a valid Excel file',
          showConfirmButton: false,
          onClose: () => setAlert(null)
        });
        return;
      }

    

      setLoading(true);
      try {
        // Get user data
        const userData = getUserData();
        if (!userData || !userData.user_id) {
          throw new Error('User authentication required');
        }

        // Create form data with consistent format
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('type', uploadType);
        formData.append('fileName', selectedFile.name);
        formData.append('uploadedBy', userData.user_id);
        
       
        // Ensure data is structured properly
        const uploadData = {
          headers: previewData.headers,
          rows: previewData.rows,
          totalRows: previewData.rows.length
        };
        
        console.log('Sending data to backend:', {
          type: uploadType,
          fileName: selectedFile.name,
          uploadedBy: userData.user_id,
          dataStructure: uploadData
        });
        
        formData.append('data', JSON.stringify(uploadData));

        // Upload the file
        const result = await uploadMasterData(formData);

        if (result.success) {
          setAlert({
            type: 'success',
            title: 'Upload Successful',
            message: result.message || `Processed ${result.processedRecords} records successfully`,
            showConfirmButton: false,
            onClose: () => {
              setAlert(null);
              onClose();
            }
          });
        } else {
          throw new Error(result.message || 'Upload failed');
        }
      } catch (error) {
        console.error('Upload error:', error);
        setAlert({
          type: 'error',
          title: 'Upload Failed',
          message: error.message || 'Failed to upload file',
          showConfirmButton: false,
          onClose: () => setAlert(null)
        });
      } finally {
        setLoading(false);
      }
    };

    return (
      <div className="modal-overlay">
        <div className="modal-content upload-modal">
          <div className="modal-header">
            <h2>Upload Master Data</h2>
            <button className="close-btn" onClick={onClose}>
              <X size={18} />
            </button>
          </div>
          <div className="modal-body">
            <div className="upload-form">
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="uploadType">Select Master Type</label>
                  <select 
                    id="uploadType"
                    value={uploadType}
                    onChange={(e) => setUploadType(e.target.value)}
                    className="form-input"
                  >
                    <option value="">Choose Master Type</option>
                    {uploadOptions.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {uploadType && (
                    <small className="form-help-text">
                      {uploadOptions.find(opt => opt.id === uploadType)?.description}
                    </small>
                  )}
                </div>
              </div>

             

              <div className="form-row">
                <div className="form-group">
                  <label>Upload Excel File</label>
                  <div 
                    className={`file-upload-wrapper ${isDragging ? 'dragging' : ''}`}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <input
                      type="file"
                      accept=".xlsx,.xls"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setSelectedFile(file);
                          handleExcelPreview(file);
                        }
                      }}
                    />
                    <div className="file-upload-label">
                      <div className="file-upload-icon">
                        <Upload size={24} />
                      </div>
                      <span className="file-upload-text">
                        {selectedFile ? selectedFile.name : 'Drag & Drop your file here'}
                      </span>
                      <span className="file-upload-hint">
                        or click to browse
                      </span>
                      <div className="file-types">
                        <span className="file-type-badge">.xlsx</span>
                        <span className="file-type-badge">.xls</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {selectedFile && previewData && (
                <div className="file-preview">
                  <div className="file-info">
                    <div className="file-preview-name">
                      <Package size={16} />
                      <span>{selectedFile.name}</span>
                    </div>
                    <div className="file-preview-stats">
                      <Database size={14} />
                      <span>{previewData.totalRows} rows</span>
                    </div>
                  </div>
                  <div className="preview-table-wrapper">
                    <table className="preview-table">
                      <thead>
                        <tr>
                          <th>#</th>
                          {previewData.headers.map((header, index) => (
                            <th key={index}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {previewData.rows.slice(0, 5).map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td>{rowIndex + 1}</td>
                            {row.map((cell, cellIndex) => (
                              <td key={cellIndex}>{cell || '-'}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {previewData.rows.length > 5 && (
                      <div className="preview-more-rows">
                        +{previewData.rows.length - 5} more rows
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button 
              className="modal-btn modal-btn-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
            <button 
              className="modal-btn modal-btn-primary"
              disabled={!uploadType || !selectedFile || !previewData || loading}
              onClick={handleUpload}
            >
              {loading ? (
                <>
                  <RefreshCw size={16} className="spinner" />
                  <span>Uploading...</span>
                </>
              ) : (
                <>
                  <Upload size={16} />
                  <span>Upload Master Data ({previewData?.totalRows || 0} rows)</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Render cell content safely
  const renderCellContent = (value) => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'object') return JSON.stringify(value);
    return value.toString();
  };

  // Render the dynamic table
  const renderDynamicTable = () => {
    // Filter data by platform for SOLDTO_PRICING section
    let platformFilteredData = products;
    if (selectedSubType === 'SOLDTO_PRICING' && selectedPlatform) {
      platformFilteredData = products.filter(product => 
        product.platform_name === selectedPlatform || 
        product.platformName === selectedPlatform || 
        product.platform === selectedPlatform
      );
    }
    
    // Then apply search filtering on the platform-filtered data
    const filteredData = handleSearch(platformFilteredData);
    
    if (!filteredData || filteredData.length === 0) {
      return <div className="no-data">No data found</div>;
    }

    // Get all unique columns from all products
    const columns = [...new Set(filteredData.flatMap(product => Object.keys(product)))];

    return (
      <div className="table-container">
        <table className="products-table">
          <thead>
            <tr>
              <th className="checkbox-cell">
                <input
                  type="checkbox"
                  checked={selectedItems.length === filteredData.length}
                  onChange={handleSelectAll}
                />
              </th>
              <th className="serial-number">S.No</th>
              {columns.map((column) => (
                <th key={column}>
                  {column
                    .split(/(?=[A-Z])|_/)
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ')}
                </th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((product, index) => (
              <tr key={index}>
                <td className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(index)}
                    onChange={() => handleSelectItem(index)}
                  />
                </td>
                <td className="serial-number">{index + 1}</td>
                {columns.map((column) => (
                  <td key={column}>{renderCellContent(product[column])}</td>
                ))}
                <td className="masters-action-buttons">
                  <button
                    className="masters-btn masters-btn-view masters-tooltip"
                    onClick={() => handleView(product)}
                    data-tooltip="View Details"
                  >
                    <Eye size={16} />
                  </button>
                  <button
                    className="masters-btn masters-btn-edit masters-tooltip"
                    onClick={() => {
                      if (selectedSubType === 'SOLDTO_PRICING') {
                        // Find the selected platform object to get its pf_id
                        const platformObj = platforms.find(p => p.platform_name === selectedPlatform) || {};
                        
                        // Make sure to include the platform and properly format the status
                        const enhancedProduct = {
                          ...product,
                          platform: selectedPlatform,
                          platform_name: selectedPlatform || product.platform_name,
                          pf_id: product.pf_id || platformObj.pf_id || 0,
                          _customType: 'SOLDTO_PRICING',
                          masterType: 'SOLDTO_PRICING', // Add this field for the backend
                          // Convert numeric status to string for the form
                          status: product.status === 1 || product.status === '1' || 
                                  product.status === true || product.status === 'true' || 
                                  product.status === 'Active' ? 'Active' : 'Inactive'
                        };
                        
                        // Get custom fields for this subtype
                        const customFields = getEditableFieldsForSubType('SOLDTO_PRICING');
                        
                        // Store custom fields in session storage for the modal to use
                        sessionStorage.setItem('customFields', JSON.stringify(customFields));
                        
                        // Pass the enhanced product to the edit handler
                        handleEdit(enhancedProduct);
                      } else if (selectedSubType === 'DISABLED_SKUS') {
                        // Ensure we preserve all existing fields from the API data
                        const enhancedProduct = {
                          ...product,
                          pfid: product.pfid || 0,
                          _customType: 'DISABLED_SKUS',
                          masterType: 'DISABLED_SKUS', // Add this field for the backend
                          title: product.title || '',
                          description: product.description || product.reason || '',
                          remark: product.remark || '',
                          status: product.status === 'active' || product.status === 'enabled' || 
                                  product.status === 'Active' || product.status === 'Enabled' ? 
                                  'Enabled' : 'Disabled',
                          discontinued_date: product.discontinued_date || new Date().toISOString().split('T')[0]
                        };
                        
                        // Get custom fields for this subtype
                        const customFields = getEditableFieldsForSubType('DISABLED_SKUS');
                        
                        // Store custom fields in session storage for the modal to use
                        sessionStorage.setItem('customFields', JSON.stringify(customFields));
                        
                        // Pass the enhanced product to the edit handler
                        handleEdit(enhancedProduct);
                      } else if (selectedSubType === 'CREDENTIALS_MASTER') {
                        // Create an enhanced product with all necessary data
                        const enhancedProduct = {
                          ...product,
                          _customType: 'CREDENTIALS_MASTER',
                          masterType: 'CREDENTIALS_MASTER' // Add this field for the backend
                        };
                        
                        // Get custom fields for this subtype
                        const customFields = getEditableFieldsForSubType('CREDENTIALS_MASTER');
                        
                        // Store custom fields in session storage for the modal to use
                        sessionStorage.setItem('customFields', JSON.stringify(customFields));
                        
                        // Pass the enhanced product to the edit handler
                        handleEdit(enhancedProduct);
                      } else {
                        handleEdit(product);
                      }
                    }}
                    data-tooltip="Edit Record"
                  >
                    <Edit2 size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Render platform cards for Sold-To-Party Pricing
  const renderPlatformCards = () => {
    if (selectedSubType === 'SOLDTO_PRICING' && platforms && platforms.length > 0) {
      return (
        <div className="platform-cards">
          {platforms.map((platform) => (
            <div
              key={platform.pf_id}
              className={`platform-card ${selectedPlatform === platform.platform_name ? 'active' : ''}`}
              onClick={() => {
                setSelectedPlatform(platform.platform_name);
              }}
            >
              <div className="platform-icon">
                <Database size={20} />
              </div>
              <div className="platform-info">
                <h3>{platform.platform_name}</h3>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {selectedSubType === 'UPLOAD_MASTER' ? (
        <div className="upload-section">
          <UploadSection />
          {showUploadModal && (
            <UploadModal 
              onClose={() => setShowUploadModal(false)} 
              platforms={platforms}
            />
          )}
        </div>
      ) : (
        <div className="masters-content-wrapper">
          {renderTableHeader()}
          
          {/* Add platform cards for Sold-To-Party Pricing */}
          {renderPlatformCards()}
          
          {loading ? (
            <div className="loading-state">
              <div className="loading-spinner"></div>
              <p>Loading data...</p>
            </div>
          ) : error ? (
            <div className="error-state">
              <p>{error}</p>
            </div>
          ) : (
            renderDynamicTable()
          )}
        </div>
      )}
    </>
  );
};

export default OtherMasters; 