import React, { useState, useEffect, useCallback } from 'react';
import { Tag, message } from 'antd';
import { AppstoreOutlined, BankOutlined, SyncOutlined } from '@ant-design/icons';
import { getUserData } from '../../utils/cookieUtils';
import { fetchUserPermissions } from '../../routes/AppRoutes';

const UserPermissionsExpanded = ({ userId, userEmail }) => {
  const [rowModules, setRowModules] = useState([]);
  const [rowPlatforms, setRowPlatforms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  
  // Define loadUserPermissions with useCallback to prevent it from changing on every render
  const loadUserPermissions = useCallback(async () => {
    try {
      setIsLoading(true);
      console.log(`Fetching permissions for user ID: ${userId}`);
      
      // Use the fetchUserPermissions from AppRoutes
      const data = await fetchUserPermissions(userId);
      
      if (data && data.success) {
        // Convert object format to array format if needed
        if (data.data) {
          // Convert modules object to array
          if (data.data.modules && typeof data.data.modules === 'object' && !Array.isArray(data.data.modules)) {
            const modulesArray = Object.entries(data.data.modules).map(([name, info]) => ({
              module_id: info.module_id,
              module_name: name,
              display_name: name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
              can_access: info.can_access ? 1 : 0,
              override: info.override
            }));
            setRowModules(modulesArray);
          } else if (Array.isArray(data.data.modules)) {
            setRowModules(data.data.modules);
          }
          
          // Convert platforms object to array
          if (data.data.platforms && typeof data.data.platforms === 'object' && !Array.isArray(data.data.platforms)) {
            const platformsArray = Object.entries(data.data.platforms).map(([name, info]) => ({
              pf_id: info.pf_id,
              platform_name: name,
              can_access: info.can_access ? 1 : 0
            }));
            setRowPlatforms(platformsArray);
          } else if (Array.isArray(data.data.platforms)) {
            setRowPlatforms(data.data.platforms);
          }
        }
      } else {
        console.error('API returned error:', data?.message || 'Unknown error');
        message.error('Failed to load user permissions');
      }
    } catch (error) {
      console.error(`Error loading permissions for user ${userId}:`, error);
      message.error('Failed to load user permissions');
    } finally {
      setIsLoading(false);
    }
  }, [userId]); // Include userId in dependencies
  
  useEffect(() => {
    // Get current project from user data in cookies
    const userData = getUserData();
    if (userData && userData.currentProject) {
      setCurrentProject(userData.currentProject);
    }
    
    loadUserPermissions();
  }, [loadUserPermissions]);
  
  // Handle quick toggle of permissions
  const handleTogglePermission = async (type, id, currentValue) => {
    try {
      setIsSaving(true);
      
      let updatedModules = [...rowModules];
      let updatedPlatforms = [...rowPlatforms];
      
      if (type === 'module') {
        updatedModules = rowModules.map(module => 
          module.module_id === id 
            ? { ...module, can_access: currentValue ? 0 : 1 } 
            : module
        );
        setRowModules(updatedModules);
      } else if (type === 'platform') {
        updatedPlatforms = rowPlatforms.map(platform => 
          platform.pf_id === id 
            ? { ...platform, can_access: currentValue ? 0 : 1 } 
            : platform
        );
        setRowPlatforms(updatedPlatforms);
      }
      
      // Get token from cookie
      
      // Save to backend
      const response = await fetch(`/api/admin/users/${userId}/permissions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          modules: type === 'module' ? updatedModules : rowModules,
          platforms: type === 'platform' ? updatedPlatforms : rowPlatforms,
          project_id: currentProject?.id // Pass the current project ID if available
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        message.success('Permission updated');
      } else {
        message.error(data.message || 'Failed to update permission');
        // Revert changes on failure
        loadUserPermissions();
      }
    } catch (error) {
      console.error('Error updating permission:', error);
      message.error('Failed to update permission');
      // Revert changes on error
      loadUserPermissions();
    } finally {
      setIsSaving(false);
    }
  };
  
  return (
    <div className="expanded-row-content">
      {isLoading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <SyncOutlined spin /> Loading permissions...
        </div>
      ) : (
        <div className="permission-summary">
          <div className="permission-section">
            <h4><AppstoreOutlined /> Module Access</h4>
            <div className="permission-tags">
              {rowModules.map(module => (
                <Tag 
                  key={module.module_id}
                  color={module.can_access ? 'success' : 'default'}
                  className="permission-tag"
                  onClick={() => !isSaving && handleTogglePermission('module', module.module_id, !!module.can_access)}
                  style={{ cursor: isSaving ? 'not-allowed' : 'pointer' }}
                >
                  {isSaving ? <SyncOutlined spin style={{ marginRight: 5 }} /> : null}
                  {module.display_name}
                </Tag>
              ))}
            </div>
          </div>
          
          <div className="permission-section">
            <h4>
              <BankOutlined /> Platform Access 
              {currentProject && <small style={{ marginLeft: '5px', fontWeight: 'normal' }}>
                for {currentProject.name}
              </small>}
            </h4>
            <div className="permission-tags">
              {rowPlatforms.map(platform => (
                <Tag 
                  key={platform.pf_id}
                  color={platform.can_access ? 'processing' : 'default'}
                  className="permission-tag"
                  onClick={() => !isSaving && handleTogglePermission('platform', platform.pf_id, !!platform.can_access)}
                  style={{ cursor: isSaving ? 'not-allowed' : 'pointer' }}
                >
                  {isSaving ? <SyncOutlined spin style={{ marginRight: 5 }} /> : null}
                  {platform.platform_name}
                </Tag>
              ))}
            </div>
            {rowPlatforms.length === 0 && (
              <div style={{ fontStyle: 'italic', color: '#999', marginTop: '10px' }}>
                No platform permissions available for this project
              </div>
            )}
          </div>
          
          <div className="permission-hint" style={{ marginTop: '10px', fontSize: '12px', color: '#888' }}>
            Click on a tag to toggle permission
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPermissionsExpanded; 