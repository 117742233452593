import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import {
  DashboardView,
  PriceAnalyticsView,
  InventoryView,
  CompetitorView,
  KeywordView
} from './views';
import './DSARevamp.css';

const DSARevamp = () => {
  const location = useLocation();
  console.log('DSARevamp component rendered');
  console.log('Current path in DSARevamp:', location.pathname);
  console.log('Available views:', { DashboardView, PriceAnalyticsView, InventoryView, CompetitorView, KeywordView });

  // Extract the current section from the path
  const currentSection = location.pathname.split('/').pop();
  console.log('Current section:', currentSection);

  return (
    <Content className="dsa-content">
      <Routes>
        <Route path="dashboard" element={<DashboardView />} />
        <Route path="price-analytics" element={<PriceAnalyticsView />} />
        <Route path="inventory" element={<InventoryView />} />
        <Route path="competitor" element={<CompetitorView />} />
        <Route path="keywords" element={<KeywordView />} />
        
        {/* Default redirect to dashboard if no specific route matched */}
        <Route path="*" element={<Navigate to="/dsa-revamp/dashboard" replace />} />
      </Routes>
    </Content>
  );
};

export default DSARevamp;