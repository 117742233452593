import axios from 'axios';
import { getCookie, clearAuthCookies } from '../utils/cookieUtils';

let API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3003';

if (API_BASE_URL.endsWith('/')) {
    API_BASE_URL = API_BASE_URL.slice(0, -1);
}

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    timeout: 10000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Request interceptor to add token
axiosInstance.interceptors.request.use(
    (config) => {
        const token = getCookie('token');
        
        // For debugging in production
        console.log(`API Request to ${config.url}:`, {
            hasToken: !!token,
            method: config.method?.toUpperCase()
        });

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        } else if (sessionStorage.getItem('isAuthenticated') === 'true') {
            // This helps detect token issues during a session
            console.warn('Session says authenticated but token is missing');
        }
        
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            const status = error.response.status;
            const originalRequest = error.config;

            // Log all API errors in production for debugging
            console.error(`API Error (${status}):`, {
                url: originalRequest.url,
                method: originalRequest.method,
                message: error.response.data?.message || error.message
            });

            // Handle token expiration
            if (status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                console.warn('Authentication token rejected or expired');
                
                // Only redirect if authenticated according to session
                if (sessionStorage.getItem('isAuthenticated') === 'true') {
                    clearAuthCookies();
                    sessionStorage.removeItem('isAuthenticated');
                    window.location.href = '/login?session=expired';
                }
                return Promise.reject(error);
            }

            // Handle other status codes
            if (status === 403) {
                window.location.href = '/AuthorizationDenied';
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
