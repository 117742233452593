export const setCookie = (name, value) => {
  try {
    // For debugging
    console.log(`Setting ${name} in localStorage: (${typeof value})`);
    
    // Store in localStorage instead of cookies
    localStorage.setItem(name, value);
  } catch (error) {
    console.error(`Error setting ${name} in localStorage:`, error);
  }
};

export const getCookie = (name) => {
  try {
    const value = localStorage.getItem(name);
    // For debugging
    console.log(`Getting ${name} from localStorage = ${value ? 'exists' : 'missing'}`);
    return value;
  } catch (error) {
    console.error(`Error getting ${name} from localStorage:`, error);
    return null;
  }
};

export const removeCookie = (name) => {
  try {
    localStorage.removeItem(name);
  } catch (error) {
    console.error(`Error removing ${name} from localStorage:`, error);
  }
};

export const setAuthCookies = (token, user) => {
  console.log('Setting auth cookies', { hasToken: !!token, hasUser: !!user });
  
  // For production debugging
  localStorage.setItem('debug_token_set', 'true');
  localStorage.setItem('debug_token_time', new Date().toISOString());
  
  setCookie('token', token);
  setCookie('user', JSON.stringify(user));
};

export const clearAuthCookies = () => {
  removeCookie('token');
  removeCookie('user');
  
  // Also clear localStorage debug items
  localStorage.removeItem('debug_token_set');
  localStorage.removeItem('debug_token_time');
};

export const getAuthData = () => {
  const token = getCookie('token');
  const userStr = getCookie('user');
  const user = userStr ? JSON.parse(userStr) : null;
  
  // For production debugging
  if (token) {
    localStorage.setItem('debug_token_read', 'true');
    localStorage.setItem('debug_token_read_time', new Date().toISOString());
  }
  
  return { token, user };
};

export const setUserData = (userData) => {
  const encodedData = btoa(JSON.stringify(userData));
  setCookie('userData', encodedData, 1); // 1 day expiration
};

export const getUserData = () => {
  try {
    const userStr = getCookie('user');
    const userData = userStr ? JSON.parse(userStr) : null;
    
    if (!userData || !userData.user_id) {
      console.warn('Invalid or incomplete user data in cookie');
      return null;
    }
    
    return userData;
  } catch (error) {
    console.error('Error parsing user data from cookie:', error);
    return null;
  }
}; 