// Client/src/components/Masters.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { 
  Package, 
  DollarSign, 
  Truck, 
  Key,
  Upload,
  Settings,
  Database,
  Lock
} from 'lucide-react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { updateDisabledSku, addDisabledSku, updateSoldToPartyPricing, addSoldToPartyPricing } from '../../routes/AppRoutes';

import './Masters.css';
import * as XLSX from 'xlsx';
import { AlertCard } from '../Assets/Dialog';
import { debounce } from 'lodash';
import ProductMaster from './Masters/ProductMaster';
import PriceMaster from './Masters/PriceMaster';
import ShippingMaster from './Masters/ShippingMaster';
import OtherMasters from './Masters/OtherMasters';
import { ViewModal, EditModal } from './Masters/SharedModals';


const Masters = ({ 
  fetchMasters,
  fetchPlatforms,
  updateMasterData,
  disableSkus,
  soldToPricing,
  uploadMasterData,
  getUserData
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [platformMap, setPlatformMap] = useState({}); // Maps platform names to IDs for API operations
  const [selectedMasterType, setSelectedMasterType] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [alert, setAlert] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState('all');
  const [showSubTypes, setShowSubTypes] = useState(false);
  const [selectedSubType, setSelectedSubType] = useState(null);

  const masterTypes = useMemo(() => [
    { id: 'PRODUCT_MASTER', label: 'Product Masters', icon: Package, path: 'product' },
    { id: 'PRICE_MASTER', label: 'Price Masters', icon: DollarSign, path: 'price' },
    { id: 'SHIPPING_MASTER', label: 'Shipping Masters', icon: Truck, path: 'shipping' },
    { 
      id: 'OTHER_MASTER', 
      label: 'Other Masters', 
      icon: Settings,
      path: 'other',
      subTypes: [
        { id: 'UPLOAD_MASTER', label: 'Upload Masters', icon: Upload, path: 'upload' },
        { id: 'CREDENTIALS_MASTER', label: 'Reliance Credentials', icon: Key, path: 'credentials' },
        { id: 'SOLDTO_PRICING', label: 'Sold-To-Party Pricing', icon: Database, path: 'soldto' },
        { id: 'DISABLED_SKUS', label: 'Disabled SKUs', icon: Lock, path: 'disabled-skus' }
      ]
    }
  ], []);

  const uploadOptions = [
    { 
      id: 'MASTER_PRICING', 
      label: 'Master Pricing', 
      description: 'Upload Master Pricing data for all platforms' 
    },
    { 
      id: 'PLATFORM_SOLDTO_PRICING', 
      label: 'Platform Sold-To-Party Pricing', 
      description: 'Upload Platform-specific Sold-To-Party Pricing data' 
    }
  ];

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const currentPath = pathSegments[pathSegments.length - 1];
    
    const masterType = masterTypes.find(type => type.path === currentPath);
    
    if (masterType) {
      setSelectedMasterType(masterType.id);
      setShowSubTypes(false);
      setSelectedSubType(null);
    } else {
      for (const type of masterTypes) {
        if (type.subTypes) {
          const subType = type.subTypes.find(subType => subType.path === currentPath);
          if (subType) {
            setSelectedMasterType(type.id);
            setShowSubTypes(true);
            setSelectedSubType(subType.id);
            break;
          }
        }
      }
    }
  }, [location.pathname, masterTypes]);

  const fetchProductsForPlatform = useCallback(async () => {
    if (selectedSubType === 'UPLOAD_MASTER') {
      return;
    }

    setLoading(true);
    setError(null);
    try {
      let response;
      
      if (selectedSubType === 'SOLDTO_PRICING') {
        response = await soldToPricing(selectedPlatform);
      } else if (selectedSubType === 'DISABLED_SKUS') {
        response = await disableSkus();
      } else {
        response = await fetchMasters(
          selectedMasterType,
          ['PRICE_MASTER', 'SHIPPING_MASTER'].includes(selectedMasterType) ? selectedPlatform : null
        );
      }

      if (!response.success) {
        throw new Error(response.message || 'Failed to fetch data');
      }

      setProducts(Array.isArray(response.data) ? response.data : []);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message || 'Failed to fetch data');
      setProducts([]);
    } finally {
      setLoading(false);
    }
  }, [selectedMasterType, selectedPlatform, selectedSubType, fetchMasters, soldToPricing, disableSkus]);

  useEffect(() => {
    const loadPlatforms = async () => {
      try {
        const response = await fetchPlatforms();
        if (response.success && Array.isArray(response.data)) {
          setPlatforms(response.data);
          
          const platformMapping = {};
          response.data.forEach(p => {
            platformMapping[p.platform_name] = p.pf_id;
          });
          setPlatformMap(platformMapping);
          
          if (['PRICE_MASTER', 'SHIPPING_MASTER'].includes(selectedMasterType)) {
            if (response.data.length > 0 && !selectedPlatform) {
              setSelectedPlatform(response.data[0].platform_name);
            }
          }
        }
      } catch (error) {
        console.error('Error loading platforms:', error);
        setAlert({
          type: 'error',
          message: 'Failed to load platforms. Please refresh the page.',
          title: 'Error'
        });
      }
    };

    loadPlatforms();
    
    if (selectedPlatform) {
      fetchProductsForPlatform();
    }
  }, [selectedMasterType, selectedPlatform, fetchPlatforms, fetchProductsForPlatform]);

  useEffect(() => {
    const loadData = async () => {
      if (selectedSubType === 'UPLOAD_MASTER') {
        return;
      }

      setLoading(true);
      setError(null);
      try {
        let response;
        
        if (selectedSubType === 'DISABLED_SKUS') {
          response = await disableSkus();
        } else if (selectedSubType === 'SOLDTO_PRICING') {
          response = await soldToPricing(selectedPlatform);
        } else if (['PRICE_MASTER', 'SHIPPING_MASTER'].includes(selectedMasterType)) {
          if (selectedPlatform) {
            response = await fetchMasters(selectedMasterType, selectedPlatform);
          }
        } else {
          response = await fetchMasters(selectedMasterType);
        }

        if (!response.success) {
          throw new Error(response.message || 'Failed to fetch data');
        }

        setProducts(Array.isArray(response.data) ? response.data : []);
      } catch (err) {
        console.error('Error processing data:', err);
        setError(err.message || 'Failed to fetch data');
        setProducts([]);
      } finally {
        setLoading(false);
      }
    };

    if (selectedMasterType || selectedSubType) {
      loadData();
    }
  }, [selectedMasterType, selectedPlatform, selectedSubType, disableSkus, soldToPricing, fetchMasters]);

  useEffect(() => {
    if (selectedMasterType === 'CREDENTIALS_MASTER') {
      const fetchCredentials = async () => {
        try {
          setLoading(true);
          const response = await fetchMasters('CREDENTIALS_MASTER');
          if (response.success) {
            setProducts(response.data);
          }
        } catch (error) {
          console.error('Error fetching credentials:', error);
          setError('Failed to fetch credentials data');
        } finally {
          setLoading(false);
        }
      };
      
      fetchCredentials();
    }
  }, [selectedMasterType, fetchMasters]);

  const handleSelectItem = (id) => {
    setSelectedItems(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      }
      return [...prev, id];
    });
  };

  const handleSelectAll = () => {
    if (selectedItems.length === products.length) {
      setSelectedItems([]);
      setAlert({
        type: 'info',
        title: 'Selection Cleared',
        message: 'All selections have been cleared',
        showConfirmButton: false,
        onClose: () => setAlert(null)
      });
    } else {
      setSelectedItems(products.map((_, index) => index));
      setAlert({
        type: 'info',
        title: 'All Selected',
        message: `${products.length} records selected`,
        showConfirmButton: false,
        onClose: () => setAlert(null)
      });
    }
  };

  const handleDownload = () => {
    if (selectedItems.length === 0) {
      setAlert({
        type: 'warning',
        title: 'No Selection',
        message: 'Please select at least one record to export',
        showConfirmButton: false,
        onClose: () => setAlert(null)
      });
      return;
    }

    const dataToExport = selectedItems.length > 0
      ? products.filter((_, index) => selectedItems.includes(index))
      : products;

    const dataWithSerialNo = dataToExport.map((item, index) => ({
      'S.No': index + 1,
      ...item
    }));

    const ws = XLSX.utils.json_to_sheet(dataWithSerialNo);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Masters");
    
    const timestamp = new Date().toISOString().split('T')[0];
    const filename = `${selectedMasterType}_${selectedPlatform || 'ALL'}_${timestamp}.xlsx`;
    
    XLSX.writeFile(wb, filename);

    setAlert({
      type: 'success',
      title: 'Export Successful',
      message: `${selectedItems.length} records exported successfully`,
      showConfirmButton: false,
      onClose: () => setAlert(null)
    });
  };

  const handleRefresh = () => {
    fetchProductsForPlatform();
    setAlert({
      type: 'success',
      title: 'Data Refreshed',
      message: 'Master data has been refreshed successfully',
      showConfirmButton: false,
      onClose: () => setAlert(null)
    });
  };

  const getColumns = () => {
    if (!products || products.length === 0) return [];
    
    const firstProduct = products[0];
    return Object.keys(firstProduct).filter(key => 
      !['id', '_id', '__v', 'selected', 'type', 'all'].includes(key.toLowerCase())
    );
  };

  const formatColumnName = (column) => {
    if (!column || column === 'all') return 'All Columns';
    return column
      .split(/(?=[A-Z])|_|\s/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
      .trim();
  };

  const handleSearch = useCallback((data) => {
    if (!searchTerm || !data) return data;
    
    return data.filter(item => {
      if (selectedColumn === 'all') {
        return Object.entries(item).some(([key, value]) => {
          if (value === null || value === undefined) return false;
          return String(value).toLowerCase().includes(searchTerm.toLowerCase());
        });
      } else {
        const value = item[selectedColumn];
        if (value === null || value === undefined) return false;
        return String(value).toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }, [searchTerm, selectedColumn]);

  const debouncedSearch = useCallback(
    (value) => {
      debounce((searchValue) => {
        setSearchTerm(searchValue);
      }, 300)(value);
    },
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const handleEdit = (item, customFields) => {
   
    setEditingItem(item);
    setModalType('edit');
    setIsModalOpen(true);
  };

  const handleView = (item) => {
    setEditingItem(item);
    setModalType('view');
    setIsModalOpen(true);
  };

  const handleDelete = (product) => {
    setAlert({
      type: 'warning',
      title: 'Confirm Deletion',
      message: `Are you sure you want to delete this ${selectedMasterType} record?`,
      showConfirmButton: true,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      onConfirm: () => {
        const updatedProducts = products.filter(p => p !== product);
        setProducts(updatedProducts);
        setAlert({
          type: 'success',
          title: 'Deleted Successfully',
          message: `${selectedMasterType} record has been deleted`,
          showConfirmButton: false,
          onClose: () => setAlert(null)
        });
      },
      onClose: () => setAlert(null)
    });
  };

  const shouldShowPlatform = ['PRICE_MASTER', 'SHIPPING_MASTER'].includes(selectedMasterType);

  const formatFieldName = (key) => {
    if (key === 'LinkID') return 'Link ID';
    
    return key
        .split(/(?=[A-Z])|_/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
  };

  const formatFieldValue = (value, fieldName) => {
    if (value === null || value === undefined) return '-';
    
    if (fieldName.toLowerCase().includes('price') || 
        fieldName.toLowerCase().includes('amount')) {
      return `₹ ${parseFloat(value).toFixed(2)}`;
    }
    
    if (fieldName.toLowerCase().includes('date') || 
        fieldName.toLowerCase().includes('timestamp')) {
      return new Date(value).toLocaleString();
    }
    
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    
    return value.toString();
  };

  const getFieldType = (key) => {
    const lowercaseKey = key.toLowerCase();
    if (lowercaseKey.includes('status')) return 'status';
    if (lowercaseKey.includes('price') || lowercaseKey.includes('amount')) return 'price';
    if (lowercaseKey.includes('date')) return 'date';
    return 'text';
  };

  const handleMasterTypeSelect = (typeId) => {
    const masterType = masterTypes.find(type => type.id === typeId);
    
    if (masterType) {
      if (masterType.id === 'OTHER_MASTER') {
        const firstSubType = masterType.subTypes[0];
        navigate(`/po/master/${firstSubType.path}`);
    } else {
        navigate(`/po/master/${masterType.path}`);
      }
    }
  };

  const handleSubTypeSelect = (subTypeId) => {
    const otherMasters = masterTypes.find(type => type.id === 'OTHER_MASTER');
    if (otherMasters) {
      const subType = otherMasters.subTypes.find(subType => subType.id === subTypeId);
      if (subType) {
        navigate(`/po/master/${subType.path}`);
      }
    }
  };

  const handleAddRecord = (initialData = {}) => {
  
    setEditingItem(initialData);
    setModalType('add');
    setIsModalOpen(true);
  };

  const handleClearAll = () => {
    setSearchTerm('');
    setSelectedColumn('all');
    setSelectedItems([]);
    const searchInput = document.querySelector('.search-input');
    if (searchInput) searchInput.value = '';
    
    fetchProductsForPlatform();
    
          setAlert({
      type: 'info',
      title: 'Cleared',
      message: 'All filters and selections have been cleared',
          showConfirmButton: false,
          onClose: () => setAlert(null)
        });
  };
  
  const getEditableFields = (masterType) => {
    switch (masterType) {
      case 'PRICE_MASTER':
        return [
          { key: 'sku_id', label: 'SKU ID', type: 'text' },
          { key: 'sap_code', label: 'SAP Code', type: 'number' },
          { key: 'mrp', label: 'MRP', type: 'number', step: '0.01' },
          { key: 'base_cost', label: 'Base Cost', type: 'number', step: '0.01' },
          { key: 'landing_cost', label: 'Landing Cost', type: 'number', step: '0.01' },
          { key: 'is_active', label: 'Active Status', type: 'select', options: ['1', '0'] },
          { key: 'Link_id', label: 'Link ID', type: 'text' },
          { key: 'status', label: 'Status', type: 'select', options: ['1', '0'] }
        ];

      case 'PRODUCT_MASTER':
        return [
          { key: 'sap_code', label: 'SAP Code', type: 'text' },
          { key: 'product_name', label: 'Product Name', type: 'text' },
          { key: 'product_description', label: 'Description', type: 'textarea' },
          { key: 'segment', label: 'Segment', type: 'text' },
          { key: 'category', label: 'Category', type: 'text' },
          { key: 'tax_percentage', label: 'Tax %', type: 'number', step: '0.01' },
          { key: 'case_pack', label: 'Case Pack', type: 'text' },
          { key: 'variant', label: 'Variant', type: 'text' }
        ];

      case 'SHIPPING_MASTER':
        return [
          { key: 'LinkID', label: 'Link ID', type: 'text' },
          { key: 'site_code', label: 'Site Code', type: 'text' },
          { key: 'warehouse_name', label: 'Warehouse Name', type: 'text' },
          { key: 'warehouse_type', label: 'Warehouse Type', type: 'select', 
            options: ['DC', 'FC', 'Store', 'Warehouse'] },
          { key: 'sold_to_party', label: 'Sold To Party', type: 'number' },
          { key: 'ship_to_party', label: 'Ship To Party', type: 'number' },
          { key: 'bill_to_party', label: 'Bill To Party', type: 'text' },
          { key: 'party_code', label: 'Party Code', type: 'text' },
          { key: 'vendor_code', label: 'Vendor Code', type: 'text' },
          { key: 'gstin', label: 'GSTIN', type: 'text' },
          { key: 'location', label: 'Location', type: 'text' },
          { key: 'address_line1', label: 'Address Line 1', type: 'text' },
          { key: 'address_line2', label: 'Address Line 2', type: 'text' },
          { key: 'city', label: 'City', type: 'text' },
          { key: 'state', label: 'State', type: 'text' },
          { key: 'region', label: 'Region', type: 'text' },
          { key: 'pincode', label: 'Pincode', type: 'text' },
          { key: 'country', label: 'Country', type: 'text', defaultValue: 'India' },
          { key: 'plant_code', label: 'Plant Code', type: 'text' },
          { key: 'mode', label: 'Mode', type: 'text' },
          { key: 'retailer_name', label: 'Retailer Name', type: 'text' },
          { key: 'service_level', label: 'Service Level', type: 'text' },
          { key: 'contact_person', label: 'Contact Person', type: 'text' },
          { key: 'contact_number', label: 'Contact Number', type: 'text' },
          { key: 'email', label: 'Email', type: 'email' },
          { key: 'is_active', label: 'Active Status', type: 'select', options: ['1', '0'] },
          { key: 'status', label: 'Status', type: 'select', 
            options: ['Active', 'Inactive', 'Suspended', 'Pending'] }
        ];

      default:
        return [];
    }
  };
  
  const handleSubmitForm = async (formData) => {
    try {
      // Check if this is a custom type from OtherMasters
      if (formData._customType) {
        if (formData._customType === 'DISABLED_SKUS') {
          if (formData.id) {
            // Update existing disabled SKU
            const response = await updateDisabledSku(formData.id, formData);
            if (response.success) {
              setAlert({
                type: 'success',
                title: 'Success',
                message: 'Disabled SKU updated successfully',
                showConfirmButton: false,
                onClose: () => {
                  setAlert(null);
                  setIsModalOpen(false);
                  // Refresh the data
                  fetchProductsForPlatform();
                }
              });
            } else {
              throw new Error(response.message || 'Failed to update disabled SKU');
            }
          } else {
            // Add new disabled SKU
            const response = await addDisabledSku(formData);
            if (response.success) {
              setAlert({
                type: 'success',
                title: 'Success',
                message: 'Disabled SKU added successfully',
                showConfirmButton: false,
                onClose: () => {
                  setAlert(null);
                  setIsModalOpen(false);
                  // Refresh the data
                  fetchProductsForPlatform();
                }
              });
            } else {
              throw new Error(response.message || 'Failed to add disabled SKU');
            }
          }
        } else if (formData._customType === 'SOLDTO_PRICING') {
          if (formData.id) {
            // Update existing sold-to-party pricing
            const response = await updateSoldToPartyPricing(formData.id, formData);
            if (response.success) {
              setAlert({
                type: 'success',
                title: 'Success',
                message: 'Sold-To-Party pricing updated successfully',
                showConfirmButton: false,
                onClose: () => {
                  setAlert(null);
                  setIsModalOpen(false);
                  // Refresh the data
                  fetchProductsForPlatform();
                }
              });
            } else {
              throw new Error(response.message || 'Failed to update Sold-To-Party pricing');
            }
          } else {
            // Add new sold-to-party pricing
            const response = await addSoldToPartyPricing(formData);
            if (response.success) {
              setAlert({
                type: 'success',
                title: 'Success',
                message: 'Sold-To-Party pricing added successfully',
                showConfirmButton: false,
                onClose: () => {
                  setAlert(null);
                  setIsModalOpen(false);
                  // Refresh the data
                  fetchProductsForPlatform();
                }
              });
            } else {
              throw new Error(response.message || 'Failed to add Sold-To-Party pricing');
            }
          }
        } else {
          // Handle other custom types if needed
          throw new Error('Unknown custom type');
        }
      } else {
        // Handle regular master types
        if (formData.id) {
          // Update existing record
          const response = await updateMasterData(
            selectedMasterType,
            selectedPlatform,
            formData.id,
            formData
          );
          if (response.success) {
            setAlert({
              type: 'success',
              title: 'Success',
              message: 'Record updated successfully',
              showConfirmButton: false,
              onClose: () => {
                setAlert(null);
                setIsModalOpen(false);
                fetchProductsForPlatform();
              }
            });
          } else {
            throw new Error(response.message || 'Failed to update record');
          }
        } else {
          // Add new record
          const response = await updateMasterData(
            selectedMasterType,
            selectedPlatform,
            null,
            formData
          );
          if (response.success) {
            setAlert({
              type: 'success',
              title: 'Success',
              message: 'Record added successfully',
              showConfirmButton: false,
              onClose: () => {
                setAlert(null);
                setIsModalOpen(false);
                fetchProductsForPlatform();
              }
            });
          } else {
            throw new Error(response.message || 'Failed to add record');
          }
        }
      }
    } catch (error) {
      console.error('Error in form submission:', error);
      setAlert({
        type: 'error',
        title: 'Error',
        message: error.message || 'An error occurred',
        showConfirmButton: false,
        onClose: () => setAlert(null)
      });
    }
  };

  const commonProps = {
    products,
    loading,
    error,
    selectedItems,
    setSelectedItems,
    handleSelectItem,
    handleSelectAll,
    handleView,
    handleEdit,
    handleDelete,
    searchTerm,
    selectedColumn,
    setSelectedColumn,
    getColumns,
    formatColumnName,
    handleSearch,
    handleDownload,
    handleRefresh,
    handleAddRecord,
    handleClearAll,
    handleSearchChange
  };

  useEffect(() => {
    // Log platform mapping for debugging
    if (Object.keys(platformMap).length > 0) {
      console.log('Platform mapping initialized with', Object.keys(platformMap).length, 'platforms');
    }
  }, [platformMap]);

  return (
    <div className="masters-container">
      <div className="masters-header">
        <div className="master-type-cards">
          {masterTypes.map((type) => (
            <div
              key={type.id}
              className={`master-type-card ${selectedMasterType === type.id ? 'active' : ''}`}
              onClick={() => handleMasterTypeSelect(type.id)}
            >
              <div className="master-type-icon">
                <type.icon size={20} />
              </div>
              <h3>{type.label}</h3>
            </div>
          ))}
        </div>
        
        {showSubTypes && (
          <div className="sub-types-section">
            <div className="sub-types-grid">
              {masterTypes.find(type => type.id === 'OTHER_MASTER')?.subTypes.map((subType) => (
                <div
                  key={subType.id}
                  className={`sub-type-card ${selectedSubType === subType.id ? 'active' : ''}`}
                  onClick={() => handleSubTypeSelect(subType.id)}
                  data-type={subType.id}
                >
                  <div className="sub-type-content">
                    <div className="sub-type-header">
                      <div className="sub-type-icon">
                        <subType.icon size={16} />
                      </div>
                      <span className="sub-type-label">{subType.label}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {shouldShowPlatform && (
          <div className="platform-cards">
            {platforms.map((platform) => (
              <div
                key={platform.pf_id}
                className={`platform-card ${selectedPlatform === platform.platform_name ? 'active' : ''}`}
                onClick={() => setSelectedPlatform(platform.platform_name)}
              >
                <div className="platform-icon">
                  {selectedMasterType === 'PRICE_MASTER' ? <DollarSign size={20} /> : <Truck size={20} />}
                </div>
                <div className="platform-info">
                  <h3>{platform.platform_name}</h3>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      
      <div className="products-section">
        <Routes>
          <Route path="/" element={<Navigate to="product" replace />} />
          
          <Route path="product" element={
            <ProductMaster 
              {...commonProps}
              fetchMasters={(platform) => fetchMasters('PRODUCT_MASTER', platform)}
            />
          } />
          
          <Route path="price" element={
            <PriceMaster 
              {...commonProps}
              selectedPlatform={selectedPlatform}
              fetchMasters={(platform) => fetchMasters('PRICE_MASTER', platform)}
            />
          } />
          
          <Route path="shipping" element={
            <ShippingMaster 
              {...commonProps}
              selectedPlatform={selectedPlatform}
              fetchMasters={(platform) => fetchMasters('SHIPPING_MASTER', platform)}
            />
          } />
          
          <Route path="upload" element={
            <OtherMasters 
              {...commonProps}
              selectedSubType="UPLOAD_MASTER"
              uploadMasterData={uploadMasterData}
              getUserData={getUserData}
              setAlert={setAlert}
              uploadOptions={uploadOptions}
            />
          } />
          
          <Route path="credentials" element={
            <OtherMasters 
              {...commonProps}
              selectedSubType="CREDENTIALS_MASTER"
              fetchMasters={() => fetchMasters('CREDENTIALS_MASTER')}
              getUserData={getUserData}
              setAlert={setAlert}
            />
          } />
          
          <Route path="soldto" element={
            <OtherMasters 
              {...commonProps}
              selectedSubType="SOLDTO_PRICING"
              soldToPricing={soldToPricing}
              getUserData={getUserData}
              setAlert={setAlert}
              platforms={platforms}
              selectedPlatform={selectedPlatform}
              setSelectedPlatform={(platform) => {
                setSelectedPlatform(platform);
                setLoading(true);
                soldToPricing(platform)
                  .then(response => {
                    if (response.success) {
                      setProducts(response.data);
                      setError(null);
                    }
                  })
                  .catch(error => {
                    console.error("Error fetching data:", error);
                    setError("Failed to fetch Sold-To-Party pricing data");
                    setProducts([]);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            />
          } />
          
          <Route path="disabled-skus" element={
            <OtherMasters 
              {...commonProps}
              selectedSubType="DISABLED_SKUS"
              disableSkus={disableSkus}
              getUserData={getUserData}
              setAlert={setAlert}
            />
          } />
          
          <Route path="*" element={<Navigate to="/po/master/product" replace />} />
        </Routes>
      </div>

      {isModalOpen && (
        <>
          {modalType === 'view' && (
            <ViewModal 
              data={editingItem}
              selectedMasterType={selectedMasterType || editingItem?._customType}
              formatFieldName={formatFieldName}
              formatFieldValue={formatFieldValue}
              getFieldType={getFieldType}
              onClose={() => {
                setIsModalOpen(false);
                setEditingItem(null);
                setModalType(null);
              }} 
            />
          )}
          {(modalType === 'edit' || modalType === 'add') && (
            <EditModal 
              data={editingItem}
              selectedMasterType={selectedMasterType || editingItem?._customType}
              platforms={platforms}
              selectedPlatform={selectedPlatform}
              modalType={modalType}
              getEditableFields={getEditableFields}
              handleSubmitForm={handleSubmitForm}
              setAlert={setAlert}
              onClose={() => {
                setIsModalOpen(false);
                setEditingItem(null);
                setModalType(null);
              }}
            />
          )}
        </>
      )}

      {alert && (
        <AlertCard
          type={alert.type}
          title={alert.title}
          message={alert.message}
          showConfirmButton={alert.showConfirmButton}
          confirmText={alert.confirmText}
          cancelText={alert.cancelText}
          onConfirm={alert.onConfirm}
          onClose={alert.onClose}
        />
      )}
    </div>
  );
};

export default Masters;