import React from 'react';
import {
  Search,
  Eye,
  Edit2,
  Trash2,
  RefreshCw,
  Download,
  Plus,
  X
} from 'lucide-react';
import '../Masters.css';

const ProductMaster = ({
  products,
  loading,
  error,
  selectedItems,
  setSelectedItems,
  handleSelectItem,
  handleSelectAll,
  handleView,
  handleEdit,
  handleDelete,
  searchTerm,
  selectedColumn,
  setSelectedColumn,
  getColumns,
  formatColumnName,
  handleSearch,
  handleDownload,
  handleRefresh,
  handleAddRecord,
  handleClearAll,
  handleSearchChange
}) => {
  // Render the table header with search and action buttons
  const renderTableHeader = () => (
    <div className="table-header">
      <h2 className="section-title">Product Masters</h2>
      <div className="masters-controls">
        <div className="search-container">
          <div className="column-select-container">
            <label className="column-label">Search By:</label>
            <select 
              className="column-select"
              value={selectedColumn}
              onChange={(e) => setSelectedColumn(e.target.value)}
            >
              <option value="all">All Columns</option>
              {getColumns().filter(column => column !== 'all').map(column => (
                <option key={column} value={column}>
                  {formatColumnName(column)}
                </option>
              ))}
            </select>
          </div>
          <div className="search-input-container">
            <Search className="search-icon" size={18} />
            <input
              type="text"
              placeholder={`Search by ${selectedColumn === 'all' ? 'all columns' : formatColumnName(selectedColumn)}...`}
              onChange={handleSearchChange}
              className="search-input"
            />
          </div>
        </div>
        
        <div className="masters-actions">
          <button 
            className="action-btn-header clear-btn" 
            onClick={handleClearAll}
          >
            <X size={16} />
            <span>Clear All</span>
          </button>
          <button 
            className="action-btn-header download-btn"
            onClick={handleDownload}
          >
            <Download size={16} />
            Download
          </button>
          <button 
            className="action-btn-header refresh-btn"
            onClick={handleRefresh}
          >
            <RefreshCw size={16} />
            Refresh
          </button>
          <button 
            className="action-btn-header add-btn"
            onClick={handleAddRecord}
          >
            <Plus size={16} />
            Add Record
          </button>
        </div>
      </div>
    </div>
  );

  // Render cell content safely
  const renderCellContent = (value) => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'object') return JSON.stringify(value);
    return value.toString();
  };

  // Render the dynamic table
  const renderDynamicTable = () => {
    const filteredData = handleSearch(products);
    
    if (!filteredData || filteredData.length === 0) {
      return <div className="no-data">No data found</div>;
    }

    // Get all unique columns from all products
    const columns = [...new Set(filteredData.flatMap(product => Object.keys(product)))];

    return (
      <div className="table-container">
        <table className="products-table">
          <thead>
            <tr>
              <th className="checkbox-cell">
                <input
                  type="checkbox"
                  checked={selectedItems.length === filteredData.length}
                  onChange={handleSelectAll}
                />
              </th>
              <th className="serial-number">S.No</th>
              {columns.map((column) => (
                <th key={column}>
                  {column
                    .split(/(?=[A-Z])|_/)
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ')}
                </th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((product, index) => (
              <tr key={index}>
                <td className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(index)}
                    onChange={() => handleSelectItem(index)}
                  />
                </td>
                <td className="serial-number">{index + 1}</td>
                {columns.map((column) => (
                  <td key={column}>{renderCellContent(product[column])}</td>
                ))}
                <td className="masters-action-buttons">
                  <button
                    className="masters-btn masters-btn-view masters-tooltip"
                    onClick={() => handleView(product)}
                    data-tooltip="View Details"
                  >
                    <Eye size={16} />
                  </button>
                  <button
                    className="masters-btn masters-btn-edit masters-tooltip"
                    onClick={() => handleEdit(product)}
                    data-tooltip="Edit Record"
                  >
                    <Edit2 size={16} />
                  </button>
                  <button
                    className="masters-btn masters-btn-delete masters-tooltip"
                    onClick={() => handleDelete(product)}
                    data-tooltip="Delete Record"
                  >
                    <Trash2 size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      {renderTableHeader()}
      
      {loading ? (
        <div className="loading-state">
          <div className="loading-spinner"></div>
          <p>Loading products...</p>
        </div>
      ) : error ? (
        <div className="error-state">
          <p>{error}</p>
        </div>
      ) : (
        renderDynamicTable()
      )}
    </>
  );
};

export default ProductMaster; 