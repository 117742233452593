import React, { useEffect, useState, useCallback } from 'react';
import { 
  Layout, 
  Table, 
  Switch, 
  Button, 
  Input, 
  Badge, 
  Tabs, 
  Drawer, 
  Form, 
  Select, 
  message, 
  Tooltip, 
  Divider, 
  Empty, 
  Row, 
  Col, 
  Space, 
  Tag,
  Avatar,
  Modal,
  Typography
} from 'antd';
import { 
  TeamOutlined, 
  LockOutlined, 
  EyeOutlined, 
  EditOutlined, 
  DeleteOutlined, 
  PlusOutlined, 
  AppstoreOutlined, 
  BankOutlined, 
  CheckCircleOutlined, 
  SyncOutlined, 
  UserOutlined,
  SearchOutlined,
  SaveOutlined 
} from '@ant-design/icons';

import { getUserData, getCookie } from '../../utils/cookieUtils';
import UserPermissionsExpanded from './UserPermissionsExpanded';
import { 
  fetchUsersByClientId, 
  fetchUsers as apiFetchUsers, 
  fetchAdminModules, 
  fetchAdminPlatforms, 
  fetchAdminProjects,
  fetchUserPermissions,
  deleteUser,
  createOrUpdateUser,
  updateUserPermissions
} from '../../routes/AppRoutes';
import './AdminDashboard.css';

const { Content } = Layout;
const { Option } = Select;
const { Text, Title } = Typography;

const AdminDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [modules, setModules] = useState([]);
  const [platforms, setPlatforms] = useState([]); // Used for platform data handling
  const [filteredPlatforms, setFilteredPlatforms] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [availableProjects, setAvailableProjects] = useState([]); // Used for project selection
  const [clientId, setClientId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [activeTab, setActiveTab] = useState('admin');
  const [refreshKey, setRefreshKey] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [form] = Form.useForm();

  // Add this constant for fallback data
  const FALLBACK_DATA = {
    projects: [
      { id: 'default', name: 'Default Project' }
    ],
    modules: [
      { module_id: 'dsa', module_name: 'dsa', display_name: 'DSA' },
      { module_id: 'admin', module_name: 'admin', display_name: 'Admin' },
      { module_id: 'ecom', module_name: 'ecom_dashboard', display_name: 'Ecom Dashboard' }
    ]
  };

  // Define these functions with useCallback before using them in useEffect
  const loadUsers = useCallback(async () => {
    try {
      setLoading(true);
      let data;
      
      // Use client ID-specific endpoint if available
      if (clientId && clientId.trim() !== '') {
        
        const response = await fetchUsersByClientId(clientId);
        data = response;
      } else {
        
        const response = await apiFetchUsers();
        data = response;
      }
      
      if (data && data.success && Array.isArray(data.data)) {
       
        setUsers(data.data.map(user => ({
          ...user,
          key: user.user_id
        })));
        return true;
      } else {
        console.warn('Received invalid user data format:', data);
        // Don't clear existing users if we already have some
        if (users.length === 0) {
          setUsers([]);
        }
        return false;
      }
    } catch (error) {
      console.error('Error in fetchUsers function:', error);
      // Don't clear existing users on error
      return false;
    } finally {
      setLoading(false);
    }
  }, [clientId, users.length]);

  const fetchProjects = useCallback(async () => {
    try {
      setLoading(true);
      const data = await fetchAdminProjects();
      
      if (data && data.success && Array.isArray(data.data) && data.data.length > 0) {
        setAvailableProjects(data.data);
        
        // Check for current project in cookies and set it
        const userData = getUserData();
        if (userData) {
          // If project is stored in userData, use it
          if (userData.currentProject) {
            const savedProject = data.data.find(p => p.id === userData.currentProject.id);
            if (savedProject) {
              setCurrentProject(savedProject);
            } else if (data.data.length > 0) {
              // If stored project not found, use first available
              setCurrentProject(data.data[0]);
            }
          } 
          // If no project but client_id exists, use that to find a related project
          else if (userData.client_id) {
            // Find project related to client_id, or use first available
            const clientProject = data.data.find(p => 
              p.name.toLowerCase().includes(userData.client_id.toLowerCase()) ||
              p.description?.toLowerCase().includes(userData.client_id.toLowerCase())
            );
            
            if (clientProject) {
              setCurrentProject(clientProject);
              // Save to userData
              userData.currentProject = clientProject;
              document.cookie = `userData=${JSON.stringify(userData)}; path=/; max-age=86400`;
            } else if (data.data.length > 0) {
              setCurrentProject(data.data[0]);
            }
          } 
          // If no project and no client_id, use first available
          else if (data.data.length > 0) {
            setCurrentProject(data.data[0]);
          }
        }
      } else {
        console.warn('Using fallback project data due to API issue:', data);
        setAvailableProjects(FALLBACK_DATA.projects);
        setCurrentProject(FALLBACK_DATA.projects[0]);
      }
    } catch (error) {
      console.error('Error in fetchProjects function, using fallback data:', error);
      setAvailableProjects(FALLBACK_DATA.projects);
      setCurrentProject(FALLBACK_DATA.projects[0]);
    } finally {
      setLoading(false);
    }
  }, [FALLBACK_DATA.projects]);

  const fetchModules = useCallback(async () => {
    try {
      setLoading(true);
      const data = await fetchAdminModules();
      
      if (data && data.success && Array.isArray(data.data) && data.data.length > 0) {
        setModules(data.data);
      } else {
        console.warn('Using fallback module data due to API issue');
        setModules(FALLBACK_DATA.modules);
      }
    } catch (error) {
      console.error('Error in fetchModules function, using fallback data:', error);
      setModules(FALLBACK_DATA.modules);
    } finally {
      setLoading(false);
    }
  }, [FALLBACK_DATA.modules]);

  const fetchPlatforms = useCallback(async () => {
    try {
      setLoading(true);
      
      // Use the same API endpoint that works in the Masters component
      const data = await fetchAdminPlatforms();
      
      // If that fails, try the app platforms endpoint as fallback
      if (!data.success || !data.data || data.data.length === 0) {
       
        const appPlatformsResponse = await fetch(`${process.env.REACT_APP_API_URL || ''}/app/platforms`, {
          headers: {
            'Authorization': `Bearer ${getCookie('token')}`
          }
        });
        
        const appPlatformsData = await appPlatformsResponse.json();
        
        if (appPlatformsData.success && appPlatformsData.data && appPlatformsData.data.length > 0) {
          console.log('Successfully fetched platforms from app API:', appPlatformsData.data.length);
          setPlatforms(appPlatformsData.data);
          setFilteredPlatforms(appPlatformsData.data);
          return;
        }
      }
      
      if (data.success) {
        // If using project-specific endpoint, these are already filtered
        if (currentProject?.id) {
          setFilteredPlatforms(data.data);
        } else {
          setPlatforms(data.data);
          
          // Filter platforms by current project if available
          if (currentProject) {
            const projectPlatforms = data.data.filter(platform => 
              platform.platform_name.toLowerCase().includes(currentProject.name.toLowerCase())
            );
            setFilteredPlatforms(projectPlatforms);
          } else {
            setFilteredPlatforms(data.data);
          }
        }
      } else {
        setPlatforms([]);
        setFilteredPlatforms([]);
      }
    } catch (error) {
      console.error('Error in fetchPlatforms function:', error);
      
      // Try app platforms as a fallback if admin platforms fail
      try {
        console.log('Trying app platforms API as fallback after error');
        const response = await fetch(`${process.env.REACT_APP_API_URL || ''}/app/platforms`, {
          headers: {
            'Authorization': `Bearer ${getCookie('token')}`
          }
        });
        
        const appData = await response.json();
        
        if (appData.success && appData.data && appData.data.length > 0) {
          console.log('Successfully fetched platforms from app API:', appData.data.length);
          setPlatforms(appData.data);
          setFilteredPlatforms(appData.data);
        } else {
          setPlatforms([]);
          setFilteredPlatforms([]);
        }
      } catch (fallbackError) {
        console.error('Fallback platform fetch also failed:', fallbackError);
        setPlatforms([]);
        setFilteredPlatforms([]);
      }
    } finally {
      setLoading(false);
    }
  }, [currentProject]);

  const handleRefresh = useCallback(() => {
    setRefreshKey(prevKey => prevKey + 1);
  }, []);

  // Now use the callbacks in the useEffect
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        // Get user data first
        const userData = getUserData();
        if (userData) {
          if (userData.currentProject) {
            setCurrentProject(userData.currentProject);
          }
          if (userData.client_id && !clientId) {
            setClientId(userData.client_id);
            console.log('Setting client ID from user data:', userData.client_id);
          }
        }
        
        // Load data sequentially to avoid race conditions
        // First projects (needed for context)
        await fetchProjects();
        
        // Then modules and users in parallel
        await Promise.all([
          fetchModules(),
          loadUsers()
        ]);
        
        // Finally platforms (which may depend on current project)
        await fetchPlatforms();
        
      } catch (error) {
        console.error('Error loading admin data:', error);
        // Even if we encounter errors, try to load users at minimum
        if (users.length === 0) {
          try {
            await loadUsers();
          } catch (innerError) {
            console.error('Backup user loading also failed:', innerError);
          }
        }
      } finally {
        setLoading(false);
      }
    };

    // Execute fetch on first load and on refreshKey changes
    fetchAllData();
  }, [refreshKey, clientId, fetchModules, fetchPlatforms, fetchProjects, loadUsers, users.length]);

  useEffect(() => {
    // If no users have been loaded yet, try loading them
    if (users.length === 0 && !loading) {
      console.log('No users found, attempting to load data...');
      handleRefresh();
    }
  }, [users.length, loading, handleRefresh]);

  // Add a useEffect hook to ensure the page is scrollable on mount
  useEffect(() => {
    // Reset scroll position when component mounts
    window.scrollTo(0, 0);
    
    // Ensure the body and html elements are scrollable
    document.body.style.overflow = 'auto';
    document.documentElement.style.overflow = 'auto';
    
    return () => {
      // Clean up when component unmounts
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    // Document their purpose in the codebase and use them
    if (platforms.length > 0 || availableProjects.length > 0) {
      console.log(`Admin panel loaded with ${platforms.length} platforms and ${availableProjects.length} projects`);
    }
  }, [platforms, availableProjects]);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const filteredUsers = users.filter(user => {
    // Basic search filtering
    const matchesSearch = user.username?.toLowerCase().includes(searchText.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      (user.first_name && user.first_name.toLowerCase().includes(searchText.toLowerCase())) ||
      (user.last_name && user.last_name.toLowerCase().includes(searchText.toLowerCase()));
    
    // Return false immediately if doesn't match search
    if (!matchesSearch) return false;
    
    // Role-based tab filtering - only admin and user
    if (activeTab === 'admin') return user.role === 'admin';
    if (activeTab === 'user') return user.role === 'user';
    
    // If we reach here, user passed all filters (should not happen with only two tabs)
    return false;
  });

  const showDrawer = (user = null) => {
    setEditingUser(user);
    form.resetFields();
    
    if (user) {
      // Set form values if editing existing user
      form.setFieldsValue({
        username: user.username,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        role: user.role,
        is_active: user.is_active
      });
      
      // Fetch user's specific permissions using the new function
      handleFetchUserPermissions(user.user_id);
    } else {
      // If creating a new user, ensure we show all available platforms for the project
      fetchPlatforms();
    }
    
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setEditingUser(null);
    
    // Reset to global platforms (not user-specific) when drawer is closed
    if (currentProject) {
      // Refetch or refilter platforms based on current project
      fetchPlatforms();
    }
  };

  const handleFetchUserPermissions = async (userId) => {
    try {
      setLoading(true);
      const data = await fetchUserPermissions(userId);
      
      if (data && data.success) {
        console.log('User permissions loaded:', data.data);
        
        // Check if data.data.modules is an object (hash) instead of an array
        if (data.data.modules && typeof data.data.modules === 'object' && !Array.isArray(data.data.modules)) {
          // Convert object format to array format
          const moduleValues = {};
          Object.entries(data.data.modules).forEach(([moduleName, moduleInfo]) => {
            if (moduleInfo.module_id) {
              moduleValues[`module_${moduleInfo.module_id}`] = !!moduleInfo.can_access;
            }
          });

          // Similar handling for platforms if they're in object format
          const platformValues = {};
          if (data.data.platforms && typeof data.data.platforms === 'object' && !Array.isArray(data.data.platforms)) {
            Object.entries(data.data.platforms).forEach(([platformName, platformInfo]) => {
              if (platformInfo.pf_id) {
                platformValues[`platform_${platformInfo.pf_id}`] = !!platformInfo.can_access;
              }
            });
          }
          
          // Set form values
          form.setFieldsValue({
            ...moduleValues,
            ...platformValues
          });
          
          // Convert to arrays for component state if needed
          if (Object.keys(data.data.platforms).length > 0) {
            const platformsArray = Object.entries(data.data.platforms).map(([name, info]) => ({
              pf_id: info.pf_id,
              platform_name: name,
              can_access: info.can_access ? 1 : 0
            }));
            setFilteredPlatforms(platformsArray);
          }
        } else {
          // Handle array format (original code)
          const moduleValues = {};
          if (Array.isArray(data.data.modules)) {
            data.data.modules.forEach(module => {
              moduleValues[`module_${module.module_id}`] = !!module.can_access;
            });
          }
          
          const platformValues = {};
          if (Array.isArray(data.data.platforms)) {
            data.data.platforms.forEach(platform => {
              platformValues[`platform_${platform.pf_id}`] = !!platform.can_access;
            });
          }
          
          // Set all values at once by combining user data and permissions
          form.setFieldsValue({
            ...moduleValues,
            ...platformValues
          });
          
          if (data.data.platforms && data.data.platforms.length > 0) {
            // Update filtered platforms to show user-specific platform list
            setFilteredPlatforms(data.data.platforms);
          }
        }
      } else {
        console.error('Failed to fetch user permissions:', data?.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Error processing user permissions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      
      // Get user data and client_id from cookies
      const currentUserData = getUserData();
      const clientId = currentUserData?.client_id || '';
      
      // Extract base user data - renamed from userData to userFormData
      const userFormData = {
        username: values.username,
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        role: values.role,
        is_active: values.is_active,
        client_id: clientId // Include client_id from current user
      };
      
      // Extract module permissions - use different variable name to avoid conflict with state variable
      const modulePermissionsList = modules.map(module => ({
        module_id: module.module_id,
        can_access: values[`module_${module.module_id}`] ? 1 : 0
      }));
      
      // Extract platform permissions - with proper reference to filtered platforms
      const platformPermissionsList = filteredPlatforms.map(platform => ({
        pf_id: platform.pf_id,
        can_access: values[`platform_${platform.pf_id}`] ? 1 : 0
      }));
      
      console.log('Submitting permissions:', {
        modules: modulePermissionsList,
        platforms: platformPermissionsList
      });
      
      let response;
      
      if (editingUser) {
        // Update existing user
        response = await createOrUpdateUser(userFormData, editingUser.user_id);
        
        // Also update permissions 
        const permissionResponse = await updateUserPermissions(
          editingUser.user_id, 
          {
            modules: modulePermissionsList,
            platforms: platformPermissionsList
          }
        );
        
        if (!permissionResponse.success) {
          console.error('Failed to update permissions:', permissionResponse.message);
        }
      } else {
        // Create new user with permissions
        response = await createOrUpdateUser({
          ...userFormData,
          modules: modulePermissionsList,
          platforms: platformPermissionsList
        });
        
        console.log('User creation response:', response);
      }

      if (response.success) {
        // Check if we received a temporary password (for new users)
        if (!editingUser && response.data && response.data.temp_password) {
          // Show credential info in a nicely formatted modal
          Modal.success({
            title: 'User Created Successfully',
            content: (
              <div>
                <p>Please save these credentials for the new user:</p>
                <div style={{ 
                  background: '#f5f5f5', 
                  padding: '15px', 
                  borderRadius: '5px', 
                  marginTop: '15px',
                  border: '1px solid #d9d9d9',
                  fontFamily: 'monospace',
                  fontSize: '14px'
                }}>
                  <p style={{ margin: '5px 0' }}><strong>Username:</strong> {response.data.username}</p>
                  <p style={{ margin: '5px 0' }}><strong>Password:</strong> {response.data.temp_password}</p>
                  <p style={{ margin: '5px 0' }}><strong>Email:</strong> {response.data.email}</p>
                </div>
                <p style={{ marginTop: '15px', color: '#ff4d4f' }}>
                  <strong>Important:</strong> Please provide these credentials to the user. 
                  This temporary password will not be shown again.
                </p>
              </div>
            ),
            width: 500,
            onOk: () => {
              closeDrawer();
              handleRefresh();
            },
            okText: 'I have saved these credentials'
          });
        } else {
          message.success(editingUser ? 'User updated successfully' : 'User created successfully');
          closeDrawer();
          handleRefresh();
        }
      } else {
        message.error(response.message || 'Failed to save user');
      }
    } catch (error) {
      console.error('Error saving user:', error);
      message.error('Failed to save user data');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      setLoading(true);
      try {
        const data = await deleteUser(userId);
        
        if (data.success) {
          handleRefresh();
        } else {
          message.error(data.message || 'Failed to delete user');
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        message.error('Failed to delete user');
      } finally {
        setLoading(false);
      }
    }
  };

  const getColorForLastActive = (date) => {
    if (!date) return '';
    
    const lastActive = new Date(date);
    const now = new Date();
    const diffDays = Math.floor((now - lastActive) / (1000 * 60 * 60 * 24));
    
    if (diffDays < 7) return 'green';
    if (diffDays < 30) return 'orange';
    return 'red';
  };

  const columns = [
    {
      title: 'User',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => {
        // Generate consistent colors based on username
        const stringToColor = (str) => {
          let hash = 0;
          for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
          }
          let color = '#';
          for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).substr(-2);
          }
          return color;
        };
        
        const color = record.profile_picture ? undefined : stringToColor(text);
        const nameInitials = record.first_name && record.last_name ? 
          `${record.first_name[0]}${record.last_name[0]}`.toUpperCase() : 
          text.substring(0, 2).toUpperCase();
        
        return (
          <div className="user-cell">
            <Avatar 
              src={record.profile_picture} 
              style={{ 
                backgroundColor: color,
                color: '#fff',
                fontWeight: 'bold'
              }}
              className="user-avatar"
            >
              {!record.profile_picture && nameInitials}
            </Avatar>
            <div className="user-info">
              <div className="username">{text}</div>
              <div className="user-email">{record.email}</div>
            </div>
            {record.is_active ? (
              <Badge status="success" className="status-badge" />
            ) : (
              <Badge status="error" className="status-badge" />
            )}
          </div>
        );
      },
    },
    {
      title: 'Name',
      key: 'name',
      render: (_, record) => {
        const name = `${record.first_name || ''} ${record.last_name || ''}`.trim();
        return name ? <span>{name}</span> : <Text type="secondary">Not set</Text>;
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: role => {
        const color = 
          role === 'admin' ? 'volcano' : 
          role === 'user' ? 'geekblue' : 
          'purple';
        
        const icon = 
          role === 'admin' ? <LockOutlined /> : 
          role === 'user' ? <TeamOutlined /> : 
          <EyeOutlined />;
        
        return (
          <Tag color={color} icon={icon}>
            {role.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: active => (
        <Badge 
          status={active ? 'success' : 'error'} 
          text={active ? 'Active' : 'Inactive'}
        />
      ),
    },
    {
      title: 'Last Login',
      dataIndex: 'last_login',
      key: 'last_login',
      render: date => {
        if (!date) return <Text type="secondary">Never</Text>;
        
        const color = getColorForLastActive(date);
        const formattedDate = new Date(date).toLocaleString();
        
        return <Text style={{ color }}>{formattedDate}</Text>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div style={{whiteSpace: 'nowrap'}}>
          <Button 
            type="link"
            style={{marginRight: '10px', color: '#1890ff'}} 
            onClick={(e) => {
              e.stopPropagation();
              showDrawer(record);
            }}
          >
            <EditOutlined /> Edit
          </Button>
          <Button 
            type="link"
            style={{color: '#ff4d4f'}} 
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteUser(record.user_id);
            }}
          >
            <DeleteOutlined /> Delete
          </Button>
        </div>
      ),
    },
  ];

  const userTabStats = {
    admin: users.filter(u => u.role === 'admin').length,
    user: users.filter(u => u.role === 'user').length,
  };

  // Create items for Tabs with improved styling
  const tabItems = [
    { 
      key: 'admin', 
      label: (
        <div className="tab-label">
          <LockOutlined className="tab-icon" />
          <span>Admins</span>
          <Badge 
            count={userTabStats?.admin || 0} 
            showZero 
            className="tab-badge"
            style={{ 
              backgroundColor: activeTab === 'admin' ? '#1e40af' : '#64748b',
              marginLeft: '8px'
            }}
          />
        </div>
      )
    },
    { 
      key: 'user', 
      label: (
        <div className="tab-label">
          <TeamOutlined className="tab-icon" />
          <span>Regular Users</span>
          <Badge 
            count={userTabStats?.user || 0} 
            showZero 
            className="tab-badge"
            style={{ 
              backgroundColor: activeTab === 'user' ? '#1e40af' : '#64748b',
              marginLeft: '8px'
            }}
          />
        </div>
      )
    }
  ];

  return (
    <Layout className="admin-dashboard-layout">
      <Content className="admin-dashboard-content">
        <div className="admin-header">
          <div className="admin-title-section">
            <Title level={3} className="admin-title">
              <TeamOutlined className="header-icon" /> User Management & Permissions
            </Title>
            <Text type="secondary" className="admin-subtitle">
              {currentProject ? 
                `Manage user access for ${currentProject.name} project` : 
                'Manage access rights and user accounts in one place'}
            </Text>
          </div>
          
          <Space className="admin-controls" wrap>
            
            <Input
              placeholder="Search users..."
              prefix={<SearchOutlined className="search-icon" />}
              onChange={e => handleSearch(e.target.value)}
              className="search-input"
              allowClear
            />
        
            <Tooltip title="Refresh Data">
              <Button 
                icon={<SyncOutlined spin={loading} />} 
                onClick={handleRefresh}
                className="refresh-button"
                loading={loading}
              />
            </Tooltip>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => showDrawer()}
              className="add-user-button"
            >
              Add User
            </Button>
          </Space>
        </div>
        
        <div className="user-tabs-container">
          <Tabs 
            activeKey={activeTab} 
            onChange={setActiveTab}
            className="user-tabs"
            type="card"
            items={tabItems}
            size="large"
            centered
            tabBarGutter={24}
          />
        </div>
        
        
        
        <div className="user-table-container scrollable-container">
          {filteredUsers.length > 0 ? (
            <Table
              dataSource={filteredUsers}
              columns={columns}
              rowKey="user_id"
              loading={loading}
              pagination={{ 
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50'],
              }}
              className="users-table"
              rowClassName={(record) => `table-row ${record.role === 'admin' ? 'admin-row' : ''} ${!record.is_active ? 'inactive-row' : ''}`}
              expandable={{
                expandedRowRender: record => <UserPermissionsExpanded userId={record.user_id} />,
                expandRowByClick: false,
              }}
              scroll={{ x: true }}
              style={{ overflow: 'visible' }}
            />
          ) : (
            <Empty 
              image={Empty.PRESENTED_IMAGE_SIMPLE} 
              description={
                <span>
                  No users found. {searchText ? 'Try a different search.' : 'Add your first user!'}
                </span>
              }
            >
              <Button type="primary" onClick={() => showDrawer()}>Add First User</Button>
            </Empty>
          )}
        </div>
        
        <Drawer
          title={
            <div className="drawer-title">
              {editingUser ? (
                <>
                  <EditOutlined className="drawer-icon" /> 
                  <span>Edit User & Permissions</span>
                </>
              ) : (
                <>
                  <PlusOutlined className="drawer-icon" /> 
                  <span>Add New User</span>
                </>
              )}
            </div>
          }
          width={720}
          onClose={closeDrawer}
          open={drawerVisible}
          styles={{ 
            body: { 
              paddingBottom: 120,
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 180px)'
            },
            mask: { backgroundColor: 'rgba(0, 0, 0, 0.45)' },
            wrapper: { zIndex: 1001 }
          }}
          className="user-drawer"
          extra={
            <Space>
              <Button onClick={closeDrawer} className="cancel-button">Cancel</Button>
              <Button 
                type="primary" 
                onClick={handleFormSubmit}
                loading={loading}
                icon={<SaveOutlined />}
                className="save-button"
              >
                Save
              </Button>
            </Space>
          }
        >
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              is_active: true,
              role: 'user'
            }}
            className="user-form"
          >
            <div className="form-section user-info-section">
              <div className="section-header">
                <TeamOutlined className="section-icon" />
                <h3 className="section-title">User Information</h3>
              </div>
              
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="username"
                    label="Username"
                    rules={[{ required: true, message: 'Please enter username' }]}
                  >
                    <Input placeholder="Username" prefix={<UserOutlined style={{ color: '#bfbfbf' }} />} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      { required: true, message: 'Please enter email' },
                      { type: 'email', message: 'Please enter a valid email' }
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="first_name"
                    label="First Name"
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="last_name"
                    label="Last Name"
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="role"
                    label="Role"
                    rules={[{ required: true, message: 'Please select a role' }]}
                  >
                    <Select placeholder="Select a role">
                      <Option value="admin">
                        <LockOutlined /> Admin (Full Access)
                      </Option>
                      <Option value="user">
                        <TeamOutlined /> User (Standard Access)
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="is_active"
                    label="Status"
                    valuePropName="checked"
                  >
                    <Switch 
                      checkedChildren="Active" 
                      unCheckedChildren="Inactive" 
                      className="status-switch"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            
            <Divider className="section-divider" />
            
            <div className="form-section permissions-section">
              <div className="section-header">
                <AppstoreOutlined className="section-icon" />
                <h3 className="section-title">Module Permissions</h3>
                <Text type="secondary" className="section-subtitle">
                  Control access to specific modules
                  {modules.length === 0 && <span> (Using default modules)</span>}
                </Text>
              </div>
              
              {modules.length === 0 ? (
                <div className="empty-permissions">
                  No modules available. Please refresh or try again later.
                </div>
              ) : (
                <Row gutter={[16, 16]} className="permission-switches">
                  {modules.map(module => (
                    <Col span={8} key={module.module_id}>
                      <Form.Item
                        name={`module_${module.module_id}`}
                        valuePropName="checked"
                        className="switch-item"
                      >
                        <Switch 
                          checkedChildren={<><CheckCircleOutlined /> {module.display_name}</>} 
                          unCheckedChildren={<>{module.display_name}</>}
                          className="module-switch" 
                        />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              )}
            </div> 
            
            <Divider className="section-divider" />
            
            <div className="form-section permissions-section">
              <div className="section-header">
                <BankOutlined className="section-icon" />
                <h3 className="section-title">Platform Permissions</h3>
                <Text type="secondary" className="section-subtitle">
                  {currentProject ? `Platforms for ${currentProject.name}` : 'Control access to specific platforms'}
                  {editingUser && <span> - User ID: {editingUser.user_id}</span>}
                  {filteredPlatforms.length === 0 && <span> (No platforms available)</span>}
                </Text>
              </div>
              
              {filteredPlatforms.length === 0 ? (
                <div className="empty-permissions">
                  No platforms available for this user or project.
                  {currentProject ? ` Try selecting a different project.` : ` Please select a project first.`}
                </div>
              ) : (
                <Row gutter={[16, 16]} className="permission-switches">
                  {filteredPlatforms.map(platform => (
                    <Col span={8} key={platform.pf_id}>
                      <Form.Item
                        name={`platform_${platform.pf_id}`}
                        valuePropName="checked"
                      >
                        <Switch 
                          checkedChildren={platform.platform_name} 
                          unCheckedChildren={platform.platform_name} 
                          className="platform-switch"
                        />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </Form>
        </Drawer>
      </Content>
    </Layout>
  );
};

export default AdminDashboard;
