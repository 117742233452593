import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import '../Masters.css';

export const ViewModal = ({ data, selectedMasterType, formatFieldName, formatFieldValue, getFieldType, onClose }) => {
  if (!data) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content view-modal">
        <div className="modal-header">
          <h2>View {selectedMasterType.replace(/_/g, ' ')} Details</h2>
          <button className="close-btn" onClick={onClose}>
            <X size={18} />
          </button>
        </div>
        <div className="modal-body">
          <div className="view-data">
            {Object.entries(data)
              .filter(([key]) => !['id', '_id', '__v', 'selected', 'type', 'all'].includes(key.toLowerCase()))
              .map(([key, value]) => (
                <div key={key} className="data-item">
                  <div className="label">{formatFieldName(key)}</div>
                  <div className="value">{formatFieldValue(value, key)}</div>
                </div>
              ))}
          </div>
        </div>
        <div className="modal-footer">
          <button className="modal-btn modal-btn-primary" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export const EditModal = ({ 
  data, 
  selectedMasterType, 
  platforms,
  selectedPlatform, 
  modalType, 
  getEditableFields, 
  handleSubmitForm, 
  setAlert, 
  onClose 
}) => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [customFieldsFromStorage, setCustomFieldsFromStorage] = useState(null);

  // Check for custom fields in session storage
  useEffect(() => {
    try {
      const storedFields = sessionStorage.getItem('customFields');
      if (storedFields) {
        setCustomFieldsFromStorage(JSON.parse(storedFields));
        // Clear after reading
        sessionStorage.removeItem('customFields');
      }
    } catch (error) {
      console.error('Error reading custom fields from storage:', error);
    }
  }, []);

  // Debug logging
  useEffect(() => {
    
  }, [data, selectedMasterType, customFieldsFromStorage]);

  // Initialize form data when component mounts or data changes
  useEffect(() => {
    if (data) {
     
      // Make sure we're creating a new object and not modifying the original
      setFormData({ ...data });
    } else {
      // For new records, set platform if applicable
      if (['PRICE_MASTER', 'SHIPPING_MASTER', 'SOLDTO_PRICING'].includes(selectedMasterType) && selectedPlatform) {
        setFormData({ platform: selectedPlatform, platform_name: selectedPlatform });
      } else {
        setFormData({});
      }
    }
  }, [data, selectedMasterType, selectedPlatform]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const processedValue = type === 'number' ? (value === '' ? '' : Number(value)) : value;
    
    setFormData(prev => ({ ...prev, [name]: processedValue }));
    
    // Clear error for this field when user makes a change
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const validateForm = () => {
    const fields = getEditableFields(selectedMasterType);
    const newErrors = {};
    
    fields.forEach(field => {
      // Check required fields
      if (field.required && 
         (formData[field.key] === undefined || 
          formData[field.key] === null || 
          formData[field.key] === '')) {
        newErrors[field.key] = `${field.label} is required`;
      }
      
      // Validate number fields
      if (field.type === 'number' && formData[field.key] !== undefined && formData[field.key] !== '') {
        const numValue = Number(formData[field.key]);
        if (isNaN(numValue)) {
          newErrors[field.key] = `${field.label} must be a valid number`;
        } else if (field.min !== undefined && numValue < field.min) {
          newErrors[field.key] = `${field.label} must be at least ${field.min}`;
        } else if (field.max !== undefined && numValue > field.max) {
          newErrors[field.key] = `${field.label} must not exceed ${field.max}`;
        }
      }
      
      // Validate pattern if specified
      if (field.pattern && formData[field.key] && !new RegExp(field.pattern).test(formData[field.key])) {
        newErrors[field.key] = `${field.label} format is invalid`;
      }
    });
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const prepareFormDataForSubmission = () => {
    // Preserve the _customType for proper routing in the parent component
    const preparedData = { ...formData };
    
    // If we're dealing with Sold-To-Party Pricing
    if (formData._customType === 'SOLDTO_PRICING') {
      // Make sure we have the platform ID
      if (!preparedData.pf_id && preparedData.platform_name) {
        // Try to find the platform in the platforms array
        const platformObj = platforms?.find(p => p.platform_name === preparedData.platform_name);
        if (platformObj && platformObj.pf_id) {
          preparedData.pf_id = platformObj.pf_id;
        }
      }
    }
    
    // If we're dealing with Disabled SKUs
    if (formData._customType === 'DISABLED_SKUS') {
      // Ensure pfid is set
      if (!preparedData.pfid && !preparedData.pf_id) {
        preparedData.pfid = 0;
      }
    }
    
    return preparedData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    if (!validateForm()) {
      setAlert({
        type: 'warning',
        title: 'Validation Error',
        message: 'Please correct the errors in the form',
        showConfirmButton: false,
        onClose: () => setAlert(null)
      });
      return;
    }
    
    setLoading(true);
    try {
      // Use the prepared data with all fields
      const preparedData = prepareFormDataForSubmission();

      await handleSubmitForm(preparedData);
      // Success is handled by parent component
    } catch (error) {
      console.error('Form submission error:', error);
      setAlert({
        type: 'error',
        title: 'Submission Error',
        message: error.message || 'Failed to save changes',
        showConfirmButton: false,
        onClose: () => setAlert(null)
      });
    } finally {
      setLoading(false);
    }
  };

  const renderField = (field) => {
    switch (field.type) {
      case 'select':
        return (
          <select
            id={field.key}
            name={field.key}
            value={formData[field.key] || ''}
            onChange={handleChange}
            className={`form-control ${errors[field.key] ? 'is-invalid' : ''}`}
            disabled={field.disabled}
          >
            <option value="">Select {field.label}</option>
            {field.options && field.options.map(option => {
              const optionValue = typeof option === 'object' ? option.value || option.id : option;
              const optionLabel = typeof option === 'object' ? option.label || option.name : option;
              
              return (
                <option key={optionValue} value={optionValue}>
                  {optionLabel}
                </option>
              );
            })}
          </select>
        );
        
      case 'textarea':
        return (
          <textarea
            id={field.key}
            name={field.key}
            value={formData[field.key] || ''}
            onChange={handleChange}
            className={`form-control ${errors[field.key] ? 'is-invalid' : ''}`}
            placeholder={field.placeholder || `Enter ${field.label}`}
            rows={4}
            disabled={field.disabled}
          />
        );
        
      default:
        return (
          <input
            type={field.type || 'text'}
            id={field.key}
            name={field.key}
            value={formData[field.key] || ''}
            onChange={handleChange}
            className={`form-control ${errors[field.key] ? 'is-invalid' : ''}`}
            placeholder={field.placeholder || `Enter ${field.label}`}
            step={field.step}
            min={field.min}
            max={field.max}
            pattern={field.pattern}
            disabled={field.disabled}
          />
        );
    }
  };

  // Add this helper function to determine field CSS class
  const getFieldClassName = (field) => {
    let className = 'form-group';
    
    // Add type-specific classes
    if (field.type === 'number' && field.isCurrency) {
      className += ' currency';
    }
    
    // Add width classes
    if (field.fullWidth) {
      className += ' full-width';
    }
    
    return className;
  };

  // Use custom fields if available
  const fieldsToRender = customFieldsFromStorage || getEditableFields(selectedMasterType);

  return (
    <div className="modal-overlay">
      <div className="modal-content edit-modal">
        <div className="modal-header">
          <h2>
            {modalType === 'add' ? `Add New ${selectedMasterType.replace(/_/g, ' ')}` : 
             `Edit ${selectedMasterType.replace(/_/g, ' ')}`}
          </h2>
          <button className="close-btn" onClick={onClose}>
            <X size={18} />
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit} className="edit-form-grid">
            {fieldsToRender.map((field) => (
              <div key={field.key} className={getFieldClassName(field)}>
                <label htmlFor={field.key}>
                  {field.label}
                  {field.required && <span className="required-mark">*</span>}
                </label>
                
                {renderField(field)}
                
                {errors[field.key] && (
                  <div className="error-message">{errors[field.key]}</div>
                )}
                
                {field.helpText && (
                  <small className="help-text">{field.helpText}</small>
                )}
              </div>
            ))}
          </form>
        </div>
        <div className="modal-footer">
          <button 
            className="modal-btn modal-btn-secondary"
            onClick={onClose}
            disabled={loading}
            type="button"
          >
            Cancel
          </button>
          <button 
            className="modal-btn modal-btn-primary"
            onClick={handleSubmit}
            disabled={loading}
            type="submit"
          >
            {loading ? 'Saving...' : modalType === 'add' ? 'Create' : 'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
}; 