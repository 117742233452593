import React from 'react';
import { Link } from 'react-router-dom';

const AccessDenied = () => {
  return (
    <div className="access-denied-container">
      <div className="access-denied-content">
        <h1>Access Denied</h1>
        <p>You don't have permission to access this page.</p>
        <p>Please contact your administrator if you believe this is an error.</p>
        <div className="access-denied-actions">
          <Link to="/" className="primary-button">
            Go to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied; 