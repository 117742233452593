import axios from 'axios';

// Get the API URL from environment variables with a fallback
let API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Ensure API_BASE_URL doesn't end with a slash
if (API_BASE_URL.endsWith('/')) {
    API_BASE_URL = API_BASE_URL.slice(0, -1);
}

// Create axios instance with default config
const apiClient = axios.create({
    baseURL: API_BASE_URL,
    timeout: 10000, // 10 seconds timeout
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add request interceptor to handle errors
apiClient.interceptors.request.use(
    (config) => {
        // Add any request preprocessing here
        return config;
    },
    (error) => {
        console.error('API Request Error:', error);
        return Promise.reject(new Error('Unable to connect to the server. Please check your internet connection.'));
    }
);

// Add response interceptor to handle errors
apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (!error.response) {
            // Network error
            console.error('Network Error:', error);
            throw new Error('Unable to connect to the server. Please check your internet connection.');
        }
        if (error.response.status === 404) {
            throw new Error('API endpoint not found. Please check the server configuration.');
        }
        throw error;
    }
);

export const loginUser = async (email, password) => {
    try {
        console.log('Attempting to connect to:', `${API_BASE_URL}/auth/login`);
        
        const response = await apiClient.post('/auth/login', { 
            email, 
            password,
            ipAddress: window.location.hostname,
            userAgent: navigator.userAgent
        });

        // Check if response has success property and it's false
        if (response.data && response.data.success === false) {
            throw new Error(response.data.message || 'Authentication failed');
        }

        // Validate the response structure
        if (!response.data || !response.data.token || !response.data.user) {
            throw new Error('Invalid response format from server');
        }

        
        return response.data;
    } catch (error) {
        console.error('Login Error Details:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status
        });
        
        if (error.response) {
            // Server responded with an error
            throw new Error(error.response.data?.message || 'Authentication failed');
        }
        
        // Network or other errors
        throw error;
    }
};

export default loginUser;
