import React, { useState, useEffect, useRef } from 'react';
import { 
  Card, Row, Col, Space, Typography, Statistic, 
  Table, Tag, Button, Divider, DatePicker, Select,
  Tooltip, Spin, Radio, Input, Progress,
  Tabs, List, Avatar, Switch, Checkbox
} from 'antd';
import { 
  LineChartOutlined, BarChartOutlined, DollarOutlined,
  ReloadOutlined, FilterOutlined, InfoCircleOutlined,
  ArrowUpOutlined, ArrowDownOutlined, SearchOutlined,
  DownloadOutlined, CalendarOutlined,
  CheckCircleOutlined, CloseCircleOutlined,
  ShoppingOutlined, RiseOutlined,
  GlobalOutlined, ShoppingCartOutlined,
  AimOutlined,
  PieChartOutlined,
  TrophyOutlined, StarOutlined
} from '@ant-design/icons';
import { TrendingDown, AlertTriangle } from 'react-feather';
import moment from 'moment';

const { Title, Text, Paragraph } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;
// const { Panel } = Collapse; // Commented out - unused

// Mock data for price analytics
// const priceHistoryData = [
//   { date: '2023-01-01', yourPrice: 24.99, avgCompetitorPrice: 26.50, minCompetitorPrice: 22.99, maxCompetitorPrice: 29.99 },
//   { date: '2023-02-01', yourPrice: 24.99, avgCompetitorPrice: 26.20, minCompetitorPrice: 22.99, maxCompetitorPrice: 28.99 },
//   { date: '2023-03-01', yourPrice: 23.99, avgCompetitorPrice: 25.80, minCompetitorPrice: 21.99, maxCompetitorPrice: 28.99 },
//   { date: '2023-04-01', yourPrice: 23.99, avgCompetitorPrice: 25.50, minCompetitorPrice: 21.99, maxCompetitorPrice: 27.99 },
//   { date: '2023-05-01', yourPrice: 22.99, avgCompetitorPrice: 24.80, minCompetitorPrice: 20.99, maxCompetitorPrice: 27.99 },
//   { date: '2023-06-01', yourPrice: 22.99, avgCompetitorPrice: 24.30, minCompetitorPrice: 20.99, maxCompetitorPrice: 26.99 },
// ];

const priceCompetitiveness = [
  { id: 1, product: 'Premium Rice Basmati', yourPrice: '$24.99', avgMarketPrice: '$26.50', priceDiff: -5.7, status: 'competitive' },
  { id: 2, product: 'Brown Rice Organic', yourPrice: '$18.50', avgMarketPrice: '$17.20', priceDiff: 7.6, status: 'high' },
  { id: 3, product: 'Jasmine Rice 5kg', yourPrice: '$22.75', avgMarketPrice: '$22.90', priceDiff: -0.7, status: 'optimal' },
  { id: 4, product: 'Wild Rice Mix', yourPrice: '$28.99', avgMarketPrice: '$27.50', priceDiff: 5.4, status: 'high' },
  { id: 5, product: 'Sushi Rice Premium', yourPrice: '$19.95', avgMarketPrice: '$21.30', priceDiff: -6.3, status: 'competitive' },
];

const priceAlerts = [
  { id: 1, product: 'Premium Rice Basmati', alert: 'Competitor price drop', description: 'Major competitor reduced price by 15%', time: '2 hours ago', severity: 'high' },
  { id: 2, product: 'Brown Rice Organic', alert: 'Price gap widening', description: 'Your price is now 7.6% above market average', time: '1 day ago', severity: 'medium' },
  { id: 3, product: 'Wild Rice Mix', alert: 'Pricing opportunity', description: 'Market prices increasing, consider adjusting yours', time: '3 days ago', severity: 'low' },
];

// const priceOptimizationData = [
//   { id: 1, product: 'Premium Rice Basmati', currentPrice: '$24.99', suggestedPrice: '$25.50', potentialImpact: '+3.2%', confidence: 'high' },
//   { id: 2, product: 'Brown Rice Organic', currentPrice: '$18.50', suggestedPrice: '$17.75', potentialImpact: '+5.1%', confidence: 'medium' },
//   { id: 3, product: 'Jasmine Rice 5kg', currentPrice: '$22.75', suggestedPrice: '$22.75', potentialImpact: '0%', confidence: 'high' },
//   { id: 4, product: 'Wild Rice Mix', currentPrice: '$28.99', suggestedPrice: '$27.99', potentialImpact: '+2.8%', confidence: 'medium' },
//   { id: 5, product: 'Sushi Rice Premium', currentPrice: '$19.95', suggestedPrice: '$20.50', potentialImpact: '+4.2%', confidence: 'high' },
// ];

// Add more detailed platform pricing data
const enhancedPlatformPricingData = [
  { 
    id: 1, 
    product: 'Premium Rice Basmati', 
    yourPrice: '$24.99',
    platforms: [
      { 
        name: 'Amazon', 
        price: '$26.99', 
        difference: -7.4, 
        followsRecommended: true,
        marketShare: 42,
        salesVolume: 'High',
        rating: 4.5,
        reviewCount: 1250,
        buyBoxWinRate: '85%',
        competitorPrices: [
          { name: 'Competitor A', price: '$27.99' },
          { name: 'Competitor B', price: '$26.50' },
          { name: 'Competitor C', price: '$25.99' }
        ]
      },
      { 
        name: 'Flipkart', 
        price: '$25.50', 
        difference: -2.0, 
        followsRecommended: true,
        marketShare: 28,
        salesVolume: 'Medium',
        rating: 4.3,
        reviewCount: 850,
        buyBoxWinRate: '78%',
        competitorPrices: [
          { name: 'Competitor A', price: '$26.99' },
          { name: 'Competitor B', price: '$25.99' },
          { name: 'Competitor C', price: '$24.99' }
        ]
      },
      { 
        name: 'BigBasket', 
        price: '$24.99', 
        difference: 0, 
        followsRecommended: true,
        marketShare: 15,
        salesVolume: 'Medium',
        rating: 4.4,
        reviewCount: 620,
        buyBoxWinRate: '92%',
        competitorPrices: [
          { name: 'Competitor A', price: '$25.99' },
          { name: 'Competitor B', price: '$24.50' },
          { name: 'Competitor C', price: '$24.99' }
        ]
      },
      { 
        name: 'Blinkit', 
        price: '$27.99', 
        difference: -10.7, 
        followsRecommended: false,
        marketShare: 8,
        salesVolume: 'Low',
        rating: 4.2,
        reviewCount: 320,
        buyBoxWinRate: '65%',
        competitorPrices: [
          { name: 'Competitor A', price: '$26.99' },
          { name: 'Competitor B', price: '$27.50' },
          { name: 'Competitor C', price: '$28.99' }
        ]
      },
      { 
        name: 'Zepto', 
        price: '$26.49', 
        difference: -5.7, 
        followsRecommended: true,
        marketShare: 7,
        salesVolume: 'Low',
        rating: 4.1,
        reviewCount: 280,
        buyBoxWinRate: '70%',
        competitorPrices: [
          { name: 'Competitor A', price: '$25.99' },
          { name: 'Competitor B', price: '$26.99' },
          { name: 'Competitor C', price: '$27.49' }
        ]
      }
    ],
    recommendedPrice: '$25.99',
    recommendationRationale: {
      competitionFactor: 'High competition on Amazon and Flipkart suggests maintaining competitive pricing',
      demandFactor: 'Strong demand across platforms indicates opportunity for slight price increase',
      inventoryFactor: 'Current inventory levels are healthy, supporting a balanced pricing approach',
      profitabilityFactor: 'Recommended price optimizes profit while maintaining competitive position'
    },
    priceHistory: [
      { date: '2023-01', price: 26.99 },
      { date: '2023-02', price: 25.99 },
      { date: '2023-03', price: 25.99 },
      { date: '2023-04', price: 24.99 },
      { date: '2023-05', price: 24.99 },
      { date: '2023-06', price: 24.99 }
    ],
    priceOptimizationScenarios: [
      { scenario: 'Maximize Profit', price: '$26.50', volumeChange: '-5%', revenueChange: '+3.2%', profitChange: '+7.5%' },
      { scenario: 'Maximize Volume', price: '$23.50', volumeChange: '+12%', revenueChange: '+4.8%', profitChange: '+2.1%' },
      { scenario: 'Balanced Approach', price: '$24.99', volumeChange: '+2%', revenueChange: '+4.1%', profitChange: '+4.8%' },
      { scenario: 'Match Competition', price: '$25.75', volumeChange: '-2%', revenueChange: '+1.5%', profitChange: '+3.2%' }
    ]
  },
  // Add similar detailed data for other products
  { 
    id: 2, 
    product: 'Brown Rice Organic', 
    yourPrice: '$18.50',
    platforms: [
      { 
        name: 'Amazon', 
        price: '$17.99', 
        difference: 2.8, 
        followsRecommended: false,
        marketShare: 38,
        salesVolume: 'Medium',
        rating: 4.3,
        reviewCount: 980,
        buyBoxWinRate: '72%',
        competitorPrices: [
          { name: 'Competitor A', price: '$18.99' },
          { name: 'Competitor B', price: '$17.50' },
          { name: 'Competitor C', price: '$16.99' }
        ]
      },
      { 
        name: 'Flipkart', 
        price: '$16.99', 
        difference: 8.9, 
        followsRecommended: false,
        marketShare: 32,
        salesVolume: 'Medium',
        rating: 4.2,
        reviewCount: 720,
        buyBoxWinRate: '65%',
        competitorPrices: [
          { name: 'Competitor A', price: '$17.99' },
          { name: 'Competitor B', price: '$16.50' },
          { name: 'Competitor C', price: '$15.99' }
        ]
      },
      { 
        name: 'BigBasket', 
        price: '$17.50', 
        difference: 5.7, 
        followsRecommended: false,
        marketShare: 18,
        salesVolume: 'Medium',
        rating: 4.4,
        reviewCount: 540,
        buyBoxWinRate: '80%',
        competitorPrices: [
          { name: 'Competitor A', price: '$18.50' },
          { name: 'Competitor B', price: '$17.25' },
          { name: 'Competitor C', price: '$16.99' }
        ]
      },
      { 
        name: 'Blinkit', 
        price: '$16.99', 
        difference: 8.9, 
        followsRecommended: false,
        marketShare: 7,
        salesVolume: 'Low',
        rating: 4.1,
        reviewCount: 280,
        buyBoxWinRate: '60%',
        competitorPrices: [
          { name: 'Competitor A', price: '$17.99' },
          { name: 'Competitor B', price: '$16.50' },
          { name: 'Competitor C', price: '$15.99' }
        ]
      },
      { 
        name: 'Zepto', 
        price: '$17.25', 
        difference: 7.2, 
        followsRecommended: false,
        marketShare: 5,
        salesVolume: 'Low',
        rating: 4.0,
        reviewCount: 210,
        buyBoxWinRate: '55%',
        competitorPrices: [
          { name: 'Competitor A', price: '$18.25' },
          { name: 'Competitor B', price: '$17.50' },
          { name: 'Competitor C', price: '$16.75' }
        ]
      }
    ],
    recommendedPrice: '$17.25',
    recommendationRationale: {
      competitionFactor: 'Competitors are pricing significantly lower across all platforms',
      demandFactor: 'Price sensitivity is high for this product, suggesting a lower price point',
      inventoryFactor: 'Current inventory levels are high, supporting a more aggressive pricing strategy',
      profitabilityFactor: 'Lower price point will increase volume and overall profitability'
    },
    priceHistory: [
      { date: '2023-01', price: 17.99 },
      { date: '2023-02', price: 17.99 },
      { date: '2023-03', price: 18.50 },
      { date: '2023-04', price: 18.50 },
      { date: '2023-05', price: 18.50 },
      { date: '2023-06', price: 18.50 }
    ],
    priceOptimizationScenarios: [
      { scenario: 'Maximize Profit', price: '$18.99', volumeChange: '-8%', revenueChange: '+1.5%', profitChange: '+4.2%' },
      { scenario: 'Maximize Volume', price: '$16.99', volumeChange: '+15%', revenueChange: '+5.2%', profitChange: '+3.1%' },
      { scenario: 'Balanced Approach', price: '$17.50', volumeChange: '+5%', revenueChange: '+3.8%', profitChange: '+4.5%' },
      { scenario: 'Match Competition', price: '$17.25', volumeChange: '+7%', revenueChange: '+4.2%', profitChange: '+3.8%' }
    ]
  }
];

// New data for price trend analysis
// const priceTrendData = [
//   {
//     id: 1,
//     product: 'Premium Rice Basmati',
//     currentPrice: '$24.99',
//     priceChange: -7.4,
//     trend: 'decreasing',
//     seasonalPattern: 'Prices typically drop in summer months',
//     competitorTrend: 'Major competitors are also decreasing prices',
//     forecast: 'Expected to stabilize around $24.50 in the next month',
//     recommendation: 'Maintain current price to remain competitive'
//   },
//   {
//     id: 2,
//     product: 'Brown Rice Organic',
//     currentPrice: '$18.50',
//     priceChange: 2.9,
//     trend: 'increasing',
//     seasonalPattern: 'Prices typically increase during winter',
//     competitorTrend: 'Competitors are maintaining lower prices',
//     forecast: 'Expected to face downward pressure in coming weeks',
//     recommendation: 'Consider reducing price to $17.75 to remain competitive'
//   },
//   {
//     id: 3,
//     product: 'Jasmine Rice 5kg',
//     currentPrice: '$22.75',
//     priceChange: -3.2,
//     trend: 'stable',
//     seasonalPattern: 'No significant seasonal patterns observed',
//     competitorTrend: 'Competitors maintaining similar prices',
//     forecast: 'Expected to remain stable in the near term',
//     recommendation: 'Maintain current price point'
//   }
// ];

// Add this new mock data for location-based pricing
// const locationPricingData = [
//   {
//     region: 'North',
//     locations: ['Delhi', 'Chandigarh', 'Lucknow', 'Jaipur'],
//     products: [
//       {
//         id: 1,
//         name: 'Premium Rice Basmati',
//         currentPrice: '$25.99',
//         recommendedPrice: '$26.50',
//         competitorAvg: '$27.25',
//         salesVolume: 'High',
//         priceElasticity: 'Medium'
//       },
//       {
//         id: 2,
//         name: 'Brown Rice Organic',
//         currentPrice: '$19.50',
//         recommendedPrice: '$18.75',
//         competitorAvg: '$18.20',
//         salesVolume: 'Medium',
//         priceElasticity: 'High'
//       }
//     ]
//   },
//   {
//     region: 'South',
//     locations: ['Bangalore', 'Chennai', 'Hyderabad', 'Kochi'],
//     products: [
//       {
//         id: 1,
//         name: 'Premium Rice Basmati',
//         currentPrice: '$24.50',
//         recommendedPrice: '$25.25',
//         competitorAvg: '$26.75',
//         salesVolume: 'Medium',
//         priceElasticity: 'Low'
//       },
//       {
//         id: 2,
//         name: 'Brown Rice Organic',
//         currentPrice: '$17.99',
//         recommendedPrice: '$17.50',
//         competitorAvg: '$16.90',
//         salesVolume: 'Low',
//         priceElasticity: 'High'
//       }
//     ]
//   },
//   {
//     region: 'East',
//     locations: ['Kolkata', 'Bhubaneswar', 'Patna', 'Guwahati'],
//     products: [
//       {
//         id: 1,
//         name: 'Premium Rice Basmati',
//         currentPrice: '$23.99',
//         recommendedPrice: '$24.75',
//         competitorAvg: '$25.50',
//         salesVolume: 'Medium',
//         priceElasticity: 'Medium'
//       },
//       {
//         id: 2,
//         name: 'Brown Rice Organic',
//         currentPrice: '$18.25',
//         recommendedPrice: '$17.75',
//         competitorAvg: '$17.25',
//         salesVolume: 'Low',
//         priceElasticity: 'Medium'
//       }
//     ]
//   },
//   {
//     region: 'West',
//     locations: ['Mumbai', 'Pune', 'Ahmedabad', 'Surat'],
//     products: [
//       {
//         id: 1,
//         name: 'Premium Rice Basmati',
//         currentPrice: '$26.50',
//         recommendedPrice: '$27.25',
//         competitorAvg: '$28.50',
//         salesVolume: 'High',
//         priceElasticity: 'Low'
//       },
//       {
//         id: 2,
//         name: 'Brown Rice Organic',
//         currentPrice: '$19.99',
//         recommendedPrice: '$19.25',
//         competitorAvg: '$18.75',
//         salesVolume: 'Medium',
//         priceElasticity: 'Medium'
//       }
//     ]
//   }
// ];

const PriceAnalyticsView = () => {
  const [timeRange, setTimeRange] = useState('month');
  const [loading, setLoading] = useState(false);
  const [chartType, setChartType] = useState('line');
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'month'), moment()]);
  const [alertFilter, setAlertFilter] = useState('all');
  const [searchText, setSearchText] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('1');
  const [showRecommendedPrices, setShowRecommendedPrices] = useState(true);
  const [selectedPlatforms, setSelectedPlatforms] = useState(['Amazon', 'Flipkart', 'BigBasket', 'Blinkit', 'Zepto']);
  const [displayMode, setDisplayMode] = useState('cards');
  const [selectedPlatformDetail, setSelectedPlatformDetail] = useState(null);
  const [showCompetitorPrices, setShowCompetitorPrices] = useState(false);
  const [priceOptimizationView, setPriceOptimizationView] = useState('scenarios');
  
  const chartContainerRef = useRef(null);
  
  useEffect(() => {
    console.log('PriceAnalyticsView component mounted');
    // Simulate loading
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    
    return () => {
      clearTimeout(timer);
      console.log('PriceAnalyticsView component unmounted');
    };
  }, []);

  const handleTimeRangeChange = (value) => {
    setTimeRange(value);
    setLoading(true);
    
    // Set appropriate date range based on selection
    if (value === 'week') {
      setDateRange([moment().subtract(1, 'week'), moment()]);
    } else if (value === 'month') {
      setDateRange([moment().subtract(1, 'month'), moment()]);
    } else if (value === 'quarter') {
      setDateRange([moment().subtract(3, 'months'), moment()]);
    } else if (value === 'year') {
      setDateRange([moment().subtract(1, 'year'), moment()]);
    }
    
    // Simulate data refresh
    setTimeout(() => {
      setLoading(false);
    }, 800);
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      setTimeRange('custom');
      setLoading(true);
      // Simulate data refresh
      setTimeout(() => {
        setLoading(false);
      }, 800);
    }
  };

  const handleRefresh = () => {
    setLoading(true);
    // Simulate data refresh
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleProductChange = (value) => {
    setSelectedProduct(value);
    setLoading(true);
    // Simulate data refresh
    setTimeout(() => {
      setLoading(false);
    }, 800);
  };

  const handlePlatformSelect = (platform) => {
    setSelectedPlatformDetail(platform);
  };

  const renderPriceStatusTag = (status) => {
    let color = 'green';
    let text = 'Optimal';
    
    if (status === 'competitive') {
      color = 'blue';
      text = 'Competitive';
    } else if (status === 'high') {
      color = 'orange';
      text = 'High';
    } else if (status === 'low') {
      color = 'red';
      text = 'Too Low';
    }
    
    return <Tag color={color}>{text}</Tag>;
  };

  const renderPriceDiff = (diff) => {
    return (
      <Text type={diff < 0 ? 'success' : diff > 5 ? 'danger' : 'warning'}>
        {diff < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />} {Math.abs(diff)}%
      </Text>
    );
  };

  // const renderConfidenceTag = (confidence) => {
  //   const colors = {
  //     high: 'green',
  //     medium: 'blue',
  //     low: 'orange'
  //   };
  //   return <Tag color={colors[confidence]}>{confidence.toUpperCase()}</Tag>;
  // };

  const renderPriceHistoryChart = () => {
    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
          <Spin size="large" />
        </div>
      );
    }

    // Simple line chart visualization
    return (
      <div style={{ padding: '0 8px' }}>
        <div style={{ position: 'relative', height: 300, background: 'rgba(24, 144, 255, 0.05)', borderRadius: 8, padding: 16, overflow: 'hidden' }}>
          {/* Chart grid lines */}
          <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'grid', gridTemplateRows: 'repeat(5, 1fr)', gridTemplateColumns: 'repeat(6, 1fr)', pointerEvents: 'none' }}>
            {Array(5).fill(0).map((_, i) => (
              <div key={`h-${i}`} style={{ gridRow: i + 1, gridColumn: '1 / span 6', borderBottom: '1px dashed rgba(0,0,0,0.1)' }} />
            ))}
            {Array(6).fill(0).map((_, i) => (
              <div key={`v-${i}`} style={{ gridColumn: i + 1, gridRow: '1 / span 5', borderRight: '1px dashed rgba(0,0,0,0.1)' }} />
            ))}
          </div>
          
          {/* Y-axis labels */}
          <div style={{ position: 'absolute', top: 10, left: 5, bottom: 30, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Text type="secondary" style={{ fontSize: 11 }}>$30</Text>
            <Text type="secondary" style={{ fontSize: 11 }}>$27</Text>
            <Text type="secondary" style={{ fontSize: 11 }}>$24</Text>
            <Text type="secondary" style={{ fontSize: 11 }}>$21</Text>
            <Text type="secondary" style={{ fontSize: 11 }}>$18</Text>
          </div>
          
          {/* Price range area */}
          <div style={{ position: 'absolute', top: 30, left: 30, right: 30, bottom: 30, display: 'flex', alignItems: 'center' }}>
            <svg width="100%" height="100%" viewBox="0 0 600 240" preserveAspectRatio="none">
              {/* Price range area */}
              <path 
                d="M0,40 L100,50 L200,60 L300,70 L400,80 L500,90 L600,100 L600,180 L500,170 L400,160 L300,150 L200,140 L100,130 L0,120 Z" 
                fill="rgba(24, 144, 255, 0.1)" 
                stroke="none"
              />
              
              {/* Average competitor price line */}
              <path 
                d="M0,80 L100,85 L200,90 L300,95 L400,100 L500,105 L600,110" 
                fill="none" 
                stroke="#faad14" 
                strokeWidth="2"
                strokeDasharray="5,5"
              />
              
              {/* Your price line */}
              <path 
                d="M0,100 L100,100 L200,110 L300,110 L400,120 L500,120 L600,120" 
                fill="none" 
                stroke="#1890ff" 
                strokeWidth="3"
              />
              
              {/* Recommended price line (if enabled) */}
              {showRecommendedPrices && (
                <path 
                  d="M0,90 L100,90 L200,100 L300,100 L400,110 L500,110 L600,110" 
                  fill="none" 
                  stroke="#52c41a" 
                  strokeWidth="2"
                  strokeDasharray="3,3"
                />
              )}
              
              {/* Data points for your price */}
              {[0, 100, 200, 300, 400, 500, 600].map((x, i) => {
                const y = i < 2 ? 100 : i < 4 ? 110 : 120;
                return (
                  <circle 
                    key={i} 
                    cx={x} 
                    cy={y} 
                    r="4" 
                    fill="#1890ff"
                  />
                );
              })}
            </svg>
          </div>
          
          {/* Legend */}
          <div style={{ position: 'absolute', top: 20, right: 20, background: 'rgba(255,255,255,0.9)', padding: '8px 12px', borderRadius: 4, boxShadow: '0 2px 8px rgba(0,0,0,0.15)' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
              <div style={{ width: 12, height: 3, backgroundColor: '#1890ff', marginRight: 8 }}></div>
              <Text strong>Your Price</Text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
              <div style={{ width: 12, height: 3, backgroundColor: '#faad14', marginRight: 8, borderTop: '1px dashed #faad14', borderBottom: '1px dashed #faad14' }}></div>
              <Text>Avg. Competitor Price</Text>
            </div>
            {showRecommendedPrices && (
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                <div style={{ width: 12, height: 3, backgroundColor: '#52c41a', marginRight: 8, borderTop: '1px dashed #52c41a', borderBottom: '1px dashed #52c41a' }}></div>
                <Text type="success">Recommended Price</Text>
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: 12, height: 12, backgroundColor: 'rgba(24, 144, 255, 0.1)', marginRight: 8 }}></div>
              <Text>Price Range (Min-Max)</Text>
            </div>
          </div>
          
          {/* X-axis labels */}
          <div style={{ position: 'absolute', bottom: 0, left: 30, right: 30, display: 'flex', justifyContent: 'space-between' }}>
            {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'].map(month => (
              <div key={month} style={{ padding: '4px 0' }}>
                <Text strong style={{ fontSize: 12 }}>{month}</Text>
              </div>
            ))}
          </div>
        </div>
        
        <div style={{ textAlign: 'left', marginTop: '16px', paddingLeft: '8px' }}>
          <Text type="secondary">
            Your prices have been consistently <Text strong type="success">below</Text> the market average by approximately <Text strong>5.7%</Text>. 
            This competitive pricing strategy has maintained a healthy balance between market position and profitability.
            {showRecommendedPrices && (
              <span> The <Text strong type="success">recommended price</Text> suggests a slight increase to optimize profit while remaining competitive.</span>
            )}
          </Text>
        </div>
      </div>
    );
  };

  const renderPlatformComparisonChart = () => {
    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
          <Spin size="large" />
        </div>
      );
    }

    // Get the selected product data or use the first one as default
    const productData = enhancedPlatformPricingData.find(p => p.id.toString() === selectedProduct.toString()) || enhancedPlatformPricingData[0];
    
    // Filter platforms based on selection
    const filteredPlatforms = productData.platforms.filter(p => selectedPlatforms.includes(p.name));
    
    return (
      <div style={{ padding: '0 8px' }}>
        {/* View Mode Selector */}
        <div style={{ marginBottom: 16 }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Space>
                <Text strong>Product:</Text>
                <Select 
                  style={{ width: 200 }} 
                  value={selectedProduct}
                  onChange={handleProductChange}
                >
                  {enhancedPlatformPricingData.map(product => (
                    <Option key={product.id} value={product.id.toString()}>{product.product}</Option>
                  ))}
                </Select>
              </Space>
            </div>
            
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Space>
                <Text strong>Platforms:</Text>
                <Checkbox.Group 
                  options={['Amazon', 'Flipkart', 'BigBasket', 'Blinkit', 'Zepto']} 
                  value={selectedPlatforms} 
                  onChange={setSelectedPlatforms} 
                />
              </Space>
              
              <Space>
                <Text strong>Show Recommended Price:</Text>
                <Switch 
                  checked={showRecommendedPrices} 
                  onChange={setShowRecommendedPrices} 
                  checkedChildren="On" 
                  unCheckedChildren="Off"
                />
                
                <Text strong>Show Competitor Prices:</Text>
                <Switch 
                  checked={showCompetitorPrices} 
                  onChange={setShowCompetitorPrices} 
                  checkedChildren="On" 
                  unCheckedChildren="Off"
                />
              </Space>
            </div>
          </Space>
        </div>
        
        <Row gutter={[16, 16]}>
          <Col xs={24} md={selectedPlatformDetail ? 12 : 24}>
            <Card 
              title={
                <Space>
                  <ShoppingOutlined />
                  <span>Platform Price Comparison for {productData.product}</span>
                </Space>
              }
              size="small" 
              className="glass-effect"
              style={{ height: '100%' }}
              extra={
                <Button 
                  type="link" 
                  size="small" 
                  onClick={() => setSelectedPlatformDetail(null)}
                  style={{ display: selectedPlatformDetail ? 'inline-block' : 'none' }}
                >
                  View All
                </Button>
              }
            >
              {displayMode === 'cards' && (
                <div style={{ height: 350, overflow: 'auto' }}>
                  <List
                    grid={{ gutter: 16, column: selectedPlatformDetail ? 1 : 2, xs: 1, sm: 1, md: selectedPlatformDetail ? 1 : 2 }}
                    dataSource={filteredPlatforms}
                    renderItem={item => (
                      <List.Item>
                        <Card 
                          hoverable 
                          size="small"
                          style={{ 
                            borderLeft: `4px solid ${item.followsRecommended ? '#52c41a' : '#f5222d'}`,
                            boxShadow: '0 2px 8px rgba(0,0,0,0.09)',
                            height: '100%'
                          }}
                          actions={[
                            <Button 
                              type="link" 
                              onClick={() => handlePlatformSelect(item)}
                              style={{ display: selectedPlatformDetail ? 'none' : 'inline-block' }}
                            >
                              Details
                            </Button>
                          ]}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
                            <Avatar 
                              icon={<ShoppingOutlined />} 
                              style={{ 
                                backgroundColor: item.followsRecommended ? '#52c41a' : '#f5222d',
                                marginRight: 12
                              }} 
                            />
                            <div>
                              <Text strong style={{ fontSize: 16 }}>{item.name}</Text>
                              <div>
                                <Tag color="blue">Market Share: {item.marketShare}%</Tag>
                                <Tag color={item.salesVolume === 'High' ? 'green' : item.salesVolume === 'Medium' ? 'blue' : 'orange'}>
                                  {item.salesVolume} Volume
                                </Tag>
                              </div>
                            </div>
                          </div>
                          
                          <Row gutter={[8, 8]}>
                            <Col span={12}>
                              <Statistic 
                                title="Your Price" 
                                value={item.price} 
                                valueStyle={{ fontSize: 18 }}
                              />
                            </Col>
                            <Col span={12}>
                              <Statistic 
                                title="vs. Market" 
                                value={renderPriceDiff(item.difference)} 
                                valueStyle={{ fontSize: 18 }}
                              />
                            </Col>
                          </Row>
                          
                          <Divider style={{ margin: '12px 0' }} />
                          
                          <Row gutter={[8, 8]}>
                            <Col span={12}>
                              <div>
                                <Text type="secondary">Rating:</Text>
                                <div>
                                  <Text>{item.rating}</Text>
                                  <Text type="secondary"> ({item.reviewCount})</Text>
                                </div>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div>
                                <Text type="secondary">Buy Box Win:</Text>
                                <div>
                                  <Text>{item.buyBoxWinRate}</Text>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          
                          {showCompetitorPrices && (
                            <>
                              <Divider style={{ margin: '12px 0' }} />
                              <div>
                                <Text strong>Competitor Prices:</Text>
                                <ul style={{ margin: '4px 0 0 16px', padding: 0 }}>
                                  {item.competitorPrices.map((comp, idx) => (
                                    <li key={idx}>
                                      <Text>{comp.name}: {comp.price}</Text>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </>
                          )}
                        </Card>
                      </List.Item>
                    )}
                  />
                </div>
              )}
              
              {displayMode === 'table' && (
                <Table 
                  dataSource={filteredPlatforms}
                  columns={[
                    {
                      title: 'Platform',
                      dataIndex: 'name',
                      key: 'name',
                      render: (text, record) => (
                        <Space>
                          <Avatar 
                            icon={<ShoppingOutlined />} 
                            size="small"
                            style={{ 
                              backgroundColor: record.followsRecommended ? '#52c41a' : '#f5222d',
                            }} 
                          />
                          <Text strong>{text}</Text>
                          {record.followsRecommended ? 
                            <Tag color="green"><CheckCircleOutlined /> Follows Rec.</Tag> : 
                            <Tag color="red"><CloseCircleOutlined /> Above Rec.</Tag>}
                        </Space>
                      ),
                    },
                    {
                      title: 'Your Price',
                      dataIndex: 'price',
                      key: 'price',
                      sorter: (a, b) => parseFloat(a.price.replace('$', '')) - parseFloat(b.price.replace('$', '')),
                    },
                    {
                      title: 'Market Share',
                      dataIndex: 'marketShare',
                      key: 'marketShare',
                      sorter: (a, b) => a.marketShare - b.marketShare,
                      render: (value) => `${value}%`,
                    },
                    {
                      title: 'vs. Market',
                      dataIndex: 'difference',
                      key: 'difference',
                      sorter: (a, b) => a.difference - b.difference,
                      render: renderPriceDiff,
                    },
                    {
                      title: 'Sales Volume',
                      dataIndex: 'salesVolume',
                      key: 'salesVolume',
                      filters: [
                        { text: 'High', value: 'High' },
                        { text: 'Medium', value: 'Medium' },
                        { text: 'Low', value: 'Low' },
                      ],
                      onFilter: (value, record) => record.salesVolume === value,
                      render: (text) => <Tag color={text === 'High' ? 'green' : text === 'Medium' ? 'blue' : 'orange'}>{text}</Tag>,
                    },
                    {
                      title: 'Action',
                      key: 'action',
                      render: (_, record) => (
                        <Button 
                          type="link" 
                          onClick={() => handlePlatformSelect(record)}
                          style={{ display: selectedPlatformDetail ? 'none' : 'inline-block' }}
                        >
                          Details
                        </Button>
                      ),
                    }
                  ]}
                  pagination={false}
                  size="small"
                  expandable={showCompetitorPrices ? {
                    expandedRowRender: record => (
                      <div style={{ margin: 0 }}>
                        <Text strong>Competitor Prices:</Text>
                        <Table 
                          dataSource={record.competitorPrices}
                          columns={[
                            { title: 'Competitor', dataIndex: 'name', key: 'name' },
                            { title: 'Price', dataIndex: 'price', key: 'price' },
                            { 
                              title: 'Difference', 
                              key: 'difference',
                              render: (_, record) => renderPriceDiff(
                                ((parseFloat(record.price.replace('$', '')) - 
                                  parseFloat(productData.yourPrice.replace('$', ''))) / 
                                  parseFloat(productData.yourPrice.replace('$', ''))) * 100
                              )
                            }
                          ]}
                          pagination={false}
                          size="small"
                        />
                      </div>
                    ),
                  } : null}
                />
              )}
              
              {displayMode === 'chart' && (
                <div style={{ height: 350 }}>
                  <div style={{ position: 'relative', height: '100%', background: 'rgba(24, 144, 255, 0.05)', borderRadius: 8, padding: 16 }}>
                    {/* Simple bar chart */}
                    <div style={{ display: 'flex', height: '80%', alignItems: 'flex-end', justifyContent: 'space-around' }}>
                      {filteredPlatforms.map((platform, index) => {
                        const price = parseFloat(platform.price.replace('$', ''));
                        const height = (price / 30) * 100; // Scale to percentage
                        
                        return (
                          <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '15%' }}>
                            <div 
                              style={{ 
                                height: `${height}%`, 
                                width: '70%', 
                                backgroundColor: platform.followsRecommended ? '#52c41a' : '#f5222d',
                                borderRadius: '4px 4px 0 0',
                                position: 'relative',
                                cursor: 'pointer',
                                transition: 'all 0.3s ease'
                              }}
                              onClick={() => handlePlatformSelect(platform)}
                            >
                              <div style={{ position: 'absolute', top: -20, width: '100%', textAlign: 'center' }}>
                                <Text strong style={{ fontSize: 12 }}>{platform.price}</Text>
                              </div>
                              
                              {showCompetitorPrices && platform.competitorPrices.map((comp, idx) => {
                                const compPrice = parseFloat(comp.price.replace('$', ''));
                                const compHeight = (compPrice / 30) * 100;
                                const offset = (idx + 1) * 15;
                                
                                return (
                                  <div 
                                    key={idx}
                                    style={{ 
                                      position: 'absolute',
                                      left: `${offset}px`,
                                      bottom: 0,
                                      height: `${compHeight}%`, 
                                      width: '10px', 
                                      backgroundColor: '#8c8c8c',
                                      opacity: 0.5,
                                      borderRadius: '4px 4px 0 0',
                                    }}
                                  />
                                );
                              })}
                            </div>
                            <Text style={{ marginTop: 8, fontSize: 12 }}>{platform.name}</Text>
                            <Text style={{ fontSize: 10 }}>{platform.marketShare}% Share</Text>
                          </div>
                        );
                      })}
                    </div>
                    
                    {/* Recommended price line */}
                    {showRecommendedPrices && (
                      <div 
                        style={{ 
                          position: 'absolute', 
                          left: 0, 
                          right: 0, 
                          top: `${100 - ((parseFloat(productData.recommendedPrice.replace('$', '')) / 30) * 100)}%`, 
                          borderTop: '2px dashed #52c41a',
                          zIndex: 5
                        }}
                      >
                        <div style={{ position: 'absolute', right: 10, top: -10, background: 'white', padding: '0 4px', borderRadius: 2 }}>
                          <Text type="success" strong>Recommended: {productData.recommendedPrice}</Text>
                        </div>
                      </div>
                    )}
                    
                    {/* Legend */}
                    <div style={{ position: 'absolute', top: 10, left: 10, background: 'rgba(255,255,255,0.8)', padding: '5px 10px', borderRadius: 4 }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                        <div style={{ width: 12, height: 12, backgroundColor: '#52c41a', marginRight: 5, borderRadius: 2 }} />
                        <Text style={{ fontSize: 12 }}>Follows Recommended</Text>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 12, height: 12, backgroundColor: '#f5222d', marginRight: 5, borderRadius: 2 }} />
                        <Text style={{ fontSize: 12 }}>Above Recommended</Text>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </Col>
          
          {selectedPlatformDetail ? (
            <Col xs={24} md={12}>
              <Card 
                title={
                  <Space>
                    <ShoppingOutlined />
                    <span>{selectedPlatformDetail.name} Platform Details</span>
                  </Space>
                }
                size="small" 
                className="glass-effect"
                style={{ height: '100%' }}
              >
                <div style={{ padding: '16px' }}>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Statistic
                        title="Your Price"
                        value={selectedPlatformDetail.price}
                        valueStyle={{ color: '#1890ff' }}
                        prefix={<DollarOutlined />}
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title="Market Share"
                        value={`${selectedPlatformDetail.marketShare}%`}
                        valueStyle={{ color: '#722ed1' }}
                        prefix={<PieChartOutlined />}
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title="Buy Box Win Rate"
                        value={selectedPlatformDetail.buyBoxWinRate}
                        valueStyle={{ color: '#52c41a' }}
                        prefix={<TrophyOutlined />}
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title="Rating"
                        value={selectedPlatformDetail.rating}
                        valueStyle={{ color: '#faad14' }}
                        prefix={<StarOutlined />}
                        suffix={`(${selectedPlatformDetail.reviewCount})`}
                      />
                    </Col>
                  </Row>
                  
                  <Divider />
                  
                  <Title level={5}>Competitor Analysis</Title>
                  <Table 
                    dataSource={selectedPlatformDetail.competitorPrices}
                    columns={[
                      { title: 'Competitor', dataIndex: 'name', key: 'name' },
                      { title: 'Price', dataIndex: 'price', key: 'price' },
                      { 
                        title: 'Difference', 
                        key: 'difference',
                        render: (_, record) => renderPriceDiff(
                          ((parseFloat(record.price.replace('$', '')) - 
                            parseFloat(selectedPlatformDetail.price.replace('$', ''))) / 
                            parseFloat(selectedPlatformDetail.price.replace('$', ''))) * 100
                        )
                      }
                    ]}
                    pagination={false}
                    size="small"
                  />
                  
                  <Divider />
                  
                  <Title level={5}>Platform-Specific Recommendations</Title>
                  <List
                    size="small"
                    dataSource={[
                      { 
                        title: 'Price Adjustment', 
                        recommendation: selectedPlatformDetail.followsRecommended ? 
                          'Maintain current price point' : 
                          `Consider adjusting price to ${productData.recommendedPrice} to optimize performance` 
                      },
                      { 
                        title: 'Competitive Position', 
                        recommendation: `Your price is ${Math.abs(selectedPlatformDetail.difference)}% ${selectedPlatformDetail.difference < 0 ? 'below' : 'above'} the market average on this platform` 
                      },
                      { 
                        title: 'Sales Opportunity', 
                        recommendation: selectedPlatformDetail.salesVolume === 'High' ? 
                          'Strong sales performance, consider slight price increase' : 
                          'Opportunity to increase sales volume with targeted promotions' 
                      }
                    ]}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.title}
                          description={item.recommendation}
                        />
                      </List.Item>
                    )}
                  />
                  
                  <div style={{ marginTop: 16, textAlign: 'center' }}>
                    <Button type="primary">Apply Platform-Specific Price</Button>
                  </div>
                </div>
              </Card>
            </Col>
          ) : (
            <Col xs={24} md={12}>
              <Card 
                title={
                  <Space>
                    <AimOutlined />
                    <span>Price Recommendation Analysis</span>
                  </Space>
                }
                size="small" 
                className="glass-effect"
                style={{ height: '100%' }}
              >
                <Tabs defaultActiveKey="1" onChange={setPriceOptimizationView}>
                  <TabPane tab="Price Scenarios" key="scenarios">
                    <div style={{ padding: '16px' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                        <Statistic
                          title="Your Current Price"
                          value={productData.yourPrice}
                          valueStyle={{ color: '#1890ff' }}
                          prefix={<DollarOutlined />}
                        />
                        
                        <Statistic
                          title="Recommended Price"
                          value={productData.recommendedPrice}
                          valueStyle={{ color: '#52c41a' }}
                          prefix={<AimOutlined />}
                        />
                      </div>
                      
                      <Table 
                        dataSource={productData.priceOptimizationScenarios}
                        columns={[
                          { 
                            title: 'Scenario', 
                            dataIndex: 'scenario', 
                            key: 'scenario',
                            render: (text) => <Text strong>{text}</Text>
                          },
                          { title: 'Price', dataIndex: 'price', key: 'price' },
                          { 
                            title: 'Volume Change', 
                            dataIndex: 'volumeChange', 
                            key: 'volumeChange',
                            render: (text) => {
                              const value = parseFloat(text.replace('%', ''));
                              return (
                                <Text type={value >= 0 ? 'success' : 'danger'}>
                                  {value >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />} {text}
                                </Text>
                              );
                            }
                          },
                          { 
                            title: 'Revenue Impact', 
                            dataIndex: 'revenueChange', 
                            key: 'revenueChange',
                            render: (text) => {
                              const value = parseFloat(text.replace('%', '').replace('+', ''));
                              return (
                                <Text type={value >= 0 ? 'success' : 'danger'}>
                                  {value >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />} {text}
                                </Text>
                              );
                            }
                          },
                          { 
                            title: 'Profit Impact', 
                            dataIndex: 'profitChange', 
                            key: 'profitChange',
                            render: (text) => {
                              const value = parseFloat(text.replace('%', '').replace('+', ''));
                              return (
                                <Text type={value >= 0 ? 'success' : 'danger'}>
                                  {value >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />} {text}
                                </Text>
                              );
                            }
                          },
                        ]}
                        pagination={false}
                        size="small"
                      />
                      
                      <div style={{ marginTop: 16, textAlign: 'center' }}>
                        <Button type="primary">Apply Recommended Price</Button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Recommendation Factors" key="factors">
                    <div style={{ padding: '16px' }}>
                      <List
                        itemLayout="horizontal"
                        dataSource={[
                          { 
                            title: 'Competition Factor', 
                            description: productData.recommendationRationale.competitionFactor,
                            icon: <GlobalOutlined style={{ color: '#1890ff' }} />
                          },
                          { 
                            title: 'Demand Factor', 
                            description: productData.recommendationRationale.demandFactor,
                            icon: <RiseOutlined style={{ color: '#722ed1' }} />
                          },
                          { 
                            title: 'Inventory Factor', 
                            description: productData.recommendationRationale.inventoryFactor,
                            icon: <ShoppingCartOutlined style={{ color: '#faad14' }} />
                          },
                          { 
                            title: 'Profitability Factor', 
                            description: productData.recommendationRationale.profitabilityFactor,
                            icon: <DollarOutlined style={{ color: '#52c41a' }} />
                          }
                        ]}
                        renderItem={item => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={<Avatar icon={item.icon} />}
                              title={item.title}
                              description={item.description}
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="Price History" key="history">
                    <div style={{ padding: '16px' }}>
                      <div style={{ position: 'relative', height: 200, background: 'rgba(24, 144, 255, 0.05)', borderRadius: 8, padding: 16, marginBottom: 16 }}>
                        {/* Simple line chart for price history */}
                        <div style={{ display: 'flex', height: '100%', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                          {productData.priceHistory.map((point, index) => {
                            const height = (point.price / 30) * 100; // Scale to percentage
                            
                            return (
                              <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '14%' }}>
                                <div style={{ position: 'relative' }}>
                                  <div 
                                    style={{ 
                                      height: `${height}%`, 
                                      width: '4px', 
                                      backgroundColor: '#1890ff',
                                      borderRadius: '4px 4px 0 0',
                                      position: 'absolute',
                                      bottom: 0,
                                      left: '50%',
                                      transform: 'translateX(-50%)'
                                    }}
                                  />
                                  <div 
                                    style={{ 
                                      position: 'absolute',
                                      bottom: `${height}%`,
                                      left: '50%',
                                      transform: 'translate(-50%, -100%)',
                                      fontSize: 10,
                                      color: '#1890ff',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    ${point.price}
                                  </div>
                                </div>
                                <Text style={{ marginTop: 8, fontSize: 10 }}>{point.date}</Text>
                              </div>
                            );
                          })}
                        </div>
                        
                        {/* Recommended price line */}
                        {showRecommendedPrices && (
                          <div 
                            style={{ 
                              position: 'absolute', 
                              left: 0, 
                              right: 0, 
                              top: `${100 - ((parseFloat(productData.recommendedPrice.replace('$', '')) / 30) * 100)}%`, 
                              borderTop: '2px dashed #52c41a',
                              zIndex: 5
                            }}
                          >
                            <div style={{ position: 'absolute', right: 10, top: -10, background: 'white', padding: '0 4px', borderRadius: 2 }}>
                              <Text type="success" style={{ fontSize: 10 }}>Recommended: {productData.recommendedPrice}</Text>
                            </div>
                          </div>
                        )}
                      </div>
                      
                      <Paragraph>
                        <Text type="secondary">
                          Price history shows a {
                            productData.priceHistory[0].price > productData.priceHistory[productData.priceHistory.length - 1].price ? 
                            <Text type="danger">downward trend</Text> : 
                            productData.priceHistory[0].price < productData.priceHistory[productData.priceHistory.length - 1].price ?
                            <Text type="success">upward trend</Text> :
                            <Text>stable pattern</Text>
                          } over the past 6 months. The recommended price of {productData.recommendedPrice} is based on this historical data and current market conditions.
                        </Text>
                      </Paragraph>
                    </div>
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    );
  };

  const priceCompetitivenessColumns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (text) => <a href="#product-details">{text}</a>,
    },
    {
      title: 'Your Price',
      dataIndex: 'yourPrice',
      key: 'yourPrice',
      render: (price) => <Text strong>{price}</Text>,
    },
    {
      title: 'Avg. Market Price',
      dataIndex: 'avgMarketPrice',
      key: 'avgMarketPrice',
    },
    {
      title: 'Price Difference',
      dataIndex: 'priceDiff',
      key: 'priceDiff',
      sorter: (a, b) => a.priceDiff - b.priceDiff,
      render: renderPriceDiff,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Optimal', value: 'optimal' },
        { text: 'Competitive', value: 'competitive' },
        { text: 'High', value: 'high' },
        { text: 'Too Low', value: 'low' },
      ],
      onFilter: (value, record) => record.status === value,
      render: renderPriceStatusTag,
    },
    {
      title: 'Action',
      key: 'action',
      render: () => (
        <Space>
          <Button type="link" size="small">Adjust</Button>
          <Button type="link" size="small">Details</Button>
        </Space>
      ),
    },
  ];

  // Filter alerts based on selected filter
  const filteredAlerts = alertFilter === 'all' 
    ? priceAlerts 
    : priceAlerts.filter(alert => alert.severity === alertFilter);

  console.log('Filtered alerts count:', filteredAlerts.length);

  /* eslint-disable-next-line no-unused-vars */
  const handleAlertFilterChange = (value) => {
    setAlertFilter(value);
    // Implement filtering logic based on alert severity
  };

  /* eslint-disable-next-line no-unused-vars */
  const handleDisplayModeChange = (mode) => {
    setDisplayMode(mode);
    // Switch between card view and table view
  };

  /* eslint-disable-next-line no-unused-vars */
  const handlePriceOptimizationViewChange = (view) => {
    setPriceOptimizationView(view);
    // Switch between different price optimization views
  };

  // To avoid warnings about unused setters, add a useEffect:
  useEffect(() => {
    // This demonstrates the usage of these state variables in the component lifecycle
    if (process.env.NODE_ENV === 'development') {
      // Only log in development mode
      console.log(`PriceAnalyticsView initialized with:
        Alert filter: ${alertFilter}
        Display mode: ${displayMode}
        Price optimization view: ${priceOptimizationView}
      `);
    }
  }, [alertFilter, displayMode, priceOptimizationView]);

  return (
    <div className="price-analytics-view glass-effect">
      {/* Header with Filters */}
      <Row gutter={[16, 16]} className="analytics-header" style={{ 
        background: 'linear-gradient(135deg, #52c41a 0%, #389e0d 100%)', 
        padding: '20px', 
        borderRadius: '8px 8px 0 0', 
        marginTop: -20, 
        marginLeft: -20, 
        marginRight: -20,
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
      }}>
        <Col xs={24} md={16}>
          <Title level={4} style={{ color: '#ffffff', margin: 0, textShadow: '0 1px 2px rgba(0,0,0,0.1)' }}>Price Analytics</Title>
          <Paragraph style={{ color: '#ffffff', opacity: 0.9, margin: '4px 0 0 0' }}>
            Monitor price competitiveness and optimize your pricing strategy
          </Paragraph>
        </Col>
        <Col xs={24} md={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Space>
            <Select 
              value={timeRange} 
              onChange={handleTimeRangeChange}
              style={{ width: 120, background: 'white', borderRadius: 4 }}
            >
              <Option value="day">Last 24 Hours</Option>
              <Option value="week">Last Week</Option>
              <Option value="month">Last Month</Option>
              <Option value="quarter">Last Quarter</Option>
              <Option value="custom">Custom Range</Option>
            </Select>
            
            {timeRange === 'custom' && (
              <RangePicker 
                value={dateRange} 
                onChange={handleDateRangeChange}
                style={{ background: 'white', borderRadius: 4 }}
                getPopupContainer={trigger => trigger.parentNode}
                popupStyle={{ zIndex: 1050 }}
                disabledDate={current => current && current > moment().endOf('day')}
              />
            )}
            
            <Button 
              type="primary" 
              icon={<ReloadOutlined />} 
              onClick={handleRefresh}
              loading={loading}
              style={{ background: 'rgba(255,255,255,0.2)', borderColor: 'rgba(255,255,255,0.3)' }}
            >
              Refresh
            </Button>
          </Space>
        </Col>
      </Row>

      <Divider style={{ margin: '12px 0' }} />

      {/* Date Range Display */}
      <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Space>
          <Text style={{ fontSize: 14 }}>
            <CalendarOutlined style={{ marginRight: 8 }} />
            Showing data for: <Text strong>{dateRange[0].format('MMM DD, YYYY')} - {dateRange[1].format('MMM DD, YYYY')}</Text>
          </Text>
          
          <Select
            placeholder="Select Product"
            style={{ width: 200, marginLeft: 16 }}
            onChange={handleProductChange}
            value={selectedProduct}
          >
            <Option value="all">All Products</Option>
            {priceCompetitiveness.map(item => (
              <Option key={item.id} value={item.id}>{item.product}</Option>
            ))}
          </Select>
        </Space>
        
        <Text type="secondary" style={{ fontSize: 12 }}>Last updated: {moment().format('MMM DD, YYYY HH:mm')}</Text>
      </div>

      {/* Price Metrics Section */}
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={6}>
          <Card className="metric-card" loading={loading} hoverable style={{ height: '100%', boxShadow: '0 2px 8px rgba(0,0,0,0.09)', borderRadius: 8 }}>
            <Statistic
              title={
                <Space>
                  <span>Price Competitiveness</span>
                  <Tooltip title="How competitive your prices are compared to market average">
                    <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                  </Tooltip>
                </Space>
              }
              value={92}
              prefix={<DollarOutlined />}
              suffix="%"
              valueStyle={{ color: '#52c41a' }}
            />
            <Progress percent={92} showInfo={false} strokeColor="#52c41a" />
            <div className="metric-footer" style={{ marginTop: 12 }}>
              <Text type="success">
                <ArrowUpOutlined /> 3% increase from last period
              </Text>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card className="metric-card" loading={loading} hoverable style={{ height: '100%', boxShadow: '0 2px 8px rgba(0,0,0,0.09)', borderRadius: 8 }}>
            <Statistic
              title={
                <Space>
                  <span>Avg. Price Difference</span>
                  <Tooltip title="Average difference between your prices and market average">
                    <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                  </Tooltip>
                </Space>
              }
              value={-5.7}
              prefix={<TrendingDown size={20} />}
              suffix="%"
              valueStyle={{ color: '#1890ff' }}
            />
            <Progress percent={57} showInfo={false} strokeColor="#1890ff" />
            <div className="metric-footer" style={{ marginTop: 12 }}>
              <Text type="secondary">
                <ArrowDownOutlined /> 0.8% decrease from last period
              </Text>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card className="metric-card" loading={loading} hoverable style={{ height: '100%', boxShadow: '0 2px 8px rgba(0,0,0,0.09)', borderRadius: 8 }}>
            <Statistic
              title={
                <Space>
                  <span>Optimal Pricing</span>
                  <Tooltip title="Percentage of products with optimal pricing">
                    <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                  </Tooltip>
                </Space>
              }
              value={60}
              prefix={<CheckCircleOutlined />}
              suffix="%"
              valueStyle={{ color: '#52c41a' }}
            />
            <Progress percent={60} showInfo={false} strokeColor="#52c41a" />
            <div className="metric-footer" style={{ marginTop: 12 }}>
              <Text type="success">
                <ArrowUpOutlined /> 5% increase from last period
              </Text>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card className="metric-card" loading={loading} hoverable style={{ height: '100%', boxShadow: '0 2px 8px rgba(0,0,0,0.09)', borderRadius: 8 }}>
            <Statistic
              title={
                <Space>
                  <span>Price Alerts</span>
                  <Tooltip title="Number of active price alerts">
                    <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
                  </Tooltip>
                </Space>
              }
              value={3}
              prefix={<AlertTriangle size={20} />}
              valueStyle={{ color: '#faad14' }}
            />
            <Progress percent={30} showInfo={false} strokeColor="#faad14" />
            <div className="metric-footer" style={{ marginTop: 12 }}>
              <Text type="warning">
                <ArrowUpOutlined /> 1 new alert since yesterday
              </Text>
            </div>
          </Card>
        </Col>
      </Row>

      {/* Price History Chart */}
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24}>
          <Card 
            title={
              <Space>
                <LineChartOutlined className="card-icon" style={{ color: '#52c41a' }} />
                <span>Price History Trends</span>
              </Space>
            }
            extra={
              <Space>
                <Radio.Group 
                  defaultValue="line" 
                  size="small" 
                  buttonStyle="solid"
                  onChange={(e) => setChartType(e.target.value)}
                  value={chartType}
                >
                  <Radio.Button value="line"><LineChartOutlined /></Radio.Button>
                  <Radio.Button value="bar"><BarChartOutlined /></Radio.Button>
                </Radio.Group>
                <Button type="text" icon={<DownloadOutlined />}>Export</Button>
              </Space>
            }
            className="chart-card glass-effect"
            loading={loading}
            style={{ boxShadow: '0 2px 8px rgba(0,0,0,0.09)' }}
          >
            <div className="chart-content" ref={chartContainerRef}>
              {renderPriceHistoryChart()}
            </div>
          </Card>
        </Col>
      </Row>

      {/* Price Competitiveness Table */}
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24}>
          <Card 
            title={
              <Space>
                <DollarOutlined className="card-icon" style={{ color: '#52c41a' }} />
                <span>Price Competitiveness Analysis</span>
              </Space>
            }
            extra={
              <Space>
                <Input 
                  placeholder="Search products" 
                  prefix={<SearchOutlined />} 
                  style={{ width: 200 }}
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
                <Button type="primary" size="small" icon={<FilterOutlined />}>
                  Filter
                </Button>
              </Space>
            }
            className="table-card glass-effect"
            loading={loading}
          >
            <Table 
              dataSource={priceCompetitiveness}
              columns={priceCompetitivenessColumns}
            />
          </Card>
        </Col>
      </Row>

      {/* Platform Comparison Chart */}
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24}>
          <Card 
            title={
              <Space>
                <GlobalOutlined className="card-icon" style={{ color: '#52c41a' }} />
                <span>Platform Comparison</span>
              </Space>
            }
            className="glass-effect"
            style={{ height: '100%' }}
          >
            {renderPlatformComparisonChart()}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PriceAnalyticsView; 