import React from 'react';
import { Card, Row, Col, Space, Statistic } from 'antd';
import { 
  PieChartOutlined, BarChartOutlined,
  LineChartOutlined, TableOutlined,
  InboxOutlined
} from '@ant-design/icons';

const InventoryView = () => {
  return (
    <div className="inventory-view">
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <Card 
            title={
              <Space>
                <PieChartOutlined className="card-icon" />
                <span>Stock Level Summary</span>
              </Space>
            } 
            className="chart-card glass-effect"
          >
            <div className="chart-content">
              <div className="chart-placeholder">
                <PieChartOutlined /> Stock Level Chart
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card 
            title={
              <Space>
                <BarChartOutlined className="card-icon" />
                <span>Inventory Turnover Rate</span>
              </Space>
            } 
            className="chart-card glass-effect"
          >
            <div className="chart-content">
              <div className="chart-placeholder">
                <BarChartOutlined /> Turnover Rate Chart
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      
      <Row gutter={[16, 16]} className="mt-4">
        <Col xs={24}>
          <Card 
            title={
              <Space>
                <LineChartOutlined className="card-icon" />
                <span>Stock Prediction</span>
              </Space>
            } 
            className="chart-card glass-effect"
          >
            <div className="chart-content">
              <div className="chart-placeholder">
                <LineChartOutlined /> Stock Forecast Chart
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      
      <Row gutter={[16, 16]} className="mt-4">
        <Col xs={24}>
          <Card 
            title={
              <Space>
                <TableOutlined className="card-icon" />
                <span>Inventory Alerts & Actions</span>
              </Space>
            } 
            className="table-card glass-effect"
          >
            <div className="table-content">
              <div className="table-placeholder">
                <TableOutlined /> Inventory Alerts Table
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mt-4">
        <Col span={24}>
          <Card title="Inventory Analysis">
            <Statistic
              title="Total Inventory"
              value={1234}
              prefix={<InboxOutlined />}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default InventoryView; 