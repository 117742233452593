import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { 
    fetchDataFromAPI, 
    fetchPodata, 
    approveOrders, 
    editOrderItem, 
    fetchPlatforms, 
    generateContract_po,
    updateOrderStatus,
    updateManualRemarks,
    processDataByPlatform,
    fetchPurchaseOrdersCount
} from '../../routes/AppRoutes';
// eslint-disable-next-line no-unused-vars
import * as XLSX from 'xlsx';
import { ChevronDown, ChevronRight, RefreshCw, Check, Download, X, Search } from 'react-feather';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './PoTable.css';
import {AlertCard } from '../Assets/Dialog';
import debounce from 'lodash/debounce';
import { getUserData } from '../../utils/cookieUtils';

// Export the Modal component separately
export const Modal = ({ show, onClose, children }) => {
  if (!show) return null;
  return (
    <div className="modal-overlay" style={{ zIndex: 9999 }}>
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title">Edit Item Details</h2>
          <button onClick={onClose} className="modal-close">
            <X size={24} />
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-scroll-container">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

// Define ExportDataModal before using it in PoTable
const ExportDataModal = ({ show, onClose, selectedPlatform, handleExportSubmit, isLoading }) => {
  const [localStartDate, setLocalStartDate] = useState(null);
  const [localEndDate, setLocalEndDate] = useState(null);

  useEffect(() => {
    if (show) {
      setLocalStartDate(null);
      setLocalEndDate(null);
    }
  }, [show]);

  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content export-modal">
        <div className="modal-header">
          <h2 className="modal-title">Export {selectedPlatform} Data</h2>
          <button onClick={onClose} className="modal-close">
            <X size={24} />
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group mb-4">
            <label className="form-label">From Date</label>
            <div className="date-picker-container">
              <DatePicker
                selected={localStartDate}
                onChange={date => setLocalStartDate(date)}
                placeholderText="Select start date"
                className="form-control"
                dateFormat="dd/MM/yyyy"
                isClearable={true}
                popperProps={{
                  strategy: "fixed"
                }}
                popperPlacement="bottom-start"
                popperClassName="date-picker-popper"
                wrapperClassName="date-picker-wrapper"
              />
            </div>
          </div>
          <div className="form-group mb-4">
            <label className="form-label">To Date</label>
            <div className="date-picker-container">
              <DatePicker
                selected={localEndDate}
                onChange={date => setLocalEndDate(date)}
                placeholderText="Select end date"
                className="form-control"
                dateFormat="dd/MM/yyyy"
                isClearable={true}
                minDate={localStartDate}
                popperProps={{
                  strategy: "fixed"
                }}
                popperPlacement="bottom-start"
                popperClassName="date-picker-popper"
                wrapperClassName="date-picker-wrapper"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-secondary" onClick={onClose}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleExportSubmit(localStartDate, localEndDate);
              onClose();
            }}
            disabled={!localStartDate || !localEndDate}
          >
            {isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" />
                Exporting...
              </>
            ) : (
              'Export'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const PoTable = ({ platform }) => {
  // First, declare all state variables
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [platforms, setPlatforms] = useState([]);
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [columns, setColumns] = useState([]);
  const [activeToggle, setActiveToggle] = useState(null);
  const [toppledData, setToppledData] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [statusFilter, setStatusFilter] = useState('');
  const [poNumberSearch, setPoNumberSearch] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [platformLoadingStates, setPlatformLoadingStates] = useState({});
  const [alert, setAlert] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [displayedOrders, setDisplayedOrders] = useState([]);
  const previousPlatformRef = useRef(platform);

  // Add new ref and state for scroll handling
  const scrollableRef = useRef(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  // Update the debounced search function with a shorter delay
  const debouncedPoNumberSearch = useMemo(
    () => ({
      search: debounce((value, callback) => {
        callback(value);
      }, 150) // Reduced from 300ms to 150ms for faster response
    }),
    []
  );

  // Add this new state for selected items
  const [selectedItems, setSelectedItems] = useState({});

  // Add this new state for team remarks
  const [teamRemarks, setTeamRemarks] = useState({});
  const [isSubmitting] = useState(false);

  // Add new state for process data modal
  const [showProcessModal, setShowProcessModal] = useState(false);

  // Add pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);

  // First, add a new state for the export modal
  const [showExportModal, setShowExportModal] = useState(false);

  // Define resetTableState before using it in other functions
  const resetTableState = useCallback(() => {
    setActiveToggle(null);
    setToppledData({});
    setSelectAll(false);
    setStatusFilter('');
    setPoNumberSearch('');
    setStartDate(null);
    setEndDate(null);
    setColumns([]);
    setAllOrders([]);
    setOrders([]);
  }, []);

  // Update the loadOrders function to display all records at once
  const loadOrders = useCallback(async (platformToLoad) => {
    if (!platformToLoad) return;
    
    setIsLoading(true);
    
    try {
        resetTableState();
        
        const response = await fetchDataFromAPI(platformToLoad);
        
        if (!response || !response.data) {
            throw new Error('Invalid response format');
        }

        let orderData = Array.isArray(response.data) ? response.data : [response.data];
        
        if (orderData.length === 0) {
            setAlert({
                type: 'info',
                message: 'No orders found for this platform.',
                title: 'No Data'
            });
            return;
        }

        const validatedOrders = orderData
            .filter(order => order && typeof order === 'object')
            .map(order => ({
                ...order,
                selected: false,
                STATUS: order.STATUS || 'Pending',
                'PO Date': order['PO Date'] ? formatDate(order['PO Date']) : null
            }));

        if (validatedOrders.length > 0) {
            const validColumns = Object.keys(validatedOrders[0])
                .filter(key => key !== 'selected');
            setColumns(validColumns);
        }

        setAllOrders(validatedOrders);
        setOrders(validatedOrders);
        setDisplayedOrders(validatedOrders);

    } catch (error) {
        setAlert({
            type: 'error',
            message: 'Failed to load orders. Please try again.',
            title: 'Error'
        });
    } finally {
        setIsLoading(false);
    }
}, [resetTableState, setAlert]);

  // Platform change handler
  const handlePlatformChange = async (newPlatform) => {
    if (newPlatform === selectedPlatform || isLoading) return;

    try {
      setPlatformLoadingStates(prev => ({
        ...prev,
        [newPlatform]: true
      }));
      setIsLoading(true);

      previousPlatformRef.current = selectedPlatform;
      setSelectedPlatform(newPlatform);
      
      // Reset current page to 1 when changing platforms
      setCurrentPage(1);
      
      resetTableState();
      await loadOrders(newPlatform);

    } catch (error) {
      console.error('Error switching platform:', error);
      setSelectedPlatform(previousPlatformRef.current);
      
      setAlert({
        type: 'error',
        message: 'Failed to load platform data. Please try again.',
        title: 'Error'
      });
    } finally {
      setPlatformLoadingStates(prev => ({
        ...prev,
        [newPlatform]: false
      }));
      setIsLoading(false);
    }
  };

  // Cleanup effect
  useEffect(() => {
    return () => {
      resetTableState();
    };
  }, [resetTableState]);

  // Platform initialization effect
  useEffect(() => {
    const loadPlatforms = async () => {
      try {
        const response = await fetchPlatforms();
        if (response.success && Array.isArray(response.data)) {
          const platformNames = response.data.map(p => p.platform_name);
          setPlatforms(platformNames);
          
          if (!selectedPlatform && platformNames.length > 0) {
            const initialPlatform = platform || platformNames[0];
            setSelectedPlatform(initialPlatform);
          }
        }
      } catch (error) {
        console.error('Error loading platforms:', error);
        setAlert({
          type: 'error',
          message: 'Failed to load platforms. Please refresh the page.',
          title: 'Error'
        });
      }
    };

    loadPlatforms();
  }, [platform, selectedPlatform]);

  // Platform tabs renderer - restored to original UI
  const renderPlatformTabs = () => (
    <div className="platform-tabs">
      {platforms.map((platformName) => (
        <button
          key={platformName}
          className={`platform-tab ${selectedPlatform === platformName ? 'active' : ''}`}
          onClick={() => handlePlatformChange(platformName)}
          disabled={isLoading || platformLoadingStates[platformName]}
        >
          {platformLoadingStates[platformName] ? (
            <div className="button-content">
              <span className="loading-spinner" />
              <span>{platformName}</span>
            </div>
          ) : (
            <div className="button-content">
              <span>{platformName}</span>
            </div>
          )}
        </button>
      ))}
    </div>
  );

  const renderStatus = (status) => {
    if (!status) {
      return <span className="status-badge status-unknown">Unknown</span>;
    }
    return <span className={`status-badge status-${status.toLowerCase()}`}>{status}</span>;
  };

  // Update applyFilters function to show all filtered results
  const applyFilters = useCallback((ordersToFilter) => {
    if (!ordersToFilter) return;
    
    let filteredOrders = [...ordersToFilter];

    if (statusFilter) {
        filteredOrders = filteredOrders.filter(order => 
            order.STATUS?.toLowerCase() === statusFilter.toLowerCase()
        );
    }

    if (poNumberSearch) {
        const searchTerm = poNumberSearch.toLowerCase();
        filteredOrders = filteredOrders.filter(order => {
            return Object.entries(order).some(([key, value]) => {
                if (value === null || value === undefined) return false;
                return String(value).toLowerCase().includes(searchTerm);
            });
        });
    }

    if (startDate && endDate) {
        filteredOrders = filteredOrders.filter(order => {
            if (!order['PO Date']) return false;
            
            try {
                // Parse the date from DD/MM/YYYY format
                const [day, month, year] = order['PO Date'].split('/').map(Number);
                const orderDate = new Date(year, month - 1, day);
                
                // Create comparison dates at the start and end of the selected days
                const compareStart = new Date(startDate);
                compareStart.setHours(0, 0, 0, 0);
                
                const compareEnd = new Date(endDate);
                compareEnd.setHours(23, 59, 59, 999);

                return orderDate >= compareStart && orderDate <= compareEnd;
            } catch (error) {
                setAlert({
                    type: 'error',
                    message: `Error filtering date: ${error.message}`,
                    title: 'Date Filter Error'
                });
                return false;
            }
        });
    }

    setOrders(filteredOrders);
    setDisplayedOrders(filteredOrders);
}, [statusFilter, poNumberSearch, startDate, endDate, setAlert]);

  useEffect(() => {
    loadOrders(selectedPlatform);
  }, [loadOrders, selectedPlatform]);

  useEffect(() => {
    applyFilters(allOrders);
  }, [applyFilters, allOrders, statusFilter, poNumberSearch, startDate, endDate]);

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  // Update the search input handler
  const handlePoNumberSearchChange = (e) => {
    const value = e.target.value;
    // Update the input value immediately for UI responsiveness
    setPoNumberSearch(value);
    
    // Use the debounced function for filtering
    debouncedPoNumberSearch.search(value, (searchValue) => {
      const filteredOrders = allOrders.filter(order => {
        return Object.entries(order).some(([key, val]) => {
          if (val === null || val === undefined) return false;
          return String(val).toLowerCase().includes(searchValue.toLowerCase());
        });
      });
      setOrders(filteredOrders);
      setDisplayedOrders(filteredOrders);
    });
  };

  const handleDateChange = (update) => {
    try {
        const [start, end] = update;
        
        // If clearing the date range
        if (!start && !end) {
            setStartDate(null);
            setEndDate(null);
            return;
        }

        // Validate date range
        if (start && end && start > end) {
            setAlert({
                type: 'warning',
                message: 'Start date cannot be after end date',
                title: 'Invalid Date Range'
            });
            return;
        }

        // Set the dates
        setStartDate(start);
        setEndDate(end);
        
    } catch (error) {
        console.error('Error handling date change:', error);
        setAlert({
            type: 'error',
            message: 'Error processing date range',
            title: 'Date Error'
        });
    }
  };

  const toggleRow = async (poNumber) => {
    if (activeToggle === poNumber) {
        setActiveToggle(null);
    } else {
        setActiveToggle(poNumber);
        try {
            if (!toppledData[poNumber]) {
                const response = await fetchPodata(poNumber);
                
                let validData = [];
                if (response?.data) {
                    validData = Array.isArray(response.data) ? response.data.flat() : [response.data];
                    setToppledData(prevState => ({
                        ...prevState,
                        [poNumber]: validData
                    }));
                }
            }
        } catch (error) {
            setAlert({
                type: 'error',
                message: 'Failed to load order details. Please try again.',
                title: 'Error'
            });
        }
    }
  };

  const toggleSelectAll = () => {
    const newState = !selectAll;
    setSelectAll(newState);
    
    // Update both the full orders list and displayed orders
    setOrders(prevOrders => 
      prevOrders.map(order => ({ ...order, selected: newState }))
    );
    
    setDisplayedOrders(prevDisplayed => 
      prevDisplayed.map(order => ({ ...order, selected: newState }))
    );
    
    // Also update allOrders to maintain consistency
    setAllOrders(prevAll => 
      prevAll.map(order => ({ ...order, selected: newState }))
    );
  };

  const handleCheckboxChange = (index) => {
    if (typeof index !== 'number' || index < 0 || index >= displayedOrders.length) {
      console.error('Invalid index for checkbox change');
      return;
    }

    const updatedDisplayed = [...displayedOrders];
    updatedDisplayed[index] = {
      ...updatedDisplayed[index],
      selected: !updatedDisplayed[index].selected
    };
    setDisplayedOrders(updatedDisplayed);

    // Update the main orders array as well
    const poNumber = displayedOrders[index].PoNumber;
    setOrders(prevOrders =>
      prevOrders.map(order =>
        order.PoNumber === poNumber
          ? { ...order, selected: !order.selected }
          : order
      )
    );
    
    // Update allOrders to maintain consistency
    setAllOrders(prevAll =>
      prevAll.map(order =>
        order.PoNumber === poNumber
          ? { ...order, selected: !order.selected }
          : order
      )
    );

    // Update selectAll state based on all displayed orders
    const allSelected = updatedDisplayed.every(order => order.selected);
    setSelectAll(allSelected);
  };

  // Update the exportToExcel function to simply show the modal
  const exportToExcel = () => {
    setShowExportModal(true);
  };

  // Update the handleExportSubmit function
  const handleExportSubmit = async (fromDate, toDate) => {
    if (!fromDate || !toDate) {
      setAlert({
        type: 'warning',
        message: 'Please select both From Date and To Date',
        title: 'Missing Dates'
      });
      return;
    }

    try {
      setIsLoading(true);

      // Format dates for API (YYYY-MM-DD)
      const formatDateForAPI = (date) => {
        return date.toISOString().split('T')[0];
      };

      // Call API with date range parameters
      const response = await fetchDataFromAPI(
        selectedPlatform,
        1,  // page
        9999, // large page size to get all data
        formatDateForAPI(fromDate),
        formatDateForAPI(toDate)
      );

      if (!response?.data || response.data.length === 0) {
        throw new Error('No data found for the selected date range');
      }

      const orderData = Array.isArray(response.data) ? response.data : [response.data];

      // Process PO Headers - use the actual API response data
      const poHeaders = orderData.map((order, index) => ({
        'Sr. No.': index + 1,
        ...order
      }));

      // Create PO Headers worksheet
      const poWorksheet = XLSX.utils.json_to_sheet(poHeaders);

      // Get and process PO Items
      const allItems = [];
      
      // Process items sequentially with proper indexing
      for (let orderIndex = 0; orderIndex < orderData.length; orderIndex++) {
        const order = orderData[orderIndex];
        try {
          const itemResponse = await fetchPodata(order.PoNumber);
          if (itemResponse?.data) {
            const items = Array.isArray(itemResponse.data) ? itemResponse.data : [itemResponse.data];
            
            // Create a safe copy of items with proper indexing
            const processedItems = items.map((item, itemIndex) => ({
              'Sr. No.': (orderIndex * 1000) + itemIndex + 1, // Use orderIndex to ensure unique numbers
              'PO Number': order.PoNumber,
              ...item
            }));
            
            allItems.push(...processedItems);
          }
        } catch (error) {
          console.error(`Error fetching items for PO ${order.PoNumber}:`, error);
        }
      }

      // Create Items worksheet
      const itemsWorksheet = XLSX.utils.json_to_sheet(allItems);

      // Create workbook with both sheets
      const workbook = {
        Sheets: {
          'PO_Headers': poWorksheet,
          'PO_Items': itemsWorksheet
        },
        SheetNames: ['PO_Headers', 'PO_Items']
      };

      // Generate Excel file
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      
      // Save file with platform name and date range
      const fileName = `${selectedPlatform}_PO_Data.xlsx`;
      saveExcelFile(excelBuffer, fileName, fromDate, toDate);

      setAlert({
        type: 'success',
        message: `Successfully exported ${orderData.length} orders with their items`,
        title: 'Export Complete'
      });

    } catch (error) {
      console.error('Error exporting data:', error);
      setAlert({
        type: 'error',
        message: error.message || 'Failed to export data. Please try again.',
        title: 'Export Error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleFileUpload = () => {
    // Implementation here
  };

  // eslint-disable-next-line no-unused-vars
  const handleEditClick = () => {
    // Implementation here
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    try {
        let date;
        
        // Handle ISO date format (ending with Z)
        if (dateStr.includes('T') && dateStr.includes('Z')) {
            date = new Date(dateStr);
        } else if (dateStr.includes('/')) {
            // Already in DD/MM/YYYY format
            return dateStr;
        } else if (dateStr.includes('-')) {
            // Convert from YYYY-MM-DD to DD/MM/YYYY
            const [year, month, day] = dateStr.split('-');
            return `${day}/${month}/${year}`;
        } else {
            // Try parsing as date object
            date = new Date(dateStr);
        }

        if (isNaN(date.getTime())) return '';

        // Format to DD/MM/YYYY
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        
        return `${day}/${month}/${year}`;
    } catch (error) {
        console.error('Error formatting date:', error);
        return dateStr; // Return original string if formatting fails
    }
  };

  const renderCellContent = (value, column, order) => {
    if (value === null || value === undefined || value === '') {
      return '';
    }
    
    try {
      // Handle Team Remark column
      if (column === 'Team Remark') {
        return (
          <select
            className="form-select form-select-sm"
            value={teamRemarks[order.PoNumber] || value || 'Scheduled Pending'}
            onChange={(e) => handleTeamRemarkChange(order.PoNumber, e.target.value)}
          >
            <option value="Scheduled Pending">Scheduled Pending</option>
            <option value="PO is Scheduled">PO is Scheduled</option>
            <option value="PO Should Not Be Processed">PO Should Not Be Processed</option>
            <option value="PO Amendment To Be Awaited">PO Amendment To Be Awaited</option>
          </select>
        );
      }

      // Handle date formatting
      if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}/.test(value)) {
        const date = new Date(value);
        return !isNaN(date.getTime()) ? formatDate(value) : value;
      }
      
      // Handle currency formatting
      if (column === 'PoValues') {
        const number = Math.floor(parseFloat(value));
        return !isNaN(number) ? 
          new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' })
            .format(number) : '';
      }
      
      // Handle weight formatting
      if (['Tonnage', 'Moq'].includes(column)) {
        const number = parseFloat(value);
        return !isNaN(number) ? `${Math.round(number)} KG` : '';
      }
      
      // For other numeric values
      if (typeof value === 'number' && !isNaN(value)) {
        return Math.round(value);
      }
      
      // For objects
      if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value);
      }
      
      return value;
    } catch (error) {
      console.error(`Error rendering cell content for ${column}:`, error);
      return String(value);
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditingItem(null);
  };

  const handleSaveEdit = async () => {
    if (!editingItem) return;
    
    try {
      setIsLoading(true);
      
      const user = getUserData();
      if (!user || !user.user_id) {
        throw new Error('Please login to continue');
      }

      // Validate required fields
      if (!editingItem.Price || !editingItem.Quantity) {
        throw new Error('Price and Quantity are required fields');
      }

      // Ensure values are numbers
      const newPrice = parseFloat(editingItem.Price);
      const newQuantity = parseFloat(editingItem.Quantity);

      if (isNaN(newPrice) || isNaN(newQuantity)) {
        throw new Error('Price and Quantity must be valid numbers');
      }

      // Add timestamp to new remarks if present
      const timestamp = new Date().toLocaleString();
      const newRemarkWithTimestamp = editingItem.newRemarks 
        ? `[${timestamp}] ${editingItem.newRemarks}`
        : '';

      // Combine existing and new remarks
      const combinedRemarks = editingItem.Remarks
        ? (newRemarkWithTimestamp ? `${editingItem.Remarks}\n${newRemarkWithTimestamp}` : editingItem.Remarks)
        : newRemarkWithTimestamp;

      const updateData = {
        newPrice,
        newQuantity,
        remarks: combinedRemarks,
        userId: user.user_id,
        userName: `${user.first_name} ${user.last_name}`
      };

      const result = await editOrderItem(
        editingItem.PoNumber,
        editingItem.ItemId,
        updateData
      );

      if (result.success) {
        // Update local state
        setToppledData(prevState => ({
          ...prevState,
          [editingItem.PoNumber]: result.data
        }));

        // Refresh the orders
        await loadOrders(selectedPlatform);
        
        setAlert({
          type: 'success',
          message: 'Item updated successfully',
          title: 'Success'
        });
        
        handleCloseEditModal();
      } else {
        throw new Error(result.message || 'Failed to update item');
      }
      
    } catch (error) {
      console.error('Error updating item:', error);
      setAlert({
        type: 'error',
        message: error.message || 'Failed to update item. Please try again.',
        title: 'Update Failed'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditItemChange = (field, value) => {
    if (!editingItem) return;
    
    let processedValue = value;
    
    // Validate numeric fields
    if (field === 'Price' || field === 'Quantity') {
      const numValue = parseFloat(value);
      if (isNaN(numValue) || numValue < 0) return;
      processedValue = numValue;
    }

    setEditingItem(prevItem => ({
      ...prevItem,
      [field]: processedValue
    }));
  };

  const LoadingOverlay = () => {
    if (!isLoading) return null;
    
    return (
      <div className="loading-overlay">
        <div className="loading-spinner" />
      </div>
    );
  };

  const SkeletonLoader = () => {
    return Array(10).fill(0).map((_, index) => (
      <tr key={`skeleton-${index}`} className="skeleton-row">
        <td>
          <div className="skeleton-cell" style={{ width: '20px' }} />
        </td>
        <td>
          <div className="skeleton-cell" style={{ width: '24px' }} />
        </td>
        {Array(columns.length).fill(0).map((_, colIndex) => (
          <td key={`skeleton-cell-${colIndex}`}>
            <div className="skeleton-cell" />
          </td>
        ))}
      </tr>
    ));
  };

  const tableRef = useRef(null);

  // Update the scroll effect
  useEffect(() => {
    const handleScroll = () => {
        const scrollTop = scrollableRef.current.scrollTop;
        const scrollDelta = scrollTop - lastScrollTop;
        const wrapper = scrollableRef.current;

        // Only apply classes if we've scrolled more than 5px
        if (Math.abs(scrollDelta) > 5) {
            if (scrollDelta > 0 && scrollTop > 50) {
                wrapper.classList.add('scroll-up');
            } else if (scrollDelta < 0) {
                wrapper.classList.remove('scroll-up');
            }
            setLastScrollTop(scrollTop);
        }
    };

    const scrollableWrapper = scrollableRef.current;
    if (scrollableWrapper) {
        scrollableWrapper.addEventListener('scroll', handleScroll);
    }

    return () => {
        if (scrollableWrapper) {
            scrollableWrapper.removeEventListener('scroll', handleScroll);
        }
    };
  }, [lastScrollTop]);

  const processApproval = async (selectedOrders, user, platform) => {
    try {
        const orderData = {
            orders: selectedOrders.map(order => ({
                PoNumber: String(order.PoNumber),
                Platform: platform,
                Status: order.STATUS,
                ItemId: order.ItemId,
                Price: order.Price,
                Quantity: order.Quantity,
                Remarks: order.Remarks || ''
            })),
            userId: user.user_id,
            userName: `${user.first_name} ${user.last_name}`,
            platform: platform
        };

        const result = await approveOrders(orderData);
        
        if (result.success) {
            setSelectAll(false);
            return {
                success: true,
                message: result.message
            };
        } else {
            throw new Error(result.message || 'Failed to process order');
        }
    } catch (error) {
        return {
            success: false,
            message: error.message || 'Failed to process order'
        };
    }
  };

  const handleApproveOrders = async (specificPoNumber = null) => {
    try {
        let ordersToProcess;
        
        if (specificPoNumber) {
            ordersToProcess = [allOrders.find(order => 
                order.PoNumber.toString() === specificPoNumber.toString()
            )].filter(Boolean);
        } else {
            ordersToProcess = orders.filter(order => order.selected);
        }

        if (ordersToProcess.length === 0) {
            setAlert({
                type: 'warning',
                message: 'Please select at least one order to process.',
                title: 'No Order Selected'
            });
            return;
        }

        setIsLoading(true);

        // Separate orders into already approved and pending
        const alreadyApproved = ordersToProcess.filter(order => 
            order.STATUS?.toLowerCase() === 'approved'
        );
        const pendingApproval = ordersToProcess.filter(order => 
            order.STATUS?.toLowerCase() !== 'approved'
        );

        // If there are pending orders, process them first
        if (pendingApproval.length > 0) {
            const user = getUserData();
            if (!user || !user.user_id) {
                throw new Error('Please login to continue');
            }

            // Process the approval for pending orders
            const approvalResult = await processApproval(pendingApproval, user, selectedPlatform);
            
            if (!approvalResult.success) {
                throw new Error(approvalResult.message || 'Failed to approve orders');
            }
        }

        // Combine newly approved orders with already approved ones for contract generation
        const ordersForContracts = [...alreadyApproved, ...pendingApproval];
        
        // Show progress message
        setAlert({
            type: 'success',
            message: `Generating contracts for ${ordersForContracts.length} order(s)...`,
            title: 'Processing'
        });

        // Generate contracts for all orders
        let successCount = 0;
        let failureCount = 0;

        for (const order of ordersForContracts) {
            try {
                const regularResult = await generateContract_po(order.PoNumber, 'REGULAR');
                const stimulResult = await generateContract_po(order.PoNumber, 'STIMUL');
                
                if (regularResult.success || stimulResult.success) {
                    successCount++;
                } else {
                    failureCount++;
                }
            } catch (error) {
                failureCount++;
            }
        }

        // Show final results
        let message = '';
        if (pendingApproval.length > 0) {
            message += `Orders approved: ${pendingApproval.length}\n`;
        }
        if (alreadyApproved.length > 0) {
            message += `Already approved orders processed: ${alreadyApproved.length}\n`;
        }
        message += `Contracts Generated: ${successCount}\n`;
        message += `Failed Contracts: ${failureCount}`;

        setAlert({
            type: successCount > 0 ? 'success' : 'warning',
            message: message,
            title: 'Process Complete',
            onClose: async () => {
                // Refresh to show updated status
                await loadOrders(selectedPlatform);
            }
        });

    } catch (error) {
        console.error('Error processing orders:', error);
        setAlert({
            type: 'error',
            message: error.message || 'Failed to process orders',
            title: 'Error'
        });
    } finally {
        setIsLoading(false);
        setSelectAll(false);
    }
  };

  // Update the handleItemCheckboxChange function
  const handleItemCheckboxChange = (poNumber, itemIndex) => {
    setSelectedItems(prev => {
      // Initialize the PO's items if they don't exist
      const poItems = prev[poNumber] || {};
      
      // Toggle the selected state for this item
      const newPoItems = {
        ...poItems,
        [itemIndex]: !poItems[itemIndex]
      };
      
      // Return the updated state
      return {
        ...prev,
        [poNumber]: newPoItems
      };
    });
  };

  // Update the handleMarkInactive function
  const handleMarkInactive = async (selectedOrders) => {
    try {
        setIsLoading(true);
        
        const user = getUserData();
        if (!user || !user.user_id) {
            throw new Error('Please login to continue');
        }

        // Check for selected items in expanded POs
        const selectedItemsMap = {};
        let totalSelectedItems = 0;
        
        // Create a safe copy of all selected items first
        const selectedItemsCopy = Object.entries(selectedItems).map(([poNumber, items]) => {
            const poItems = toppledData[poNumber] || [];
            const selectedIndices = Object.entries(items || {})
                .filter(([_, selected]) => selected)
                .map(([index]) => ({
                    index: Number(index),
                    itemCode: poItems[Number(index)]?.ItemCode,
                    poNumber
                }))
                .filter(item => item.itemCode); // Only keep valid items

            totalSelectedItems += selectedIndices.length;
            return { poNumber, items: selectedIndices };
        });

        // Now process the safe copy
        selectedItemsCopy.forEach(({ poNumber, items }) => {
            if (items.length > 0) {
                selectedItemsMap[poNumber] = items.map(item => ({
                    itemCode: item.itemCode,
                    poNumber: item.poNumber
                }));
            }
        });

        // Handle both items and POs
        if (totalSelectedItems > 0) {
            let successCount = 0;
            let failureCount = 0;
            const errors = [];

            // Process items one by one
            for (const poNumber of Object.keys(selectedItemsMap)) {
                for (const item of selectedItemsMap[poNumber]) {
                    try {
                        // Send single item update request
                        const itemData = {
                            type: 'item',
                            items: [item], // Send only one item
                            platform: selectedPlatform
                        };

                        const result = await updateOrderStatus(itemData);
                        if (result.success) {
                            successCount++;
                        } else {
                            failureCount++;
                            errors.push(`Failed to update item ${item.itemCode}: ${result.message}`);
                        }
                    } catch (error) {
                        failureCount++;
                        errors.push(`Error updating item ${item.itemCode}: ${error.message}`);
                    }
                }

                // Refresh the PO data after processing all its items
                const response = await fetchPodata(poNumber);
                setToppledData(prev => ({
                    ...prev,
                    [poNumber]: response.data
                }));
            }

            // Show results
            setAlert({
                type: successCount > 0 ? 'success' : 'error',
                message: `Processed ${totalSelectedItems} items:\n` +
                        `Successfully marked inactive: ${successCount}\n` +
                        `Failed: ${failureCount}` +
                        (errors.length > 0 ? `\n\nErrors:\n${errors.join('\n')}` : ''),
                title: successCount > 0 ? 'Success' : 'Update Failed'
            });
        } else if (selectedOrders.length > 0) {
            // Handle PO inactivation one by one
            let successCount = 0;
            let failureCount = 0;
            const errors = [];

            for (const order of selectedOrders) {
                try {
                    const orderData = {
                        type: 'po',
                        orders: [{
                            poNumber: String(order.PoNumber),
                            platform: selectedPlatform
                        }],
                        platform: selectedPlatform
                    };

                    const result = await updateOrderStatus(orderData);
                    if (result.success) {
                        successCount++;
                    } else {
                        failureCount++;
                        errors.push(`Failed to update PO ${order.PoNumber}: ${result.message}`);
                    }
                } catch (error) {
                    failureCount++;
                    errors.push(`Error updating PO ${order.PoNumber}: ${error.message}`);
                }
            }

            // Show results
            setAlert({
                type: successCount > 0 ? 'success' : 'error',
                message: `Processed ${selectedOrders.length} orders:\n` +
                        `Successfully marked inactive: ${successCount}\n` +
                        `Failed: ${failureCount}` +
                        (errors.length > 0 ? `\n\nErrors:\n${errors.join('\n')}` : ''),
                title: successCount > 0 ? 'Success' : 'Update Failed'
            });
        }
        
    } catch (error) {
        console.error('Error marking items/orders inactive:', error);
        setAlert({
            type: 'error',
            message: error.message || 'Failed to mark as inactive. Please try again.',
            title: 'Update Failed'
        });
    } finally {
        setIsLoading(false);
        
        // Clear selections after completion
        setSelectedItems({});
        setSelectAll(false);
        
        // Refresh the orders to show updated status
        await loadOrders(selectedPlatform);
    }
  };

  // Update the renderToppledData function
  const renderToppledData = (poNumber) => {
    const data = toppledData[poNumber];
    
    if (!data || data.length === 0) {
      return (
        <div className="details-content">
          <p>No details available</p>
        </div>
      );
    }

    // Calculate if all items in this PO are selected
    const allSelected = data.length > 0 && 
      data.every((_, index) => selectedItems[poNumber]?.[index]);

    return (
      <div className="details-content">
        <table className="inner-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={allSelected}
                  onChange={() => {
                    // Toggle all items in this PO
                    const newState = !allSelected;
                    setSelectedItems(prev => ({
                      ...prev,
                      [poNumber]: Object.fromEntries(
                        data.map((_, index) => [index, newState])
                      )
                    }));
                  }}
                />
              </th>
              <th>Sr. No.</th>
              {Object.keys(data[0]).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedItems[poNumber]?.[index] || false}
                    onChange={() => handleItemCheckboxChange(poNumber, index)}
                  />
                </td>
                <td>{index + 1}</td>
                {Object.entries(item).map(([key, value]) => (
                  <td key={key}>{renderCellContent(value, key, { PoNumber: poNumber })}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Add edit modal content renderer
  const renderEditModalContent = () => {
    if (!editingItem) return null;

    return (
      <div className="edit-form">
        <div className="form-group">
          <label>Price</label>
          <input
            type="number"
            className="form-control"
            value={editingItem.Price || ''}
            onChange={(e) => handleEditItemChange('Price', e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Quantity</label>
          <input
            type="number"
            className="form-control"
            value={editingItem.Quantity || ''}
            onChange={(e) => handleEditItemChange('Quantity', e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Add Remarks</label>
          <textarea
            className="form-control"
            value={editingItem.newRemarks || ''}
            onChange={(e) => handleEditItemChange('newRemarks', e.target.value)}
            placeholder="Add new remarks..."
          />
        </div>
        <div className="form-group">
          <label>Current Remarks</label>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-secondary"
            onClick={handleCloseEditModal}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSaveEdit}
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </div>
    );
  };

  // Add this function to handle clearing all filters and selections
  const handleClearAll = () => {
    // Reset all filters
    setStatusFilter('');
    setPoNumberSearch('');
    setStartDate(null);
    setEndDate(null);
    
    // Reset all data states
    setOrders([]);
    setAllOrders([]);
    setDisplayedOrders([]);
    setSelectAll(false);
    setActiveToggle(null);
    setToppledData({});
    
    // Clear team remarks and selected items
    setTeamRemarks({});
    setSelectedItems({});
    
    // Reload fresh data
    loadOrders(selectedPlatform);
  };

  // Update the handleTeamRemarkChange function to only update local state
  const handleTeamRemarkChange = (poNumber, value) => {
    setTeamRemarks(prev => ({
      ...prev,
      [poNumber]: value
    }));
  };

  // Update the handleSubmitTeamRemarks function to handle the submission
  const handleSubmitTeamRemarks = async () => {
    try {
        // Filter only POs that have team remarks changed
        const updates = orders
            .filter(order => teamRemarks[order.PoNumber] !== undefined)
            .map(order => ({
                poNumber: order.PoNumber,
                manual_remark: teamRemarks[order.PoNumber],
                platform: order.platform
            }));

        if (updates.length === 0) {
            setAlert({
                show: true,
                type: 'warning',
                message: 'No remarks to update'
            });
            return;
        }

        // Show confirmation dialog using AlertCard
        setAlert({
            show: true,
            type: 'warning',
            title: 'Update Remarks',
            message: (
                <div className="po-list-container">
                    <p className="alert-description">
                        The following orders will be updated with new remarks. Do you want to proceed?
                    </p>
                    <div className="po-list custom-scrollbar">
                        {updates.map((update, index) => (
                            <div key={index} className="po-item">
                                <div className="po-item-header">
                                    <strong className="po-number">PO #{update.poNumber}</strong>
                                    <span className="po-badge">{update.platform}</span>
                                </div>
                                <div className="po-remarks">
                                    <span className="remarks-label">New Remark:</span>
                                    <p className="remarks-text">{update.manual_remark}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ),
            showConfirmButton: true,
            onConfirm: async () => {
                try {
                    // Call the API endpoint to update manual remarks using the AppRoutes function
                    const response = await updateManualRemarks(updates);

                    if (response.success) {
                        setAlert({
                            show: true,
                            type: 'success',
                            message: 'Remarks updated successfully'
                        });
                        // Clear the team remarks state
                        setTeamRemarks({});
                        // Refresh the orders to get updated data
                        loadOrders(selectedPlatform);
                    } else {
                        setAlert({
                            show: true,
                            type: 'error',
                            message: response.message || 'Failed to update remarks'
                        });
                    }
                } catch (error) {
                    console.error('Error updating remarks:', error);
                    setAlert({
                        show: true,
                        type: 'error',
                        message: error.message || 'Failed to update remarks'
                    });
                }
            }
        });
    } catch (error) {
        console.error('Error preparing remarks:', error);
        setAlert({
            show: true,
            type: 'error',
            message: 'Failed to prepare remarks for update'
        });
    }
  };

  // Add new handler for process data
  const handleProcessData = () => {
    setShowProcessModal(true);
  };


  // Update handleProcessSubmit
  const handleProcessSubmit = async (startDate, endDate) => {
    if (!startDate || !endDate) {
      setAlert({
        type: 'warning',
        message: 'Please select both start and end dates',
        title: 'Missing Dates'
      });
      return;
    }

    try {
      setIsLoading(true);
      
      // Format dates to match backend expectation (YYYY-MM-DD)
      const formatDateForBackend = (date) => {
        return date.toISOString().split('T')[0];
      };

      const result = await processDataByPlatform(
        selectedPlatform,
        formatDateForBackend(startDate),
        formatDateForBackend(endDate)
      );

      // Handle the response properly
      if (result && result.success) {
        setAlert({
          type: 'success',
          message: result.message || 'Data processed successfully',
          title: 'Success'
        });
        
        // Refresh the data after processing
        await loadOrders(selectedPlatform);
      } else {
        throw new Error(result?.message || 'Processing failed');
      }
      
      setShowProcessModal(false);
    } catch (error) {
      console.error('Process data error:', error);
      setAlert({
        type: 'error',
        message: error.message || 'Failed to process data',
        title: 'Error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Update the ProcessDataModal component
  const ProcessDataModal = ({ show, onClose }) => {
    const [localStartDate, setLocalStartDate] = useState(null);
    const [localEndDate, setLocalEndDate] = useState(null);

    // Reset dates when modal opens
    useEffect(() => {
      if (show) {
        setLocalStartDate(null);
        setLocalEndDate(null);
      }
    }, [show]);

    if (!show) return null;

    return (
      <div className="modal-overlay">
        <div className="modal-content process-modal">
          <div className="modal-header">
            <h2 className="modal-title">Process {selectedPlatform} Data</h2>
            <button onClick={onClose} className="modal-close">
              <X size={24} />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group mb-4">
              <label className="form-label">From Date</label>
              <div className="date-picker-container">
                <DatePicker
                  selected={localStartDate}
                  onChange={date => setLocalStartDate(date)}
                  placeholderText="Select start date"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  isClearable={true}
                  popperProps={{
                    strategy: "fixed"
                  }}
                  popperPlacement="bottom-start"
                  popperClassName="date-picker-popper"
                  wrapperClassName="date-picker-wrapper"
                />
              </div>
            </div>
            <div className="form-group mb-4">
              <label className="form-label">To Date</label>
              <div className="date-picker-container">
                <DatePicker
                  selected={localEndDate}
                  onChange={date => setLocalEndDate(date)}
                  placeholderText="Select end date"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  isClearable={true}
                  minDate={localStartDate}
                  popperProps={{
                    strategy: "fixed"
                  }}
                  popperPlacement="bottom-start"
                  popperClassName="date-picker-popper"
                  wrapperClassName="date-picker-wrapper"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={onClose}>
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                handleProcessSubmit(localStartDate, localEndDate);
                onClose();
              }}
              disabled={!localStartDate || !localEndDate}
            >
              {isLoading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" />
                  Processing...
                </>
              ) : (
                'Process'
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Function to fetch data with pagination
  const fetchData = useCallback(async () => {
    if (!selectedPlatform) {
      setOrders([]);
      setDisplayedOrders([]);
      setTotalRecords(0);
      setTotalPages(1);
      return;
    }
    
    setIsLoading(true);
    try {
      // Fetch paginated data first using AppRoutes function
      const response = await fetchDataFromAPI(
        selectedPlatform, 
        currentPage, 
        pageSize,
      );
      
      // Fetch purchase orders count
      const countResponse = await fetchPurchaseOrdersCount(selectedPlatform);
      const totalRecords = countResponse.total_records;
      const totalPages = Math.ceil(totalRecords / pageSize);
      
      if (response && response.data) {
        setOrders(response.data);
        
        // Update data for selected platform
        setDisplayedOrders(response.data);
        
        // For the total count and pages, use the response data length
        // as a fallback since we don't have a direct API call for count
        const dataLength = response.data.length;
        
        // If we have less data than requested page size, we're probably at the end
        if (dataLength < pageSize) {
          setTotalRecords((currentPage - 1) * pageSize + dataLength);
          setTotalPages(currentPage);
        } 
        // If we have full page of data, assume there might be more
        else {
          // For UI purposes, show at least the current data amount
          setTotalRecords(Math.max(currentPage * pageSize, totalRecords));
          setTotalPages(Math.max(currentPage, totalPages));
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setAlert({
        type: 'error',
        message: 'Failed to load purchase orders. Please try again.',
        title: 'Error'
      });
      // Set defaults on error
      setTotalRecords(0);
      setTotalPages(1);
    } finally {
      setIsLoading(false);
    }
  }, [selectedPlatform, currentPage, pageSize]);

  // Call fetchData when relevant dependencies change
  useEffect(() => {
    if (selectedPlatform) {
      fetchData();
    }
  }, [fetchData, selectedPlatform, currentPage, pageSize]);
  
  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  // Handle page size change
  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  // Use totalRecords in a useEffect or other function
  useEffect(() => {
    // Update document title with record count for accessibility
    document.title = `PO Table - ${totalRecords} records`;
    
    // Log pagination information
    console.log(`Showing page ${currentPage} of ${totalPages} (${totalRecords} total records)`);
  }, [totalRecords, totalPages, currentPage]);

  // Add this function definition before using it
  const saveExcelFile = (buffer, fileName, startDate, endDate) => {
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-').slice(0, 19);
    let exportFileName = '';

    // Start with platform name
    if (selectedPlatform) {
      exportFileName += `${selectedPlatform}_`;
    }

    // Add date range if provided
    if (startDate && endDate) {
      const formatDate = (date) => {
        return date.toLocaleDateString('en-GB').split('/').reverse().join('-');
      };
      exportFileName += `${formatDate(startDate)}_to_${formatDate(endDate)}_`;
    } else {
      // If no date range, add current date
      exportFileName += `${timestamp.split('T')[0]}_`;
    }

    // Add timestamp to ensure uniqueness
    exportFileName += `${timestamp}`;

    // Add file extension
    exportFileName += '.xlsx';

    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = exportFileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className="po-table-container">
      <div className="table-scroll-container">
        <div className="scrollable-wrapper" ref={scrollableRef}>
          {renderPlatformTabs()}
          <div className="filters-card">
            <div className="filters-content">
              <div className="filters-row">
                <div className="filter-item">
                  <div className="custom-filter-group">
                    <div className="filter-label">Status</div>
                    <select 
                      id="statusFilter"
                      value={statusFilter} 
                      onChange={handleStatusFilterChange}
                      className="custom-select"
                    >
                      <option value="">All Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                      <option value="Shipped">Shipped</option>
                      <option value="Delivered">Delivered</option>
                      <option value="Canceled">Canceled</option>
                      <option value="Inactive">Inactive</option>
                      <option value="Unknown">Unknown</option>
                    </select>
                  </div>
                </div>
                
                <div className="filter-item">
                  <div className="custom-filter-group">
                    <div className="filter-label">Search</div>
                    <div className="search-input-wrapper">
                      <Search size={12} className="search-icon" />
                      <input 
                        id="poSearch"
                        type="text" 
                        className="custom-input" 
                        placeholder="Search..."
                        value={poNumberSearch}
                        onChange={handlePoNumberSearchChange}
                        autoComplete="off" // Added to prevent browser suggestions
                        spellCheck="false" // Disable spell checking
                      />
                    </div>
                  </div>
                </div>
                
                <div className="filter-item">
                  <div className="custom-filter-group">
                    <div className="filter-label">Date</div>
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={handleDateChange}
                      placeholderText="Select date range"
                      className="custom-input date-picker"
                      wrapperClassName="date-picker-wrapper"
                      dateFormat="dd/MM/yyyy"
                      isClearable={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            
            <div className="actions-row">
              <div className="action-item">
                <button 
                  className="btn btn-sm btn-success" 
                  title="Approve Orders"
                  onClick={() => {
                    const selectedOrders = orders.filter(order => order.selected);
                    if (selectedOrders.length === 1) {
                      handleApproveOrders(String(selectedOrders[0].PoNumber));
                    } else {
                      handleApproveOrders();
                    }
                  }}
                  disabled={isLoading}
                >
                  <Check size={14} className="me-1" />
                  <span>{isLoading ? 'Processing...' : 'Approve'}</span>
                </button>
              </div>
              <div className="action-item">
                <button 
                  className="btn btn-sm btn-primary" 
                  onClick={() => loadOrders(selectedPlatform)} 
                  title="Reload Data"
                >
                  <RefreshCw size={14} className="me-1" />
                  <span>Reload</span>
                </button>
              </div>
              <div className="action-item">
                <button 
                  className="btn btn-sm btn-dark" 
                  onClick={exportToExcel} 
                  title="Export to Excel"
                >
                  <Download size={14} className="me-1" />
                  <span>Export</span>
                </button>
              </div>
              <div className="action-item">
                <button 
                  className="btn btn-sm btn-warning" 
                  onClick={() => {
                    // Check for selected items
                    const selectedItemsCount = Object.values(selectedItems)
                      .reduce((total, poItems) => 
                        total + Object.values(poItems).filter(Boolean).length, 0);
                    
                    const selectedOrdersCount = orders.filter(order => order.selected).length;

                    // Validate that at least something is selected
                    if (selectedItemsCount === 0 && selectedOrdersCount === 0) {
                      setAlert({
                        type: 'warning',
                        message: 'Please select at least one order or item to mark as inactive.',
                        title: 'No Selection'
                      });
                      return;
                    }

                    // Show confirmation for the selected items or POs
                    const message = selectedItemsCount > 0
                      ? `Are you sure you want to mark ${selectedItemsCount} item(s) as inactive?`
                      : `Are you sure you want to mark ${selectedOrdersCount} purchase order(s) as inactive?`;

                    setAlert({
                      type: 'warning',
                      message: message,
                      title: 'Confirm Inactive',
                      showConfirmButton: true,
                      confirmText: 'Mark Inactive',
                      onConfirm: () => handleMarkInactive(orders.filter(order => order.selected))
                    });
                  }}
                  disabled={isLoading}
                  title="Mark Orders/Items Inactive"
                >
                  <X size={14} className="me-1" />
                  <span>Inactive</span>
                </button>
              </div>
              <div className="action-item">
                <button 
                  className="btn btn-sm btn-outline-danger" 
                  onClick={handleClearAll}
                  title="Clear All Filters and Selections"
                >
                  <X size={14} className="me-1" />
                  <span>Clear All</span>
                </button>
              </div>
              <div className="action-item">
                <button 
                  className="btn btn-sm btn-success" 
                  onClick={handleSubmitTeamRemarks}
                  disabled={isSubmitting || Object.keys(teamRemarks).length === 0}
                  title="Submit Team Remarks"
                >
                  <Check size={14} className="me-1" />
                  <span>{isSubmitting ? 'Submitting...' : 'Submit Remarks'}</span>
                </button>
              </div>
              <div className="action-item">
                <button 
                  className="btn btn-sm btn-info" 
                  onClick={handleProcessData}
                  title="Process Data"
                >
                  <RefreshCw size={14} className="me-1" />
                  <span>Process Data</span>
                </button>
              </div>
              
              {/* Pagination controls in action row */}
      
              <div className="btn-group">
                  <button 
                    className="btn btn-sm btn-secondary" 
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1 || isLoading}
                  >
                    &lt;
                  </button>
                  <button className="btn btn-sm btn-light" disabled>
                    {currentPage} / {Math.max(1, totalPages)}
                  </button>
                  <button 
                    className="btn btn-sm btn-secondary" 
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={isLoading || (orders.length < pageSize && currentPage >= totalPages)}
                  >
                    &gt;
                  </button>
                </div>

              
           
              
        
                <select 
                  className="form-select form-select-sm" 
                  value={pageSize} 
                  onChange={handlePageSizeChange}
                  disabled={isLoading}
                >
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                </select>
             
            </div>
          </div>

          <div className="table-wrapper">
            <div ref={tableRef} className="table-scroll">
              <LoadingOverlay />
              <div className="table-responsive">
                <div className="table-scroll">
                  <table className="table" role="table">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectAll}
                            onChange={toggleSelectAll}
                          />
                        </th>
                        <th></th>
                        <th>Sr. No.</th>
                        {columns.map((column, index) => (
                          <th key={index}>{column}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <SkeletonLoader />
                      ) : (
                        <>
                          {displayedOrders.map((order, displayIndex) => (
                            <React.Fragment key={order.PoNumber || displayIndex}>
                              <tr className={activeToggle === order.PoNumber ? 'active-row' : ''}>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={order.selected}
                                    onChange={() => handleCheckboxChange(displayIndex)}
                                  />
                                </td>
                                <td>
                                  <button 
                                    className="btn btn-sm btn-link p-0 toggle-btn"
                                    onClick={() => toggleRow(order.PoNumber)}
                                  >
                                    {activeToggle === order.PoNumber ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
                                  </button>
                                </td>
                                <td>{displayIndex + 1}</td>
                                {columns.map((column, colIndex) => (
                                  <td key={colIndex}>
                                    {column === 'STATUS' 
                                      ? renderStatus(order[column] || 'Pending') 
                                      : renderCellContent(order[column], column, order)}
                                  </td>
                                ))}
                              </tr>
                              {activeToggle === order.PoNumber && (
                                <tr className="details-row">
                                  <td colSpan={columns.length + 3}>
                                    {renderToppledData(order.PoNumber)}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showEditModal} onClose={handleCloseEditModal}>
        {renderEditModalContent()}
      </Modal>
      <ProcessDataModal 
        show={showProcessModal} 
        onClose={() => setShowProcessModal(false)}
      />
      <ExportDataModal 
        show={showExportModal} 
        onClose={() => setShowExportModal(false)}
        selectedPlatform={selectedPlatform}
        handleExportSubmit={handleExportSubmit}
        isLoading={isLoading}
      />
      {alert && (
        <div className="dialog-container">
          <AlertCard
            type={alert.type}
            message={alert.message}
            title={alert.title}
            onClose={() => setAlert(null)}
            onConfirm={alert.onConfirm}
            showConfirmButton={alert.showConfirmButton}
          />
        </div>
      )}
    </div>
  );
};

export default PoTable;
              