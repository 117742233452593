import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faFilter,
  faChartPie,
  faChartBar,
  faShoppingCart,
  faWeightHanging,
  faCheckCircle,
  faClock,
  faChevronDown,
  faArrowUp,
  faArrowDown,
  faTable
} from '@fortawesome/free-solid-svg-icons';
import './SummaryPage.css';
import * as XLSX from 'xlsx';
import { fetchDashboardSummary } from  '../../routes/AppRoutes';

const MetricCard = ({ title, value, trend, icon, sparklineData, color, type }) => {
  const [displayValue, setDisplayValue] = useState(0);
  
  useEffect(() => {
    // Reset to 0 when value changes
    setDisplayValue(0);
    
    const duration = 2000; // 2 seconds animation
    const steps = 20; // Reduced number of steps for smoother animation
    const stepValue = value / steps;
    let current = 0;
    
    const timer = setInterval(() => {
      current += stepValue;
      if (current >= value) {
        setDisplayValue(value);
        clearInterval(timer);
      } else {
        setDisplayValue(Math.floor(current));
      }
    }, duration / steps);
    
    // Ensure cleanup
    return () => {
      clearInterval(timer);
    };
  }, [value]); // Only re-run when value changes

  return (
    <div className="metric-card" data-type={type}>
      <div className="metric-header">
        <div className="metric-icon">
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className={`metric-trend ${trend >= 0 ? 'positive' : 'negative'}`}>
          <FontAwesomeIcon icon={trend >= 0 ? faArrowUp : faArrowDown} />
          <span>{Math.abs(trend)}%</span>
        </div>
      </div>
      <div className="metric-content">
        <h3>{title}</h3>
        <p className="metric-value">{displayValue.toLocaleString()}</p>
        <div className="metric-sparkline">
          <SparklineChart data={sparklineData} color={color} />
        </div>
      </div>
    </div>
  );
};

const SparklineChart = ({ data, color, type = 'line' }) => {
  const options = {
    chart: {
      type: type,
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    colors: [color],
    tooltip: {
      fixed: {
        enabled: false
      },
      x: {
        show: false
      },
      marker: {
        show: false
      }
    }
  };

  return (
    <div className="sparkline-chart">
      <ReactApexChart
        options={options}
        series={[{ data: data }]}
        type={type}
        height={40}
      />
    </div>
  );
};

const SummaryPage = () => {
  const isInitialMount = useRef(true);

  // Get default dates for current month
  const getDefaultDates = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    
    return {
      fromDate: firstDay.toISOString().split('T')[0],
      toDate: lastDay.toISOString().split('T')[0]
    };
  };

  const defaultDates = getDefaultDates();

  const [chartOptions, setChartOptions] = useState({
    platformOptions: null,
    statusOptions: null,
    salesOptions: null
  });
  const [summaryData, setSummaryData] = useState([]);
  // Single filters state with default dates
  const [filters, setFilters] = useState({
    Distribution: 'All',
    platform: 'All',
    fromDate: defaultDates.fromDate,  // Set default from date
    toDate: defaultDates.toDate,      // Set default to date
    status: 'All'
  });
  const [theme] = useState('light');
  const [collapsedSections, setCollapsedSections] = useState({
    filters: false,
    metrics: false,
    charts: false,
    table: false
  });
  const [metrics, setMetrics] = useState({
    totalOrders: { value: 0, trend: 0 },
    totalWeightage: { value: 0, trend: 0 },
    approvedOrders: { value: 0, trend: 0 },
    pendingOrders: { value: 0, trend: 0 }
  });

  const processChartData = (data) => {
    if (!data) return null;

    // Process platform distribution data
    const platformData = {
      series: [],
      labels: []
    };
    
    if (data.platformDistribution && Array.isArray(data.platformDistribution)) {
      platformData.series = data.platformDistribution.map(item => item.order_count || 0);
      platformData.labels = data.platformDistribution.map(item => item.platform || 'Unknown');
    }

    // Process status data
    const statusData = {
      series: [0, 0],
      labels: ['Approved Orders', 'Pending Orders']
    };
    
    if (data.metrics?.[0]) {
      statusData.series = [
        parseInt(data.metrics[0].approved_orders || 0),
        parseInt(data.metrics[0].pending_orders || 0)
      ];
    }

    // Process sales trend data
    const salesData = [];
    if (Array.isArray(data.salesTrend)) {
      const processedSalesTrend = data.salesTrend.reduce((acc, item) => {
        if (!item?.platform || !item?.date) return acc;
        
        if (!acc[item.platform]) {
          acc[item.platform] = {
            name: item.platform,
            data: []
          };
        }
        
        acc[item.platform].data.push({
          x: new Date(item.date).getTime(),
          y: parseFloat(item.total_sales || 0),
          total_orders: parseInt(item.order_count || 0),
          total_volume: parseFloat(item.total_volume || 0)
        });
        
        return acc;
      }, {});

      salesData.push(...Object.values(processedSalesTrend));
    }

    return {
      platformOptions: {
        series: platformData.series,
        labels: platformData.labels,
        chart: {
          type: 'donut',
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        dataLabels: {
          enabled: true
        }
      },
      statusOptions: {
        series: statusData.series,
        labels: statusData.labels,
        chart: {
          type: 'donut',
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        dataLabels: {
          enabled: true
        },
        colors: ['#28a745', '#ffc107']
      },
      salesOptions: {
        chart: {
          type: 'line',
          height: 350,
          animations: {
            enabled: false
          }
        },
        series: salesData,
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          labels: {
            formatter: (value) => value ? value.toFixed(2) : '0'
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (value) => value ? value.toFixed(2) : '0'
          }
        }
      }
    };
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchDashboardSummary(filters);
      const { data } = response;

      if (!data) {
        console.log('No data received');
        return;
      }

      // Update metrics
      if (data.metrics?.[0]) {
        setMetrics({
          totalOrders: {
            value: data.metrics[0].total_orders || 0,
            trend: data.metrics[0].orders_trend || 0
          },
          totalWeightage: {
            value: parseFloat(data.metrics[0].total_weightage) || 0,
            trend: data.metrics[0].weightage_trend || 0
          },
          approvedOrders: {
            value: parseInt(data.metrics[0].approved_orders) || 0,
            trend: data.metrics[0].approved_trend || 0
          },
          pendingOrders: {
            value: parseInt(data.metrics[0].pending_orders) || 0,
            trend: data.metrics[0].pending_trend || 0
          }
        });
      }

      // Update charts with new data
      setChartOptions(processChartData(data));

      // Update summary table with proper data mapping
      setSummaryData(data.summaryTable?.map(item => ({
        platform: item.platform,
        totalOrders: item.total_orders,
        totalValue: parseFloat(item.total_value).toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR'
        }),
        totalQuantity: item.total_quantity,
        totalVolume: `${parseFloat(item.total_volume).toFixed(2)} kg`,
        approvedOrders: item.approved_orders,
        pendingOrders: item.pending_orders
      })) || []);

    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  }, [filters]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData(); // Initial fetch with default dates
      return;
    }

    const timeoutId = setTimeout(() => {
      fetchData();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [fetchData]);

  const toggleSection = (section) => {
    setCollapsedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    
    // Summary data
    const summarySheet = XLSX.utils.json_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(workbook, summarySheet, "Summary");
    
    // Save the file
    XLSX.writeFile(workbook, `Dashboard_Export_${new Date().toISOString().split('T')[0]}.xlsx`);
  };

  const TableHeader = () => (
    <div className="section-title">
      <FontAwesomeIcon icon={faTable} />
      <h2>Summary Table</h2>
      <button 
        className="export-button" 
        onClick={exportToExcel}
        aria-label="Export to Excel"
      >
        Export to Excel
      </button>
    </div>
  );

  return (
    <div className={`dashboard-content ${theme}`}>
      <div className="summary-scroll-container">
        <div className={`filters-container collapsible ${collapsedSections.filters ? 'collapsed' : ''}`}>
          <div className="section-title" onClick={() => toggleSection('filters')}>
            <FontAwesomeIcon icon={faFilter} />
            <h2>Filters</h2>
            <FontAwesomeIcon 
              icon={faChevronDown} 
              className={`toggle-icon ${collapsedSections.filters ? 'rotated' : ''}`}
            />
          </div>
          <div className="section-content">
            <div className="filters" role="search" aria-label="Filter orders">
              <div className="filter">
                <label htmlFor="distribution">Distribution</label>
                <select 
                  id="distribution"
                  value={filters.Distribution}
                  onChange={(e) => handleFilterChange('Distribution', e.target.value)}
                >
                  <option value="All">All</option>
                  <option value="E-Commerce">E-Commerce</option>
                  <option value="Modern Trade">Modern Trade</option>
                </select>
              </div>
              <div className="filter">
                <label>Platform</label>
                <select onChange={(e) => handleFilterChange('platform', e.target.value)}>
                  <option value="All">All</option>
                  <option value="Flipkart">Flipkart</option>
                  <option value="Amazon">Amazon</option>
                  <option value="Blinkit">Blinkit</option>
                  <option value="Big Basket">Big Basket</option>
                  <option value="Zepto">Zepto</option>
                </select>
              </div>
              <div className="filter">
                <label>From Date</label>
                <input type="date" onChange={(e) => handleFilterChange('fromDate', e.target.value)} />
              </div>
              <div className="filter">
                <label>To Date</label>
                <input type="date" onChange={(e) => handleFilterChange('toDate', e.target.value)} />
              </div>
              <div className="filter">
                <label>Status</label>
                <select onChange={(e) => handleFilterChange('status', e.target.value)}>
                  <option value="All">All</option>
                  <option value="Approved">Approved</option>
                  <option value="Pending">Pending</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="metrics-container">
          <div className="section-title">
            <FontAwesomeIcon icon={faChartLine} />
            <h2>Key Metrics</h2>
          </div>
          <div className="metrics" role="region" aria-label="Key metrics">
            <MetricCard
              title="Total Orders"
              value={metrics.totalOrders.value}
              trend={metrics.totalOrders.trend}
              icon={faShoppingCart}
              sparklineData={[30, 40, 35, 50, 49, 60, 70, 91, 125]}
              color="var(--accent-color)"
              type="orders"
            />
            <MetricCard
              title="Total Weightage"
              value={metrics.totalWeightage.value}
              trend={metrics.totalWeightage.trend}
              icon={faWeightHanging}
              sparklineData={[45, 43, 48, 46, 50, 48, 52, 53, 56]}
              color="var(--success-color)"
              type="weightage"
            />
            <MetricCard
              title="Approved Orders"
              value={metrics.approvedOrders.value}
              trend={metrics.approvedOrders.trend}
              icon={faCheckCircle}
              sparklineData={[25, 30, 35, 38, 40, 45, 48, 50, 55]}
              color="var(--warning-color)"
              type="approved"
            />
            <MetricCard
              title="Pending Orders"
              value={metrics.pendingOrders.value}
              trend={metrics.pendingOrders.trend}
              icon={faClock}
              sparklineData={[20, 18, 15, 14, 13, 12, 11, 10, 9]}
              color="var(--danger-color)"
              type="pending"
            />
          </div>
        </div>

        <div className="charts-container">
          <div className="section-title">
            <FontAwesomeIcon icon={faChartPie} />
            <h2>Analytics</h2>
            <div className="chart-actions">
              <button className="chart-type-btn">
                <FontAwesomeIcon icon={faChartBar} />
              </button>
            </div>
          </div>
          <div className="chart-container" role="region" aria-label="Order analytics">
            <div className="chart-card">
              {chartOptions.platformOptions && (
                <ReactApexChart 
                  options={{
                    ...chartOptions.platformOptions,
                    chart: {
                      ...chartOptions.platformOptions.chart,
                      animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                      },
                      dropShadow: {
                        enabled: true,
                        blur: 3,
                        opacity: 0.2
                      }
                    }
                  }}
                  series={chartOptions.platformOptions.series}
                  type="donut"
                  height={window.innerWidth <= 768 ? 300 : 350}
                />
              )}
            </div>
            <div className="chart-card">
              {chartOptions.statusOptions && (
                <ReactApexChart 
                  options={{
                    ...chartOptions.statusOptions,
                    chart: {
                      ...chartOptions.statusOptions.chart,
                      animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                      },
                      dropShadow: {
                        enabled: true,
                        blur: 3,
                        opacity: 0.2
                      }
                    }
                  }}
                  series={chartOptions.statusOptions.series}
                  type="donut"
                  height={window.innerWidth <= 768 ? 300 : 350}
                />
              )}
            </div>
          </div>
        </div>

        <div className="table-container">
          <TableHeader />
          <div className="table-card">
            <div className="table-scroll">
              <table>
                <thead>
                  <tr>
                    <th>Platform</th>
                    <th>Total Orders</th>
                    <th>Total Value</th>
                    <th>Total Quantity</th>
                    <th>Total Volume</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {summaryData.map((platform) => (
                    <tr key={platform.platform}>
                      <td>{platform.platform}</td>
                      <td>{platform.totalOrders}</td>
                      <td>₹{platform.totalValue}</td>
                      <td>{platform.totalQuantity}</td>
                      <td>{platform.totalVolume}</td>
                      <td>
                        <div className="status-container">
                          <span className="status-pill status-approved">{platform.approvedOrders} Approved</span>
                          <span className="status-pill status-pending">{platform.pendingOrders} Pending</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="chart-area chart-responsive">
          {chartOptions.salesOptions && (
            <ReactApexChart 
              options={chartOptions.salesOptions} 
              series={chartOptions.salesOptions.series} 
              type="line"
              height={window.innerWidth <= 768 ? 300 : 350}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SummaryPage;